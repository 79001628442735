import { Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';
import { CheckboxProps } from './Checkbox.types';

export default function Checkbox(props: CheckboxProps) {
    const { id, onCheckboxChange, checked, disabled, label, sx } = props;

    if (label) {
        return (
            <FormControlLabel
                control={
                    <MUICheckbox
                        color="primary"
                        checked={checked}
                        onChange={(event) => onCheckboxChange(event, id ?? 0)}
                        disabled={disabled}
                        sx={sx}
                    />
                }
                label={label}
            />
        );
    }

    return (
        <MUICheckbox
            color="primary"
            checked={checked}
            onChange={(event) => onCheckboxChange(event, id ?? 0)}
            disabled={disabled}
            sx={sx}
        />
    );
}
