import TagManager from 'react-gtm-module';
import { ENVIRONMENT_TYPE } from '../config';
import { TokenData } from '../models/App.interfaces';
import { TokenHelper } from './TokenHelper';

export default function setAnalyticsArgs(company?: string) {
    const initialData: TokenData = TokenHelper.getInitialData();
    const customerNumber = initialData.customerNumber || 'unknown';
    const companyName = company || 'unknown';
    const tenantID = initialData.tenantId || 'unknown';

    const tagManagerArgs = {
        dataLayer: {
            /* eslint-disable*/
            'companyName': companyName,
            'customerNumber': customerNumber,
            'tenantID': tenantID,
            'environment': ENVIRONMENT_TYPE,
            /* eslint-enable*/
        },
    };

    TagManager.dataLayer(tagManagerArgs);
}
