import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import {
    Company,
    PaymentHistory,
    PaymentHistoryPagination,
    PaymentHistoryParams,
    Wrapper,
} from '../../../models/App.interfaces';
import { HistoryActions, HistoryFactory } from './HistoryContext.types';

function SetCompanies(
    payload: Wrapper<Company>,
    dispatch: Dispatch<HistoryActions>
) {
    const companies: Company[] = payload?.data ? payload?.data : [];
    dispatch({ type: ActionTypes.ListCompanies, payload: companies });
}

function SetCompanyNumber(payload: number, dispatch: Dispatch<HistoryActions>) {
    dispatch({ type: ActionTypes.SetCompanyNumber, payload: payload });
}

function SetPaymentHistory(
    payload: PaymentHistory[],
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({ type: ActionTypes.ListHistory, payload: payload });
}

function UpdateHistoryParameters(
    payload: PaymentHistoryParams,
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({ type: ActionTypes.UpdateHistoryParameters, payload: payload });
}

function UpdateHistoryPagination(
    payload: PaymentHistoryPagination,
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({
        type: ActionTypes.SetPaymentHistoryPagination,
        payload: payload,
    });
}

function UpdateDateValue(payload: string, dispatch: Dispatch<HistoryActions>) {
    dispatch({ type: ActionTypes.SetDateValue, payload: payload });
}

function UpdateConfirmationPopoverOpen(
    payload: boolean,
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({
        type: ActionTypes.SetConfirmationPopoverOpen,
        payload: payload,
    });
}

function UpdateConfirmationNumber(
    payload: string,
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({ type: ActionTypes.SetConfirmationNumber, payload: payload });
}

function UpdateInvoicesPopoverOpen(
    payload: boolean,
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({ type: ActionTypes.SetInvoicesPopoverOpen, payload: payload });
}

function UpdateInvoicesList(
    payload: string[],
    dispatch: Dispatch<HistoryActions>
) {
    dispatch({ type: ActionTypes.SetInvoicesList, payload: payload });
}

export default function ActionFactory(
    dispatch: Dispatch<HistoryActions>
): HistoryFactory {
    return {
        onSetCompanies: (payload: Wrapper<Company>) =>
            SetCompanies(payload, dispatch),
        onSetCompanyNumber: (payload: number) =>
            SetCompanyNumber(payload, dispatch),
        onSetPaymentHistory: (payload: PaymentHistory[]) =>
            SetPaymentHistory(payload, dispatch),
        onUpdateHistoryParameters: (payload: PaymentHistoryParams) =>
            UpdateHistoryParameters(payload, dispatch),
        onUpdateHistoryPagination: (payload: PaymentHistoryPagination) =>
            UpdateHistoryPagination(payload, dispatch),
        onUpdateDateValue: (payload: string) =>
            UpdateDateValue(payload, dispatch),
        onUpdateConfirmationPopoverOpen: (payload: boolean) =>
            UpdateConfirmationPopoverOpen(payload, dispatch),
        onUpdateConfirmationNumber: (payload: string) =>
            UpdateConfirmationNumber(payload, dispatch),
        onUpdateInvoicesPopoverOpen: (payload: boolean) =>
            UpdateInvoicesPopoverOpen(payload, dispatch),
        onUpdateInvoicesList: (payload: string[]) =>
            UpdateInvoicesList(payload, dispatch),
    };
}
