import Modal from '../../components/modal/Modal';
import Button from '../../components/Button/Button';
import Box from '../../components/box/Box';
import Typography from '../../components/Typography/Typography';
import { useApplication } from '../../context/ApplicationContext';
import {
    PROCEED_TO_PORTAL_PAYMENT,
    REGISTRATION_SUCCESS_TITLE_TEXT,
} from '../../helpers/Constants';

export default function SuccessfulSignup() {
    const [applicationState, actions] = useApplication();
    const { isSuccessfulSignup } = applicationState;

    return (
        <>
            <Modal open={isSuccessfulSignup} maxWidth="xs">
                <Box>
                    <Typography
                        variant="h6"
                        sx={{ pb: 4, fontWeight: 'bold' }}
                        color="otherText.primary"
                    >
                        {REGISTRATION_SUCCESS_TITLE_TEXT}
                    </Typography>
                    <Typography variant="body1" color="otherText.primary">
                        Account successfully created, you are now logged in.
                        Click <i>Sign in</i> next time you visit the portal to
                        access your account, use saved payment methods, view
                        your payment history, or make quicker transactions.
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    title={PROCEED_TO_PORTAL_PAYMENT}
                    onClick={() => {
                        actions.onUpdateIsSuccessfulSignup(false);
                    }}
                    sx={{ mt: 4 }}
                    fullWidth
                />
            </Modal>
        </>
    );
}
