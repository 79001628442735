import { AmountErrorType, PaymentMethodType } from '../models/App.types';
import { Item } from '../models/Props.types';

export const SESSION_DATA_KEY: string = 'SessionData';
export const LOGO_KEY: string = 'logo';
export const CONTACT_INFORMATION_KEY: string = 'contactInformation';
export const NAME_KEY: string = 'name';
export const COMPANY_NAME_KEY: string = 'companyName';
// Key name for session storage to save user info data
export const USER_INFO_KEY: string = 'UserInformation';
// Key name for session storage to save encodedTenantInformation
export const ENCODED_TENANT_KEY: string = 'EncodedTenantInformation';

export const IMAGE_HEADER: string = 'data:application/octet-stream;base64,';
export const CONTACT_INFO_DEFAULT: string = 'your service provider';
export const TRANSACTION_SUCCESS: string = 'Success';
export const TRANSACTION_CANCELLED: string = 'Transaction Cancelled';
export const TRANSACTION_CAPTION_MESSAGE: string =
    'Redirecting you to the payment summary...';
export const TRANSACTION_SUCCESS_MESSAGE: string =
    'Your payment for ${0} was successful. A receipt has been emailed to you at your email.';
export const TRANSACTION_CANCELLED_MESSAGE: string =
    'Your payment was cancelled. You can now safely close this window.';
export const FOOTER: string =
    'For questions about your account or help submitting your payment, please contact {0}.';
export const PAY_FOOTER: string =
    'To get help with making a payment, please contact support at {0}.';

export const CANCEL: string = 'Cancel';
export const EDIT: string = 'Edit';
export const REMOVE: string = 'Remove';
export const COPY_BUTTON_TEXT: string = 'Copy';
export const RETURN_BUTTON_TEXT: string = 'Return';

export const UPDATE_PRIMARY_TITLE: string = 'Change primary payment method?';

export const UPDATE_PRIMARY_TEXT: string =
    'The primary payment method will be selected by default ' +
    'when paying an invoice.';

export const ADD_PAYMENT_METHOD_LABEL: string = 'Add Payment Method';

export const ADD_CC_DETAILS_LABEL: string = 'Add Credit Card Info';

export const SAVE_PAYMENT_METHOD_LABEL: string = 'Save payment method';

export const SET_PRIMARY_CHECKBOX_LABEL: string =
    'Set as primary payment method';

export const SAVE_METHOD_CHECKBOX_TEXT: string =
    'Save payment info for faster future payments';

export const PAYMENT_WILL_BE_STORED_WARNING: string =
    'Your payment method will be stored by WinTeam to be used for any future transactions. ' +
    'Saved Payment Methods may be removed at any time by visiting the Payment Methods tab, ' +
    'hovering over the Nickname field, and selecting the Delete/Remove option.';

export const GO_BACK_TITLE: string = 'Go Back';

export const MODAL_CC_TOP_MESSAGE: string =
    'Clicking "Process Credit Card" will submit your card and billing info to our system.' +
    ' Your card information will be used to process this payment and be saved for later use.';

export const PAYMENT_METHOD_WARNING: string =
    'Your payment was processed, but there was a problem saving the info for later use.';

export const REGISTRATION_SUCCESS_TITLE_TEXT: string =
    'Registration Successful';

export const NO_PAYMENT_HISTORY_MESSAGE: string =
    'No Payment History for this Company';

export const INCLUDE_RETURNS_LABEL: string = 'Include Returns';

export const PROCEED_TO_PORTAL_PAYMENT: string = 'Proceed to Payment Portal';

export const REGISTRATION_SUCCESSFUL: string = 'Registration Successful';

export const REGISTRATION_FAILURE: string = 'Registration Process Failure';

export const REGISTRATION_PROCESS_FAILURE: string =
    'There was a problem creating your Online Payment Portal account. ' +
    'Please contact your Vendor for further assistance with registering your account. ' +
    'You may still log in to make a one-time payment by authenticating with a ' +
    'valid Invoice Number + Customer Number combination on the Online Payment Portal ' +
    'landing page by using the button below.';

export const PAY_BUTTON_TEXT: string = 'Continue to payment';
export const BACK_INVOICES_BUTTON_TEXT: string = 'Back to invoices';

export const PAYMENT_CONF_NUMBER_TITLE: string = 'Payment Confirmation #';
export const INVOICES_POPOVER_TITLE: string = 'Invoices';

export const BACK_SAVED_PAYMENTS_LABEL: string = 'Back to saved payments';

// eslint-disable-next-line max-len
export const PRINT_MARGINS: string = `@page { margin: ${'50px'} ${'20px'} ${'50px'} ${'20px'} !important ; }`;

// Validation constants
export const NUMERIC_REGEX: RegExp = /^[0-9]*$/;
export const ALPHANUMERIC_REGEX: RegExp = /^[a-zA-Z0-9]*$/;
export const DOLLAR_FORMAT: RegExp = /\B(?=(\d{3})+(?!\d))/g;
export const ZIP_REGEX: RegExp = /^\d{5}(?:-?\d{4})?$/;

// Account types
export const ACCOUNT_TYPES = [
    { key: 'Checking', name: 'Checking' },
    { key: 'Savings', name: 'Savings' },
];

export const LAST_DIGITS_TO_SHOW = 4;
export const MASKED_DIGITS_DEFAULT = 6;

export const AMOUNT_ERROR_MESSAGE: { [key in AmountErrorType]: string } = {
    [AmountErrorType.NONE]: '',
    [AmountErrorType.EMPTY]: 'Payment amount cannot be empty',
    [AmountErrorType.EXCEED]: 'Payment amount cannot be greater than invoice',
    [AmountErrorType.EXCEED_PENDING]:
        'Payment amount cannot be greater than Outstanding - Pending Payment',
};

// Tabs
export const TAB_VALUES: Item[] = [
    { label: 'Customer Select', value: '/customers', link: '/customers' },
    {
        label: 'Payment Methods',
        value: '/payment-methods',
        link: '/payment-methods',
    },
    {
        label: 'Payment History',
        value: '/history',
        link: '/history',
    },
];

export const PAGE_SIZE_OPTIONS: number[] = [10, 25, 50];

export const HISTORY_DATE_RANGE_OPTIONS = [
    { key: '90', name: 'Last 90 days' },
    { key: '6', name: 'Last 6 months' },
    { key: '1', name: 'Last year' },
    { key: '0', name: 'All history' },
];

export const PAYMENT_METHOD_TYPES: Item[] = [
    { value: PaymentMethodType.ACH, label: PaymentMethodType.ACH },
    { value: PaymentMethodType.CreditCard, label: 'Credit Card' },
];

export const ADD_NOTE_MODAL_TEXT: string = 'Add notes to payment summary';

export const ADD_NOTE_LABEL: string = 'Type notes here';

export const RETURN_TO_PAYMENT_METHOD_TITLE: string =
    'Return to payment method';

export const ERROR_500_MESSAGE =
    'We were not able to process your request. Please try again later or contact {0}.';

export const ACCOUNT_DETAILS_TEXT = 'Account Details';

export const ADD_SUCCESSFULLY_MESSAGE: string =
    'Payment method has successfully been stored to your account by WinTeam.' +
    ' This method will now appear under your Saved Payment Methods and is ready to use for faster' +
    ' checkouts.';

export const ADD_METHOD_CANCELLED_MESSAGE: string =
    'Your transaction was cancelled. You can now safely close this window.';

export const METHOD_ADDED_SUCCESSFULLY_TEXT: string =
    'Method Added Successfully!';
