import { AxiosError } from 'axios';
import { useGet } from '../api/useGet';
import { PAYMENTS_API } from '../config';
import { COMPANY_NAME_KEY } from '../helpers/Constants';
import { TokenData, Wrapper, Company } from '../models/App.interfaces';
import { TokenHelper } from '../helpers/TokenHelper';

const base = 'customers';

function useFetchToken(
    customerNumber: string,
    invoiceNumber: number,
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccess: (data: Wrapper<TokenData>) => Promise<void> | void
) {
    const companyName: string = sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';
    const domain: string = window.location.hostname;

    return useGet<Wrapper<TokenData>>(
        PAYMENTS_API +
            // eslint-disable-next-line max-len
            `${base}/${customerNumber}/token?invoiceNumber=${invoiceNumber}&domainUrl=${domain}&companyName=${companyName}`,
        false,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccess,
        }
    );
}

function useFetchRefreshToken(
    onSuccess?: (data: Wrapper<TokenData>) => Promise<void> | void,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void
) {
    return useGet<Wrapper<TokenData>>(
        PAYMENTS_API + `${base}/token/refresh`,
        false,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccess,
            staleTime: 0,
        }
    );
}

function useGetCompanies(
    onSuccessCallback?: (data: Wrapper<Company>) => Promise<void> | void,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return useGet<Wrapper<Company>>(
        PAYMENTS_API + `${base}/${customerNumber}/companies`,
        true,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
            staleTime: 0,
        }
    );
}

const CustomersActions = {
    useFetchToken: (
        customerNumber: string,
        invoiceNumber: number,
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccess: (data: Wrapper<TokenData>) => Promise<void> | void
    ) =>
        useFetchToken(
            customerNumber,
            invoiceNumber,
            onErrorCallback,
            onSuccess
        ),
    useFetchRefreshToken: (
        onSuccess?: (data: Wrapper<TokenData>) => Promise<void> | void,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void
    ) => useFetchRefreshToken(onSuccess, onErrorCallback),
    useGetCompanies: (
        onSuccessCallback?: (data: Wrapper<Company>) => Promise<void> | void,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void
    ) => useGetCompanies(onSuccessCallback, onErrorCallback),
};

export default CustomersActions;
