import { JSEncrypt } from 'jsencrypt';

export abstract class EncryptionHelper {
    public static encrypt(valueToEncrypt: string, publicKey: string): string {
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encrypted: string | false = encrypt.encrypt(valueToEncrypt);
        return typeof encrypted === 'string' ? encrypted : '';
    }
}
