import { AmountErrorType, PaymentMethodType } from '../../../models/App.types';
import { InvoiceInformationPayment } from '../../../models/Invoice.interfaces';
import { ActionTypes } from './ActionTypes';
import { PaymentActions, PaymentData } from './PaymentsContext.types';

export const State: PaymentData = {
    Invoices: [],
    CompanyNumber: 0,
    totalSelected: 0,
    isErrorAmount: false,
    TotalAmount: 0,
    paymentMethodType: PaymentMethodType.CreditCard,
    BillingInformation: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        email: '',
        postalCode: '',
    },
    ModalOpen: false,
    TransactionSettings: {
        hostedTransactionId: '',
        hostedTransactionUrl: '',
    },
    Cancelled: false,
    PaymentInformation: {
        referenceNumber: '',
        maskedCardNumber: '',
        cardType: '',
        paymentDate: '',
        wasPaymentMethodSuccessfullySaved: false,
    },
    paymentMethods: [],
    bankAccountInformation: {
        accountNumber: '',
        routingNumber: '',
        accountType: '',
    },
    IsACHModalOpen: false,
    notes: '',
    allowPending: false,
    isSavePaymentMethodChecked: false,
    nickname: '',
    savedPaymentMethods: [],
    addingMethod: false,
};

export function Reducer(
    draft: PaymentData,
    action: PaymentActions
): PaymentData {
    const { type } = action;
    switch (type) {
        case ActionTypes.AddInvoices:
            draft.Invoices = action.payload;
            draft.allowPending = draft.Invoices.some(
                (invoice) => invoice.pendingAmount > 0
            );
            break;
        case ActionTypes.AddCompanyNumber:
            draft.CompanyNumber = action.payload;
            break;
        case ActionTypes.SetAllSelected:
            draft.Invoices.forEach(
                (invoice) => (invoice.isSelected = action.payload)
            );
            draft.totalSelected = action.payload ? draft.Invoices.length : 0;
            break;
        case ActionTypes.SetInvoiceSelected:
            {
                let invoice = draft.Invoices.find(
                    ({ invoiceNumber }) => invoiceNumber === action.payload
                );

                if (invoice) {
                    draft.totalSelected = invoice.isSelected
                        ? draft.totalSelected - 1
                        : draft.totalSelected + 1;

                    invoice.isSelected = !invoice.isSelected;
                }
            }
            break;
        case ActionTypes.DeleteSelectedInvoices:
            draft.Invoices = draft.Invoices.filter(
                (invoice: InvoiceInformationPayment) => !invoice.isSelected
            );
            draft.TotalAmount = draft.Invoices.reduce(
                (sum, value) =>
                    !isNaN(value.amount) ? sum + value.amount : sum,
                0
            );

            draft.isErrorAmount = draft.Invoices.some(
                (invoice) => invoice.errorType !== AmountErrorType.NONE
            );

            draft.allowPending = draft.Invoices.some(
                (invoice) => invoice.pendingAmount > 0
            );
            draft.totalSelected = 0;
            break;
        case ActionTypes.SetAmount:
            draft.TotalAmount = action.payload;
            break;
        case ActionTypes.SetPaymentMethodType:
            draft.paymentMethodType = action.payload;
            draft.bankAccountInformation = {
                accountNumber: '',
                routingNumber: '',
                accountType: '',
            };
            break;
        case ActionTypes.SetInvoiceAmount:
            const [invoiceNumber, amount] = action.payload;
            let invoice = draft.Invoices.find(
                (invoice) => invoice.invoiceNumber === invoiceNumber
            );

            if (invoice) {
                invoice.amount = amount;
                if (invoice.remainingDue - invoice.pendingAmount < amount) {
                    invoice.errorType =
                        invoice.pendingAmount > 0
                            ? AmountErrorType.EXCEED_PENDING
                            : AmountErrorType.EXCEED;
                    draft.isErrorAmount = true;
                } else if (
                    amount === undefined ||
                    amount === 0 ||
                    isNaN(amount)
                ) {
                    invoice.errorType = AmountErrorType.EMPTY;
                    draft.isErrorAmount = true;
                } else {
                    invoice.errorType = AmountErrorType.NONE;
                    draft.isErrorAmount = draft.Invoices.some(
                        (invoice) => invoice.errorType !== AmountErrorType.NONE
                    );
                }
            }

            draft.TotalAmount = draft.Invoices.reduce(
                (sum, value) =>
                    !isNaN(value.amount) ? sum + value.amount : sum,
                0
            );
            break;
        case ActionTypes.SetBillingInformation:
            draft.BillingInformation = action.payload;
            break;
        case ActionTypes.SetModalOpen:
            draft.ModalOpen = action.payload;
            break;
        case ActionTypes.SetTransactionSettings:
            draft.TransactionSettings = action.payload;
            break;
        case ActionTypes.SetCancelled:
            draft.Cancelled = action.payload;
            break;
        case ActionTypes.SetPaymentInformation:
            draft.PaymentInformation = action.payload;
            break;
        case ActionTypes.SetPaymentMethodList:
            draft.paymentMethods = action.payload;
            const valueType: string = action.payload[0]?.value.toString() || '';
            draft.paymentMethodType =
                PaymentMethodType[valueType as keyof typeof PaymentMethodType];
            break;
        case ActionTypes.SetBankAccountInformation:
            draft.bankAccountInformation = action.payload;
            break;
        case ActionTypes.SetIsACHModalOpen:
            draft.IsACHModalOpen = action.payload;
            break;
        case ActionTypes.SetNotes:
            draft.notes = action.payload;
            break;
        case ActionTypes.SetIsSaveMethodChecked:
            draft.isSavePaymentMethodChecked = action.payload;
            break;
        case ActionTypes.SetNickname:
            draft.nickname = action.payload;
            break;
        case ActionTypes.ListSavedPaymentMethods:
            draft.savedPaymentMethods = action.payload;
            draft.selectedMethod = action.payload.find(
                (method) => method.isDefault
            );
            break;
        case ActionTypes.SetSelectedMethod:
            draft.selectedMethod = action.payload;
            break;
        case ActionTypes.SetAddingMethod:
            draft.addingMethod = action.payload;
            break;
    }

    return draft;
}
