import { Container as MUIContainer } from '@mui/material';
import PropTypes from 'prop-types';
import { ContainerProps } from './Container.types';
export default function Container(props: ContainerProps) {
    return (
        <MUIContainer fixed maxWidth={props.maxWidth} sx={props.sx}>
            {props.children}
        </MUIContainer>
    );
}

Container.propTypes = {
    children: PropTypes.node.isRequired,
};
