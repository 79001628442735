import { createContext, useMemo, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { State, Reducer } from './State';
import ActionFactory from './Actions';
import { Props } from '../../../models/Props.types';
import { InvoiceData, InvoiceFactory } from './InvoicesContext.types';

const Invoices = createContext<InvoiceData>(State);
const InvoicesDispatch = createContext<InvoiceFactory>({} as InvoiceFactory);

export function InvoiceDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, State);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);

    useEffect(() => {
        actions.onUpdateCustomerName();
    }, [actions]);

    return (
        <Invoices.Provider value={value}>
            <InvoicesDispatch.Provider value={actions}>
                {children}
            </InvoicesDispatch.Provider>
        </Invoices.Provider>
    );
}

export function useInvoices(): [InvoiceData, InvoiceFactory] {
    const state = useContext(Invoices);
    const actions = useContext(InvoicesDispatch);

    return [state, actions];
}
