import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { INSTRUMENTATION_KEY } from './config';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const applicationInsights = new ApplicationInsights({
    config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

applicationInsights.loadAppInsights();

export { reactPlugin, applicationInsights };
