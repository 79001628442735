import { ErrorPage } from '../models/App.types';

export const NOT_FOUND_KEY = 'NotFound';
export const INTERNAL_SERVER_ERROR_KEY = 'InternalServerError';
export const INTERNAL_SERVER_ERROR_LOGIN_KEY = 'InternalServerErrorLogin';
export const MESSAGE_ERROR_PAGE = 'If the error persists please contact {0}.';

export const PAGE_ERROR_MAP: { [key: string]: ErrorPage } = {
    [NOT_FOUND_KEY]: {
        code: '404',
        description: 'Page Not Found',
        buttonTitle: 'Return to Login',
        route: '/login',
    },
    [INTERNAL_SERVER_ERROR_KEY]: {
        code: 'Error 500',
        description: 'Something went wrong...',
        buttonTitle: 'Return to Login',
        route: '/login',
    },
    [INTERNAL_SERVER_ERROR_LOGIN_KEY]: {
        code: 'Error 500',
        description: 'Something went wrong...',
        buttonTitle: 'Return to Invoices',
        route: '/invoices',
    },
};
