import { IconButton as MUIButton } from '@mui/material';
import { IconButtonProps } from './Button.types';

export default function IconButton(props: IconButtonProps) {
    const {
        color,
        children,
        edge,
        tabIndex,
        onClick,
        className,
        sx,
        disabled,
    } = props;

    return (
        <MUIButton
            color={color}
            onClick={onClick}
            edge={edge}
            tabIndex={tabIndex}
            className={className}
            sx={sx}
            disabled={disabled}
        >
            {children}
        </MUIButton>
    );
}
