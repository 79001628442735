import { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button/Button';
import Spinner from '../../../../components/Spinner/Spinner';
import {
    CreateACHTransaction,
    FormatString,
    hideDigits,
} from '../../../../helpers/Utilities';
import { DOLLAR_FORMAT } from '../../../../helpers/Constants';
import PaymentActions from '../../../../hooks/PaymentsActions';
import { usePayments } from '../../context/PaymentsContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { ModalACHProps } from './ModalACH.types';
import {
    ACHTransactionPost,
    PaymentInformation,
    SavedMethodTransactionPost,
    TransactionData,
} from '../../../../models/Transaction.interfaces';
import { TokenHelper } from '../../../../helpers/TokenHelper';
import Typography from '../../../../components/Typography/Typography';
import { ArrowBack } from '@mui/icons-material';
import { InvoiceToPay } from '../../../../models/Invoice.interfaces';
import ModalText from '../../../../components/modal/ModalText';
import { useMsal } from '@azure/msal-react';
import { Item } from '../../../../models/Props.types';
import MethodGrid from '../../../../components/methodGrid/MethodGrid';
import { PaymentMethodType } from '../../../../models/App.types';
import { errorActions } from '../../../../helpers/ErrorActions';
import ScreenConstants from '../../../../constants/ScreenConstants';
import ButtonConstants from '../../../../constants/ButtonConstants';

export default function ModalACH(props: ModalACHProps) {
    const {
        companyNumber,
        billingInformation,
        invoices,
        totalAmount,
        bankAccountInformation,
        notes,
        nickname,
        isB2CUserAccount,
        isSavedMethod,
        selectedMethod,
        isAddingMethod,
    } = props;
    const [state, actions] = usePayments();
    const CustomerNumber = TokenHelper.getInitialData().customerNumber || '';
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { instance } = useMsal();

    const email = isSavedMethod
        ? TokenHelper.getUserInformation().emailAddress
        : billingInformation.email;

    const {
        IsACHModalOpen,
        PaymentInformation,
        isSavePaymentMethodChecked,
        paymentMethodType,
    } = state;

    const routingNumber: string = hideDigits(
        bankAccountInformation.routingNumber
    );

    const lastFour: string = hideDigits(selectedMethod?.methodLastFour || '');

    const accountNumber: string = hideDigits(
        bankAccountInformation.accountNumber
    );

    const totalPaymentAmount =
        '$' + totalAmount.toFixed(2).replace(DOLLAR_FORMAT, ',');

    const successMessage: string =
        isSavedMethod && selectedMethod?.typeId === PaymentMethodType.CreditCard
            ? ScreenConstants.SUCCESS_CC_TEXT
            : ScreenConstants.SUCCESS_ACH_TEXT;

    const handlePostError = (error: AxiosError) => {
        errorActions.paymentError(
            error,
            navigate,
            enqueueSnackbar,
            instance,
            () => {
                setLoading(false);
                actions.onSetIsACHModalOpen(false);
            }
        );
    };

    const postHostedTransaction =
        PaymentActions.usePostACHTransaction(handlePostError);

    const postSaveMethodTransaction =
        PaymentActions.usePostSavedMethodTransaction(handlePostError);

    const createTransaction = async () => {
        setLoading(true);
        let paymentInformation: PaymentInformation;
        if (isSavedMethod && !isAddingMethod) {
            const transaction: SavedMethodTransactionPost = {
                customerNumber: CustomerNumber,
                paymentMethodId: selectedMethod?.id || 0,
                companyNumber: companyNumber,
                invoicesToPay: invoices,
                email: email,
                notes: notes,
            };

            const response = await postSaveMethodTransaction.mutateAsync(
                transaction
            );

            paymentInformation = {
                referenceNumber: response.data.referenceNumber,
                maskedAccountNumber: selectedMethod?.methodLastFour,
                maskedCardNumber: selectedMethod?.methodLastFour,
                cardType: selectedMethod?.methodDescription,
                paymentDate: response.data.createDate,
                wasPaymentMethodSuccessfullySaved: false,
            };
        } else {
            const savePaymentMethod: boolean =
                isSavePaymentMethodChecked && isB2CUserAccount;
            const hostedTransaction: ACHTransactionPost = CreateACHTransaction(
                companyNumber,
                billingInformation,
                bankAccountInformation,
                invoices,
                notes,
                nickname,
                savePaymentMethod
            );

            const response = await postHostedTransaction.mutateAsync(
                hostedTransaction
            );
            paymentInformation = {
                referenceNumber: response.data.referenceNumber,
                maskedAccountNumber: response.data.maskedAccountNumber,
                paymentDate: response.data.createDate,
                wasPaymentMethodSuccessfullySaved:
                    response.data.wasPaymentMethodSuccessfullySaved,
                didPaymentUpdateFail: response.data.didPaymentUpdateFail,
            };
        }

        setLoading(false);
        setIsSuccess(true);

        actions.onSetPaymentInformation(paymentInformation);
    };

    const handleRedirect = () => {
        const invoicesToPay: InvoiceToPay[] = invoices.map((invoice) => {
            return {
                amount: invoice.amount,
                invoiceNumber: invoice.invoiceNumber,
            };
        });

        const transactionData: TransactionData = {
            email: email,
            amount: totalAmount,
            type:
                isSavedMethod && !isAddingMethod
                    ? selectedMethod?.typeId || ''
                    : paymentMethodType,
            paymentInformation: PaymentInformation,
            invoices: invoicesToPay,
            customerAccount: CustomerNumber,
            notes: notes,
            isSavedMethod: (isSavedMethod && !isAddingMethod) || false,
        };

        navigate('/confirmation', { state: { transactionData } });
    };

    const methodInformation: Item[] = [
        {
            label:
                isSavedMethod && !isAddingMethod
                    ? 'Card/Account No.'
                    : 'Routing Number',
            value: isSavedMethod && !isAddingMethod ? lastFour : routingNumber,
        },
        {
            label:
                isSavedMethod && !isAddingMethod
                    ? 'Expiration'
                    : 'Account Number',
            value:
                isSavedMethod && !isAddingMethod
                    ? selectedMethod?.expirationDate || ''
                    : accountNumber,
        },
        {
            label: 'Invoice Emailed to',
            value: email,
        },
    ];

    return (
        <>
            <Modal
                open={IsACHModalOpen}
                sx={{ mt: 4, mb: 4 }}
                maxWidth="md"
                fullWidth
                allowModalStructure
                title={
                    !isSuccess && (
                        <>
                            <Typography
                                variant="h6"
                                color={'otherText.primary'}
                                sx={{ fontWeight: 'bold' }}
                            >
                                {ScreenConstants.CONFIRM_PROCESS}
                            </Typography>
                        </>
                    )
                }
                modalActions={
                    <>
                        <Button
                            variant="outlined"
                            title={
                                isSuccess
                                    ? ButtonConstants.RETURN_INVOICES_PAGE
                                    : ButtonConstants.BACK_METHOD
                            }
                            onClick={() => {
                                isSuccess
                                    ? navigate('/invoices')
                                    : actions.onSetIsACHModalOpen(false);
                            }}
                            startIcon={<ArrowBack />}
                            disabled={loading}
                        />
                        {!PaymentInformation.didPaymentUpdateFail && (
                            <Button
                                variant="contained"
                                title={
                                    isSuccess
                                        ? ButtonConstants.VIEW_SUMMARY
                                        : isSavePaymentMethodChecked
                                        ? ButtonConstants.ADD_PROCESS_PAYMENT
                                        : ButtonConstants.PROCESS_PAYMENT
                                }
                                onClick={() => {
                                    isSuccess
                                        ? handleRedirect()
                                        : createTransaction();
                                }}
                                startIcon={
                                    loading && (
                                        <Spinner
                                            color="inherit"
                                            size={'30px'}
                                            sx={{ ml: '8px' }}
                                        />
                                    )
                                }
                                disabled={loading}
                            />
                        )}
                    </>
                }
                justifyActions="space-between"
            >
                {isSavePaymentMethodChecked && !isSuccess && (
                    <Typography
                        variant="body1"
                        color={'otherText.primary'}
                        sx={{ mb: '16px' }}
                    >
                        {ScreenConstants.MODAL_ACH_TOP_MESSAGE}
                    </Typography>
                )}
                <Typography
                    variant="body1"
                    color={'otherText.primary'}
                    sx={{ mt: '13px', fontWeight: 'bold', mb: 1 }}
                >
                    {isSuccess
                        ? PaymentInformation.didPaymentUpdateFail
                            ? ScreenConstants.FAILURE
                            : ScreenConstants.SUCCESS
                        : ScreenConstants.PAYMENT_METHOD}
                </Typography>
                {isSuccess ? (
                    <>
                        <ModalText sx={{ mt: '18px' }}>
                            {PaymentInformation.didPaymentUpdateFail
                                ? ScreenConstants.MODAL_ACH_FAILURE_MESSAGE
                                : FormatString(
                                      successMessage,
                                      totalPaymentAmount,
                                      email
                                  )}
                        </ModalText>
                        {PaymentInformation.wasPaymentMethodSuccessfullySaved ===
                            false &&
                            isSavePaymentMethodChecked && (
                                <ModalText
                                    sx={{ mt: '18px', color: 'warning.light' }}
                                >
                                    {ScreenConstants.PAYMENT_METHOD_WARNING}
                                </ModalText>
                            )}
                    </>
                ) : (
                    <MethodGrid
                        methodType={
                            isSavedMethod && !isAddingMethod
                                ? selectedMethod?.methodDescription ||
                                  PaymentMethodType.ACH
                                : PaymentMethodType.ACH
                        }
                        totalAmount={totalPaymentAmount}
                        methodInformation={methodInformation}
                    />
                )}
                {isSavePaymentMethodChecked && !isSuccess && (
                    <Typography
                        variant="body1"
                        color={'otherText.primary'}
                        sx={{ mt: '33px', mb: 1 }}
                    >
                        {ScreenConstants.PAYMENT_WILL_BE_STORED_WARNING}
                    </Typography>
                )}
            </Modal>
        </>
    );
}
