import { Avatar as MUIAvatar } from '@mui/material';
import { AvatarProps } from './Avatar.types';

export default function Avatar(props: AvatarProps) {
    const { sx, variant, children } = props;

    return (
        <MUIAvatar sx={sx} variant={variant}>
            {children}
        </MUIAvatar>
    );
}
