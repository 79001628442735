import { GridRenderCellParams } from '@mui/x-data-grid';
import { PaymentMethods } from '../../../models/App.interfaces';
import MethodActionCell from '../components/MethodActionCell/MethodActionCell';
import PrimaryMethodCell from '../components/PrimaryMethodCell/PrimaryMethodCell';

export const getMethodsColumns = (refetchMethods: () => void) => {
    return [
        {
            field: 'friendlyName',
            headerName: 'Nickname',
            flex: 0.25,
            cellClassName: 'principal-cell--cell',
            sortable: false,
            renderCell: (
                params: GridRenderCellParams<string, PaymentMethods>
            ) => {
                return (
                    <MethodActionCell
                        params={params}
                        refetchMethods={refetchMethods}
                    />
                );
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.25,
            sortable: false,
        },
        {
            field: 'methodLastFour',
            headerName: 'Card/Account No.',
            flex: 0.25,
            sortable: false,
            valueGetter: (params: any) =>
                `${params.row.methodDescription} - *****${params.row.methodLastFour}`,
        },
        {
            field: 'expirationDate',
            headerName: 'Expiration',
            flex: 0.15,
            sortable: false,
            valueGetter: (
                params: GridRenderCellParams<string, PaymentMethods>
            ) =>
                params.row.expirationDate
                    ? `${params.row.expirationDate}${
                          params.row.isExpired ? ' (expired)' : ''
                      }`
                    : '',
        },
        {
            field: 'isDefault',
            headerName: 'Primary',
            flex: 0.1,
            sortable: false,
            renderCell: PrimaryMethodCell,
        },
    ];
};
