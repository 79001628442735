import Button from '../../../components/Button/Button';
import Typography from '../../../components/Typography/Typography';
import Container from '../../../components/container/Container';
import Layout from '../../../components/layout/Layout';
import { ErrorPage } from '../../../models/App.types';
import { COMPANY_NAME_KEY } from '../../../helpers/Constants';
import Metadata from '../../../components/metadata/Metadata';
import MetadataConstants from '../../../constants/MetadataConstants';

export default function ErrorView(props: ErrorPage) {
    const { code, description, message, buttonTitle, route, redirect } = props;
    let redirectRoute = route ?? '/';
    // eslint-disable-next-line no-console
    console.clear();

    const companyName: string = sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';
    const showButton: boolean =
        buttonTitle !== undefined &&
        companyName !== '' &&
        companyName !== 'error' &&
        companyName !== 'invite';

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.ERROR_TAB_TITLE}
                description={MetadataConstants.ERROR_DESCRIPTION}
                noIndex
            />
            <Container>
                <Layout
                    alignItems="center"
                    direction="column"
                    topPadding={10}
                    spacing={2}
                >
                    <Typography variant="h3" text={code} />
                    <Typography variant="h6" text={description} />
                    {showButton && (
                        <Button
                            variant="contained"
                            title={buttonTitle ?? ''}
                            sx={{ mt: 4 }}
                            onClick={() => {
                                redirect ? redirect(redirectRoute) : undefined;
                            }}
                        />
                    )}
                    {message && (
                        <Typography
                            variant="body2"
                            text={message}
                            sx={{ pb: 2 }}
                        />
                    )}
                </Layout>
            </Container>
        </>
    );
}
