import { useLocation, useNavigate } from 'react-router-dom';
import { NOT_FOUND_KEY, PAGE_ERROR_MAP } from '../../helpers/ErrorConstants';
import ErrorView from './views/ErrorView';

export default function ErrorPage() {
    const { state } = useLocation() as any;
    const { code, description, buttonTitle, route } =
        PAGE_ERROR_MAP[state ? state.error : NOT_FOUND_KEY];

    const navigate = useNavigate();

    return (
        <>
            <ErrorView
                code={code}
                description={description}
                message={state?.message}
                buttonTitle={buttonTitle}
                route={route}
                redirect={navigate}
            />
        </>
    );
}
