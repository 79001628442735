import { PaymentMethodType } from '../models/App.types';
import {
    PaymentInformation,
    TransactionData,
} from '../models/Transaction.interfaces';
import { DOLLAR_FORMAT, LAST_DIGITS_TO_SHOW } from './Constants';

interface EmailProps {
    subject: string;
    body: string;
}

export abstract class SummaryHelper {
    static formatPaymentMethod(
        paymentType: string,
        paymentInformation: PaymentInformation
    ) {
        const maskedAccountNumber =
            paymentType === PaymentMethodType.ACH
                ? paymentInformation.maskedAccountNumber?.slice(
                      -LAST_DIGITS_TO_SHOW
                  )
                : paymentInformation.maskedCardNumber;

        return paymentType === PaymentMethodType.ACH
            ? `${PaymentMethodType.ACH} - ${maskedAccountNumber}`
            : `${paymentInformation.cardType} - ${maskedAccountNumber}`;
    }

    static formatTotalAmount(amount: number) {
        return '$' + amount.toFixed(2).replace(DOLLAR_FORMAT, ',');
    }

    static formatPaymentDate(paymentDate: string, hasUTC: boolean) {
        return new Date(paymentDate + (hasUTC ? '' : ' UTC'));
    }

    static formatHeaderDate(paymentDate: Date) {
        return new Intl.DateTimeFormat('en-Us', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        }).format(paymentDate);
    }

    static formatProcessedDate(paymentDate: Date) {
        return new Intl.DateTimeFormat('en-Us', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            hour12: true,
            minute: '2-digit',
        }).format(paymentDate);
    }

    static isNullOrWhitespace(input: string) {
        return !input || !input.trim();
    }

    static formatEmail(
        transactionData: TransactionData,
        companyName: string,
        contactInformation: string
    ) {
        const paymentMethod = SummaryHelper.formatPaymentMethod(
            transactionData.type,
            transactionData.paymentInformation
        );

        const totalAmount = SummaryHelper.formatTotalAmount(
            transactionData.amount
        );

        const paymentDate = SummaryHelper.formatPaymentDate(
            transactionData.paymentInformation.paymentDate,
            transactionData.type === PaymentMethodType.ACH ||
                transactionData.isSavedMethod
        );

        const processedDate = SummaryHelper.formatProcessedDate(paymentDate);

        const notes = !SummaryHelper.isNullOrWhitespace(transactionData.notes)
            ? `

Notes:
${transactionData.notes}`
            : '';

        const body = `We have received your payment. Please keep this confirmation for your records.
            
Payment Confirmation #: ${transactionData.paymentInformation.referenceNumber}
Payment Amount: ${totalAmount}
Payment Method: ${paymentMethod}
Submitted: ${processedDate}
Customer Account ID: ${transactionData.customerAccount}${notes}

Applied to Invoice #:
${transactionData.invoices
    .map(
        (invoice) =>
            `   - Invoice Number: ${
                invoice.invoiceNumber
            }, Amount: $${invoice.amount
                .toFixed(2)
                .replace(DOLLAR_FORMAT, ',')}`
    )
    .join('\r\n')}

Please contact ${contactInformation} with any questions.
${companyName}`;

        const formattedEmail: EmailProps = {
            subject: 'Your Online Payment Confirmation',
            body: encodeURIComponent(body),
        };
        return formattedEmail;
    }
}
