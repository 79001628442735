import Button from '../../../components/Button/Button';
import Typography from '../../../components/Typography/Typography';
import styled from '@emotion/styled';

export const StyledMain = styled.div`
    display: table;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const StyledMiddle = styled.div`
    padding-top: 50px;
    display: table-cell;
    vertical-align: middle;
`;

export const StyledBox = styled.div`
    background-color: #ffffff;
    max-width: 340px;
    min-width: 250px;
    height: auto;
    min-height: 350px;
    position: relative;
    margin: auto;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    padding: 50px;
`;

export const StyledImage = styled.img`
    max-height: 100px;
    max-width: 340px;
    pointer-events: none;
    padding-bottom: 5px;
`;

export const StyledError = styled(Typography)`
    padding-bottom: 15px;
    padding-left: 18px;
    color: #de3432;
`;

export const StyledButton = styled(Button)`
    padding-left: 5px;
`;

export const StyledFooterBox = styled.div`
    max-width: 370px;
    min-width: 250px;
    height: auto;
    position: relative;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
`;

export const StyledSpinner = styled.div`
    margin: 0;
    display: flex;
    justify-content: center;
`;
