import { useGet } from '../api/useGet';
import { COMPANY_NAME_KEY } from '../helpers/Constants';
import { PAYMENTS_API } from '../config';
import { GeneralInformation, Wrapper } from '../models/App.interfaces';
import { AxiosError } from 'axios';
import { usePost } from '../api/usePost';
import { useDelete } from '../api/useDelete';
import { usePut } from '../api/usePut';
import {
    ACHTransactionPost,
    ACHTransactionResponse,
    HostedTransactionPost,
    HostedTransactionPut,
    SavedMethodTransactionPost,
    SavedMethodTransactionResponse,
    TransactionSettings,
} from '../models/Transaction.interfaces';
import { TokenHelper } from '../helpers/TokenHelper';
import { HostedTransactionType } from '../models/App.types';
import { objectToQueryString } from '../helpers/Utilities';

const base = 'payments';

function GetInformation(
    onSuccess?: (data: Wrapper<GeneralInformation>) => Promise<void> | void,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void
) {
    const companyNameUrl: string =
        sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';
    return useGet<Wrapper<GeneralInformation>>(
        PAYMENTS_API +
            `${base}/defaults/information?companyName=${companyNameUrl}`,
        true,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccess,
        }
    );
}

function usePostHostedTransaction(
    onError?: (error: AxiosError) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return usePost<HostedTransactionPost, TransactionSettings>(
        PAYMENTS_API +
            `${base}/customers/${customerNumber}/credit-cards/transactions`,
        {
            onErrorCallback: onError,
        }
    );
}

function usePostACHTransaction(
    onError?: (error: AxiosError) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return usePost<ACHTransactionPost, ACHTransactionResponse>(
        PAYMENTS_API +
            `${base}/customers/${customerNumber}/transfer-accounts/transactions`,
        {
            onErrorCallback: onError,
        }
    );
}

function usePostSavedMethodTransaction(
    onError?: (error: AxiosError) => Promise<void> | void
) {
    return usePost<SavedMethodTransactionPost, SavedMethodTransactionResponse>(
        PAYMENTS_API + `${base}/payment-methods/transactions`,
        {
            onErrorCallback: onError,
        }
    );
}

function usePutHostedTransaction(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return usePut<HostedTransactionPut>(
        PAYMENTS_API +
            `${base}/customers/${customerNumber}/credit-cards/transactions`,
        {
            onErrorCallback: onErrorCallback,
        }
    );
}

function useDeleteHostedTransaction(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return useDelete<string>(
        PAYMENTS_API + `${base}/customers/${customerNumber}/transactions`,
        {
            onErrorCallback: onErrorCallback,
        }
    );
}

function setEventSource(transactionID: string, type: HostedTransactionType) {
    const companyName: string = sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';
    const queryParams = objectToQueryString({
        companyName: companyName,
        hostedTransactionType: type,
    });
    const eventSource = new EventSource(
        PAYMENTS_API +
            `${base}/customers/transactions/${transactionID}/status?${queryParams}`
    );

    return eventSource;
}

const PaymentActions = {
    useGetInformation: (
        onSuccess?: (data: Wrapper<GeneralInformation>) => Promise<void> | void,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void
    ) => GetInformation(onSuccess, onErrorCallback),
    usePostHostedTransaction: (
        onError?: (error: AxiosError) => Promise<void> | void
    ) => usePostHostedTransaction(onError),
    usePutHostedTransaction: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => usePutHostedTransaction(onErrorCallback),
    useDeleteHostedTransaction: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useDeleteHostedTransaction(onErrorCallback),
    setEventSource: (
        hostedTransactionID: string,
        type: HostedTransactionType
    ) => setEventSource(hostedTransactionID, type),
    usePostACHTransaction: (
        onError?: (error: AxiosError) => Promise<void> | void
    ) => usePostACHTransaction(onError),
    usePostSavedMethodTransaction: (
        onError?: (error: AxiosError) => Promise<void> | void
    ) => usePostSavedMethodTransaction(onError),
};

export default PaymentActions;
