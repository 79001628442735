import { ActionsTypes } from './ActionTypes';
import { Information, LoginActions } from './ApplicationContext.types';

export function Reducer(draft: Information, action: LoginActions): Information {
    const { type, payload } = action;
    switch (type) {
        case ActionsTypes.AddCompanyData:
            draft.Company = payload;
            break;
        case ActionsTypes.UpdateCompanyData:
            draft.Company = payload;
            break;
        case ActionsTypes.UpdateIsPopupOpen:
            draft.isPopupOpen = payload;
            break;
        case ActionsTypes.UpdateIsSuccessfulSignup:
            draft.isSuccessfulSignup = payload;
            break;
        case ActionsTypes.AddUserData:
            draft.userInformation = {
                ...payload,
                fullName: `${payload.firstName} ${payload.lastName}`,
            };
            break;
        case ActionsTypes.UpdateUserData:
            draft.userInformation = {
                ...payload,
                fullName: `${payload.firstName} ${payload.lastName}`,
            };
            break;
        case ActionsTypes.UpdateOriginalAccount:
            draft.originalAccount = payload;
            break;
        case ActionsTypes.UpdateIsUserSignedIn:
            draft.isUserSignedIn = payload;
            break;
    }

    return draft;
}
