import { Card as MUICard, CardContent as MUIContent } from '@mui/material';
import { Props } from '../../models/Props.types';

export default function Card(props: Props) {
    const { children } = props;
    return (
        <MUICard>
            <MUIContent>{children}</MUIContent>
        </MUICard>
    );
}
