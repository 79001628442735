import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Typography from '../../../../components/Typography/Typography';
import Modal from '../../../../components/modal/Modal';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Spinner from '../../../../components/Spinner/Spinner';
import { AchDetailsModalProps } from './AchDetailsModal.types';
import {
    PAYMENT_WILL_BE_STORED_WARNING,
    GO_BACK_TITLE,
    SAVE_PAYMENT_METHOD_LABEL,
    CONTACT_INFORMATION_KEY,
    CONTACT_INFO_DEFAULT,
    ERROR_500_MESSAGE,
    ACCOUNT_DETAILS_TEXT,
} from '../../../../helpers/Constants';
import Box from '../../../../components/box/Box';
import { PaymentMethodType } from '../../../../models/App.types';
import UsersActions from '../../../../hooks/UsersActions';
import { ACHPaymentMethodPost } from '../../../../models/Transaction.interfaces';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import {
    CreateACHPaymentMethodPost,
    FormatString,
} from '../../../../helpers/Utilities';
import { TokenHelper } from '../../../../helpers/TokenHelper';
import { Item } from '../../../../models/Props.types';
import MethodGrid from '../../../../components/methodGrid/MethodGrid';
import SuccessModal from '../successModal/SuccessModal';
import ErrorMessageConstants from '../../../../constants/ErrorMessageConstants';

export default function AchDetailsModal(props: AchDetailsModalProps) {
    const {
        isOpenAchDetailsModal,
        setIsOpenAchDetailsModal,
        nickname,
        bankAccountInformation,
        savedPaymentMethodDetails,
        companyNumber,
        navigate,
    } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isOpenSuccessModal, setIsOpenSuccessModal] =
        useState<boolean>(false);
    const contactInfo =
        sessionStorage.getItem(CONTACT_INFORMATION_KEY) ?? CONTACT_INFO_DEFAULT;

    const handlePostError = (error: AxiosError) => {
        if (error.response?.status === 400) {
            enqueueSnackbar(ErrorMessageConstants.INVALID_DATA, {
                variant: 'error',
            });
        } else if (error.response?.status === 500) {
            enqueueSnackbar(FormatString(ERROR_500_MESSAGE, contactInfo), {
                variant: 'error',
            });
        }
        setLoading(false);
        setIsOpenAchDetailsModal(false);
    };

    const postACHPaymentMethod =
        UsersActions.usePostAchPaymentMethod(handlePostError);

    const createAchPaymentMethod = async () => {
        setLoading(true);

        const achPaymentMethod: ACHPaymentMethodPost =
            CreateACHPaymentMethodPost(
                companyNumber,
                bankAccountInformation,
                savedPaymentMethodDetails,
                TokenHelper.getUserInformation().emailAddress
            );
        const response = await postACHPaymentMethod.mutateAsync(
            achPaymentMethod
        );

        setLoading(false);
        setIsOpenAchDetailsModal(false);
        setIsOpenSuccessModal(true);
    };

    const handleCancel = () => {
        setIsOpenAchDetailsModal(false);
    };

    const methodInformation: Item[] = [
        {
            label: 'Nickname',
            value: nickname,
        },
        {
            label: 'Routing Number',
            value: bankAccountInformation.routingNumber,
        },
        {
            label: 'Account Number',
            value: bankAccountInformation.accountNumber,
        },
        {
            label: 'Account Type',
            value: bankAccountInformation.accountType,
        },
    ];

    return (
        <>
            <Modal open={isOpenAchDetailsModal} maxWidth="sm" fullWidth>
                <Box>
                    <Typography variant="h6" color="otherText.primary">
                        {ACCOUNT_DETAILS_TEXT}
                    </Typography>
                    <Box
                        sx={{ bgcolor: '#f5f6f8', pb: 1, pt: 2, pr: 2, mt: 1 }}
                    >
                        <MethodGrid
                            methodType={PaymentMethodType.ACH}
                            methodInformation={methodInformation}
                        />
                    </Box>
                    <Typography
                        variant="body1"
                        color="otherText.primary"
                        sx={{ mb: '16px', mt: 2 }}
                    >
                        {PAYMENT_WILL_BE_STORED_WARNING}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant="outlined"
                            title={GO_BACK_TITLE}
                            onClick={handleCancel}
                            startIcon={<ArrowBack />}
                            disabled={loading}
                        />
                        <Button
                            variant="contained"
                            title={SAVE_PAYMENT_METHOD_LABEL}
                            onClick={createAchPaymentMethod}
                            startIcon={
                                loading && (
                                    <Spinner
                                        color="inherit"
                                        size={'30px'}
                                        sx={{ ml: '8px' }}
                                    />
                                )
                            }
                            disabled={loading}
                        />
                    </Box>
                </Box>
            </Modal>
            <SuccessModal
                isOpenSuccessModal={isOpenSuccessModal}
                setIsOpenSuccessModal={setIsOpenSuccessModal}
                navigate={navigate}
            />
        </>
    );
}
