import { useState } from 'react';
import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { UserMenuProps } from './Menu.types';
import Button from '../Button/Button';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';

export default function UserMenu(props: UserMenuProps) {
    const { buttonTitle, onLogout, onChangePassword, menuOpen, setMenuOpen } =
        props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl) && menuOpen;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                title={buttonTitle}
                variant="text"
                sx={{
                    color: 'primary.contrastText',
                    textTransform: 'none',
                }}
                onClick={handleClick}
            />
            <MenuMui
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{ mt: 1 }}
            >
                <MenuItem onClick={onChangePassword}>
                    <ListItemIcon>
                        <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Change Password</ListItemText>
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </MenuMui>
        </>
    );
}
