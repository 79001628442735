import { useState } from 'react';
import FullPageLoader from '../components/fullPageLoader/FullPageLoader';

type PageLoader = {
    fullPageLoader: JSX.Element | null;
    showLoader: () => void;
    hideLoader: () => void;
};

export default function useFullPageLoader(): PageLoader {
    const [loading, setLoading] = useState(false);

    return {
        fullPageLoader: loading ? <FullPageLoader /> : null,
        showLoader: () => setLoading(true),
        hideLoader: () => setLoading(false),
    };
}
