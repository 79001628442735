import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';
import { DataGridProps } from './DataGrid.types';

export default function DataGrid(props: DataGridProps) {
    const {
        rows,
        columns,
        sx,
        checkboxSelection,
        getRowId,
        loading,
        pageSize,
        rowsPerPageOptions,
        onPageSizeChange,
        hideFooterSelectedRowCount,
        onSelectionModelChange,
        initialState,
        components,
        paginationMode,
        rowCount,
        onPageChange,
        disableColumnMenu,
        keepNonExistentRowsSelected,
        selectionModel,
        onSortModelChange,
        sortingOrder,
        autoHeight,
        isRowSelectable,
        getRowClassName,
        page,
        hideFooter,
        onRowClick,
        onCellClick,
    } = props;
    return (
        <MUIDataGrid
            rows={rows}
            columns={columns}
            sx={sx}
            checkboxSelection={checkboxSelection}
            getRowId={getRowId}
            loading={loading}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageSizeChange={onPageSizeChange}
            hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            onSelectionModelChange={onSelectionModelChange}
            initialState={initialState}
            components={components}
            paginationMode={paginationMode}
            rowCount={rowCount}
            onPageChange={onPageChange}
            disableColumnMenu={disableColumnMenu}
            keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            selectionModel={selectionModel}
            onSortModelChange={onSortModelChange}
            sortingOrder={sortingOrder}
            autoHeight={autoHeight}
            isRowSelectable={isRowSelectable}
            getRowClassName={getRowClassName}
            page={page}
            hideFooter={hideFooter}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
        />
    );
}
