const env = process.env;

export const PAYMENTS_API: string = env.REACT_APP_API || '';

export const INSTRUMENTATION_KEY: string = env.REACT_APP_APPINSIGHTS_KEY || '';

export const GOOGLE_TAG_ID: string = env.REACT_APP_GOOGLE_TAG_ID || '';

export const ENVIRONMENT_TYPE: string = env.REACT_APP_ENVIRONMENT_TYPE || '';

export const LOGOUT_TIME_MINUTES: number = Number(
    env.REACT_APP_LOGOUT_TIME_MINUTES
);

export const B2C_APPLICATION_ID: string =
    env.REACT_APP_B2C_APPLICATION_ID || '';

export const B2C_TENANT_NAME: string = env.REACT_APP_B2C_TENANT_NAME || '';

export const B2C_USERFLOW_SIGNIN: string =
    env.REACT_APP_B2C_USERFLOW_SIGNIN || '';

export const B2C_USERFLOW_SIGNUP: string =
    env.REACT_APP_B2C_USERFLOW_SIGNUP || '';

export const B2C_USERFLOW_CHANGEPASSWORD: string =
    env.REACT_APP_B2C_USERFLOW_CHANGEPASSWORD || '';

export const B2C_READ_PERMISSION: string =
    env.REACT_APP_B2C_READ_PERMISSION || '';

export const B2C_WRITE_PERMISSION: string =
    env.REACT_APP_B2C_WRITE_PERMISSION || '';

export const B2C_REDIRECT_URL: string = env.REACT_APP_B2C_REDIRECT_URL || '';
