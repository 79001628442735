import RadioGroup from '../../../../components/radioGroup/RadioGroup';
import Typography from '../../../../components/Typography/Typography';
import { PaymentMethodSectionProps } from './PaymentMethodSection.types';

export default function PaymentMethodSection(props: PaymentMethodSectionProps) {
    const {
        paymentMethod,
        paymentMethodList,
        onRadioChange,
        isPopupOpen,
        isAddPayment,
    } = props;

    return (
        <>
            {!isAddPayment && (
                <Typography
                    variant="h5"
                    text={'Payment Method'}
                    color={'otherText.primary'}
                />
            )}
            <RadioGroup
                value={paymentMethod}
                items={paymentMethodList}
                onRadioChange={onRadioChange}
                disabled={isPopupOpen}
                sx={{ mt: '10px' }}
            />
        </>
    );
}
