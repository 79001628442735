import { useState } from 'react';
import GridMenu from '../../components/menu/GridMenu';
import Typography from '../../components/Typography/Typography';
import ButtonConstants from '../../constants/ButtonConstants';
import ScreenConstants from '../../constants/ScreenConstants';
import Button from '../Button/Button';
import Modal from '../modal/Modal';
import ModalText from '../modal/ModalText';
import Spinner from '../Spinner/Spinner';
import { ActionCellProps } from './ActionCell.types';

export default function ActionCell(props: ActionCellProps) {
    const {
        row,
        modalMessage,
        menu,
        isModalOpen,
        setIsModalOpen,
        actionItemMutate,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const handleActionItem = async () => {
        setLoading(true);
        await actionItemMutate();
        setLoading(false);
    };

    return (
        <>
            <Typography variant="inherit">{row.name}</Typography>
            <GridMenu id={row.id} values={menu} />
            <Modal
                open={isModalOpen}
                maxWidth="xs"
                allowModalStructure
                title={
                    <>
                        <Typography
                            variant="h6"
                            color={'otherText.primary'}
                            sx={{ fontWeight: 'bold' }}
                        >
                            {ScreenConstants.ARE_YOU_SURE_MESSAGE}
                        </Typography>
                    </>
                }
                modalActions={
                    <>
                        <Button
                            variant="outlined"
                            title={ButtonConstants.CANCEL}
                            onClick={() => setIsModalOpen(false)}
                            disabled={loading}
                        />
                        <Button
                            variant="contained"
                            title={ButtonConstants.REMOVE}
                            onClick={handleActionItem}
                            startIcon={
                                loading && (
                                    <Spinner
                                        color="inherit"
                                        size={'30px'}
                                        sx={{ ml: '8px' }}
                                    />
                                )
                            }
                            disabled={loading}
                        />
                    </>
                }
                justifyActions="space-between"
            >
                <ModalText sx={{ mt: '32px' }}>{modalMessage}</ModalText>
            </Modal>
        </>
    );
}
