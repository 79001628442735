import { useState, ChangeEvent } from 'react';
import Modal from '../../../../components/modal/Modal';
import Box from '../../../../components/box/Box';
import Button from '../../../../components/Button/Button';
import Typography from '../../../../components/Typography/Typography';
import { formNoteValidator } from '../../../../helpers/Validators';
import InputField from '../../../../components/InputField/InputField';
import {
    ADD_NOTE_MODAL_TEXT,
    ADD_NOTE_LABEL,
    RETURN_TO_PAYMENT_METHOD_TITLE,
} from '../../../../helpers/Constants';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { AddNoteModalProps } from './AddNoteModal.types';

export default function AddNoteModal(props: AddNoteModalProps) {
    const { openNotesModal, setOpenNotesModal, note, setNotes } = props;
    const [textNote, setTextNote] = useState(note);
    const [mainError, setMainError] = useState('');

    const methods = useForm({
        resolver: yupResolver(formNoteValidator),
    });

    const {
        handleSubmit,
        resetField,
        formState: { errors },
    } = methods;

    const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTextNote(event.target.value);
    };

    const handleChange = () => {
        if (mainError !== '') {
            setMainError('');
        }
    };

    const handleCancel = () => {
        setOpenNotesModal(false);
        setTextNote(note);
        setMainError('');
        resetField('note', {
            defaultValue: note,
        });
    };

    const onSubmit = async () => {
        setOpenNotesModal(false);
        setNotes(textNote);
        resetField('note', {
            defaultValue: textNote,
        });
    };

    return (
        <>
            <Modal open={openNotesModal} maxWidth="md" fullWidth>
                <Box>
                    <Typography variant="h6" color="otherText.primary">
                        <b>{ADD_NOTE_MODAL_TEXT}</b>
                    </Typography>
                    <FormProvider {...methods}>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            onChange={handleChange}
                        >
                            <Typography
                                variant="body1"
                                text={mainError}
                                sx={{ mt: 4, textAlign: 'center' }}
                                color={'error'}
                            />
                            <InputField
                                variant="subtitle1"
                                labelSx={{ fontWeight: 'bold' }}
                                textFieldSx={{
                                    width: '100%',
                                    mb: 2,
                                }}
                                label={ADD_NOTE_LABEL}
                                id="note"
                                fullWidth
                                error={!!errors.note}
                                helperText={
                                    errors.note &&
                                    errors?.note?.message?.toString()
                                }
                                registerText="note"
                                handleChange={handleNoteChange}
                                color={'text.primary'}
                                type={'text'}
                                multiline
                                rows={6}
                                disabled={!openNotesModal}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    title={RETURN_TO_PAYMENT_METHOD_TITLE}
                                    variant="outlined"
                                    startIcon={<ArrowBack />}
                                    onClick={handleCancel}
                                />
                                <Button
                                    type="submit"
                                    title="Save note"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                />
                            </Box>
                        </form>
                    </FormProvider>
                </Box>
            </Modal>
        </>
    );
}
