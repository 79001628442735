import { useMethods } from '../../context/MethodsContext';
import Radio from '../../../../components/Radio/Radio';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { PaymentMethods } from '../../../../models/App.interfaces';

export default function PrimaryMethodCell(
    params: GridRenderCellParams<boolean, PaymentMethods>
) {
    const { row } = params;
    const [, actions] = useMethods();

    const handleRadioSelect = () => {
        actions.onUpdateIsPrimaryPopupOpen(true);
        actions.onUpdatePrimaryMethodId(row.id);
    };

    return (
        <>
            <Radio
                id={row.id.toString()}
                checked={row.isDefault}
                onChange={handleRadioSelect}
                disabled={row.isExpired}
            />
        </>
    );
}
