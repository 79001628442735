import { STATES } from '../../../../helpers/States';
import InputField from '../../../../components/InputField/InputField';
import FormSelect from '../../../../components/formSelect/FormSelect';
import { NumberFormatCustom } from '../../../../helpers/FormatNumbers';
import { BillingInfoFormProps } from './BillingInfoForm.types';
import Typography from '../../../../components/Typography/Typography';
import Layout from '../../../../components/layout/Layout';

export default function BillingInfoForm(props: BillingInfoFormProps) {
    const {
        billingInformation,
        errors,
        handleChange,
        handleStateSelectChange,
        isPopupOpen,
        isB2CUserAccount,
    } = props;

    return (
        <>
            <Typography
                variant="h5"
                text={'Billing Info'}
                color={'otherText.primary'}
            />
            <Layout
                alignItems="left"
                direction="column"
                topPadding={2}
                spacing={2}
            >
                <InputField
                    label="Billing First Name"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.firstName}
                    helperText={errors.firstName && errors?.firstName.message}
                    registerText="firstName"
                    handleChange={handleChange}
                    disabled={isPopupOpen}
                />
                <InputField
                    label="Billing Last Name"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors?.lastName.message}
                    registerText="lastName"
                    handleChange={handleChange}
                    disabled={isPopupOpen}
                />
                {!isB2CUserAccount && (
                    <InputField
                        label="Email"
                        size="small"
                        textFieldSx={{ width: '276px' }}
                        error={!!errors.email}
                        helperText={errors.email && errors?.email.message}
                        registerText="email"
                        handleChange={handleChange}
                        disabled={isPopupOpen}
                    />
                )}
                <InputField
                    label="Address"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.address}
                    helperText={errors.address && errors?.address.message}
                    registerText="address"
                    handleChange={handleChange}
                    disabled={isPopupOpen}
                />
                <InputField
                    label="City"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.city}
                    helperText={errors.city && errors?.city.message}
                    registerText="city"
                    handleChange={handleChange}
                    disabled={isPopupOpen}
                />
                <FormSelect
                    label="State"
                    registerText="state"
                    items={STATES}
                    value={billingInformation.state}
                    onChange={handleStateSelectChange}
                    size="small"
                    error={!!errors.state}
                    helperText={errors.state && errors?.state.message}
                    sx={{ width: '276px' }}
                    select
                    selectProps={{
                        MenuProps: {
                            PaperProps: { sx: { maxHeight: '200px' } },
                        },
                    }}
                    disabled={isPopupOpen}
                />
                <InputField
                    label="Zip Code"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.postalCode}
                    helperText={errors.postalCode && errors?.postalCode.message}
                    registerText="postalCode"
                    handleChange={handleChange}
                    inputProps={{
                        format:
                            billingInformation.postalCode.length > 5
                                ? '#####-####'
                                : undefined,
                        limit: 9,
                    }}
                    inputComponent={NumberFormatCustom}
                    disabled={isPopupOpen}
                />
            </Layout>
        </>
    );
}
