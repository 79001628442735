import { createContext, useMemo, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { State, Reducer } from './State';
import ActionFactory from './Actions';
import { InvoiceData } from '../../../models/Invoice.interfaces';
import { Props } from '../../../models/Props.types';
import { PaymentData, PaymentFactory } from './PaymentsContext.types';

const Payments = createContext<PaymentData>(State);
const PaymentsDispatch = createContext<PaymentFactory>({} as PaymentFactory);

export function PaymentsDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, State);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);
    const stateLocation = useLocation().state as InvoiceData;
    const navigate = useNavigate();

    useEffect(() => {
        if (stateLocation === null || stateLocation.SelectedRows.length === 0) {
            navigate('/invoices');
        } else {
            actions.onSetInvoices(stateLocation.SelectedRows);
            actions.onSetCompanyNumber(stateLocation.CompanyNumber);
            actions.onSetPaymentMethods();
        }
    }, []);

    return (
        <Payments.Provider value={value}>
            <PaymentsDispatch.Provider value={actions}>
                {children}
            </PaymentsDispatch.Provider>
        </Payments.Provider>
    );
}

export function usePayments(): [PaymentData, PaymentFactory] {
    const state = useContext(Payments);
    const actions = useContext(PaymentsDispatch);

    return [state, actions];
}
