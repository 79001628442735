import { ChangeEvent, forwardRef, ForwardedRef } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    formBankAccountInformationValidator,
    formBillingInfoValidator,
} from '../../../../helpers/Validators';
import Button from '../../../../components/Button/Button';
import { SavedPaymentMethodFormProps, FormRef } from './FormSection.types';
import Grid from '../../../../components/Grid/Grid';
import GridItem from '../../../../components/Grid/GridItem';
import InputField from '../../../../components/InputField/InputField';
import Typography from '../../../../components/Typography/Typography';
import Checkbox from '../../../../components/checkbox/Checkbox';
import {
    ADD_CC_DETAILS_LABEL,
    CANCEL,
    PAYMENT_WILL_BE_STORED_WARNING,
    SET_PRIMARY_CHECKBOX_LABEL,
    SAVE_PAYMENT_METHOD_LABEL,
} from '../../../../helpers/Constants';
import Box from '../../../../components/box/Box';
import BillingInfoForm from '../../../Payments/components/billingInfoForm/BillingInfoForm';
import BankAccountForm from '../../../Payments/components/bankAccountForm/BankAccountForm';
import { StyledBreak, StyledPaperBottom } from '../../views/styling';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { PaymentMethodType } from '../../../../models/App.types';

function FormSection(
    props: SavedPaymentMethodFormProps,
    ref: ForwardedRef<FormRef>
) {
    const {
        savedPaymentMethodDetails,
        paymentMethodType,
        setPaymentMethodDetails,
        setBankAccountInformation,
        bankAccountInformation,
        onCancel,
        onSubmit,
        disabled,
    } = props;

    const schema =
        paymentMethodType === PaymentMethodType.ACH
            ? formBillingInfoValidator(true).concat(
                  formBankAccountInformationValidator
              )
            : formBillingInfoValidator(true);

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    const onMethodSubmit = async () => {
        onSubmit(savedPaymentMethodDetails);
    };

    const handleStateSelectChange = (event: SelectChangeEvent) => {
        setPaymentMethodDetails({
            ...savedPaymentMethodDetails,
            state: event.target.value,
        });
    };

    const handleBillingInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPaymentMethodDetails({
            ...savedPaymentMethodDetails,
            [name]: value,
        });
    };

    const handlePrimaryCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPaymentMethodDetails({
            ...savedPaymentMethodDetails,
            isPrimary: event.target.checked,
        });
    };

    const handleAccounTypeSelectChange = (event: SelectChangeEvent) => {
        setBankAccountInformation({
            ...bankAccountInformation,
            accountType: event.target.value,
        });
    };

    const handleBankAccountInformationChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setBankAccountInformation({
            ...bankAccountInformation,
            [name]: value,
        });
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onMethodSubmit)}
                    noValidate
                >
                    <StyledPaperBottom>
                        <InputField
                            label="Nickname"
                            size="small"
                            textFieldSx={{
                                width: '276px',
                                mt: '16px',
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            registerText="nickname"
                            handleChange={handleBillingInfoChange}
                            type={'text'}
                        />
                        <StyledBreak />
                        <Grid spacing={2}>
                            <GridItem xs={12} md={6}>
                                <>
                                    <BillingInfoForm
                                        billingInformation={
                                            savedPaymentMethodDetails
                                        }
                                        handleStateSelectChange={
                                            handleStateSelectChange
                                        }
                                        errors={errors}
                                        handleChange={handleBillingInfoChange}
                                        isB2CUserAccount={true}
                                    />
                                    <Box sx={{ mt: 4 }}>
                                        <Checkbox
                                            checked={
                                                savedPaymentMethodDetails.isPrimary
                                            }
                                            onCheckboxChange={
                                                handlePrimaryCheckChange
                                            }
                                            label={SET_PRIMARY_CHECKBOX_LABEL}
                                        />
                                    </Box>
                                </>
                            </GridItem>
                            <GridItem>
                                <>
                                    {paymentMethodType ===
                                        PaymentMethodType.ACH && (
                                        <BankAccountForm
                                            bankAccountInformation={
                                                bankAccountInformation
                                            }
                                            handleAccounTypeSelectChange={
                                                handleAccounTypeSelectChange
                                            }
                                            errors={errors}
                                            handleChange={
                                                handleBankAccountInformationChange
                                            }
                                        />
                                    )}
                                </>
                            </GridItem>
                        </Grid>
                    </StyledPaperBottom>
                    <Typography
                        variant="body1"
                        color={'otherText.primary'}
                        sx={{ textAlign: 'center', pt: 3 }}
                    >
                        {PAYMENT_WILL_BE_STORED_WARNING}
                    </Typography>
                    <Box
                        sx={{
                            pt: 5,
                            pl: 2,
                            pr: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            title={CANCEL}
                            onClick={onCancel}
                            startIcon={<KeyboardArrowLeftIcon />}
                            disabled={disabled}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            title={
                                paymentMethodType === PaymentMethodType.ACH
                                    ? SAVE_PAYMENT_METHOD_LABEL
                                    : ADD_CC_DETAILS_LABEL
                            }
                            sx={{ float: 'right' }}
                            disabled={disabled}
                        />
                    </Box>
                </form>
            </FormProvider>
        </>
    );
}

export default forwardRef<FormRef, SavedPaymentMethodFormProps>(FormSection);
