import { PAYMENTS_API } from '../config';
import { Wrapper } from '../models/App.interfaces';
import { AxiosError } from 'axios';
import { useGet } from '../api/useGet';

const base = 'companies';

function useValidateCompany(
    companyNumber: number,
    enable: boolean,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: () => Promise<void> | void
) {
    return useGet<Wrapper<boolean>>(
        PAYMENTS_API +
            `${base}/credentials/validate?companyNumber=${companyNumber}`,
        enable,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
        }
    );
}

const CompaniesActions = {
    useValidateCompany: (
        companyNumber: number,
        enable: boolean,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: () => Promise<void> | void
    ) =>
        useValidateCompany(
            companyNumber,
            enable,
            onErrorCallback,
            onSuccessCallback
        ),
};

export default CompaniesActions;
