import Typography from '../../Typography/Typography';
import { StyledFooter, StyledFooterBox, StyledLogo } from './styling';
import ScreenConstants from '../../../constants/ScreenConstants';
import FooterLogo from '../../../images/ts_footer_logo.png';

export default function LogoFooter() {
    const { POWERED_BY_TEXT, TEAM_SOFTWARE_TEXT } = ScreenConstants;

    return (
        <StyledFooterBox>
            <StyledFooter>
                <Typography
                    text={POWERED_BY_TEXT}
                    variant="body2"
                    color="text.primary"
                    sx={{ pr: 2.5 }}
                />
                <StyledLogo src={FooterLogo} alt={TEAM_SOFTWARE_TEXT} />
            </StyledFooter>
        </StyledFooterBox>
    );
}
