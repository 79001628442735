import { Typography as MUITypography } from '@mui/material';
import { TypographyProps } from './Typography.types';

export default function Typography(props: TypographyProps) {
    const { variant, sx, display, align, color, text, children } = props;
    return (
        <MUITypography
            variant={variant}
            sx={sx}
            display={display}
            align={align}
            color={color}
            style={{ whiteSpace: 'pre-wrap' }}
        >
            {text ?? children}
        </MUITypography>
    );
}
