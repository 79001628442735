import { TableCell as MUITableCell } from '@mui/material';
import { CellProps } from './TableCell.types';

export default function TableCell(props: CellProps) {
    const { children, id, padding, align, colspan } = props;
    return (
        <MUITableCell id={id} padding={padding} colSpan={colspan} align={align}>
            {children}
        </MUITableCell>
    );
}
