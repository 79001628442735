import { Box as MUIBox } from '@mui/material';
import { ContainerProps } from '../../models/Props.types';

export default function Box(props: ContainerProps) {
    const { children, sx, display, justifyContent, alignItems, position } =
        props;
    return (
        <MUIBox
            display={display}
            justifyContent={justifyContent}
            alignItems={alignItems}
            sx={sx}
            position={position}
        >
            {children}
        </MUIBox>
    );
}
