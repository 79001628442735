import UsersActions from '../../../../hooks/UsersActions';
import { errorActions } from '../../../../helpers/ErrorActions';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { Actions, Row } from '../../../../models/Props.types';
import { useMethods } from '../../context/MethodsContext';
import { Wrapper } from '../../../../models/App.interfaces';
import ButtonConstants from '../../../../constants/ButtonConstants';
import ScreenConstants from '../../../../constants/ScreenConstants';
// eslint-disable-next-line max-len
import CreditCardMethodModal from '../../../../components/creditCardMethodModal/CreditCardMethodModal';
import PaymentActions from '../../../../hooks/PaymentsActions';
import {
    EventStatus,
    HostedTransactionType,
} from '../../../../models/App.types';
import {
    BillingInformation,
    CreditCardPaymentMethodReplace,
} from '../../../../models/Transaction.interfaces';
import { useApplication } from '../../../../context/ApplicationContext';
import ActionCell from '../../../../components/ActionCell/ActionCell';
import { CreateRevertCreditCardMethod } from '../../../../helpers/Utilities';
import { TokenHelper } from '../../../../helpers/TokenHelper';
import { MethodActionCellProps } from './MethodActionCell.types';

export default function MethodActionCell(props: MethodActionCellProps) {
    const { params, refetchMethods } = props;
    const { row, value } = params;

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { instance } = useMsal();
    const [state, actions] = useMethods();
    const [applicationState] = useApplication();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCreditCardModalOpen, setIsCreditCardModalOpen] =
        useState<boolean>(false);
    const { Company } = applicationState;

    const {
        companyNumber,
        isCreditCardLoading,
        hasEventSourceFailed,
        creditCardTransactionSettings,
        streamEventStatus,
    } = state;

    const methodRow: Row = {
        name:
            value === ''
                ? `${row.methodDescription} - *****${row.methodLastFour} `
                : value || '',
        id: row.id.toString(),
    };

    // DELETE action section
    const deleteMethod = UsersActions.useDeletePaymentMethod((error) => {
        errorActions.defaultError(error, navigate, enqueueSnackbar, instance);
        setIsModalOpen(false);
    });

    const handleRemoveAction = () => {
        setIsModalOpen(true);
    };

    const handleDeleteMethod = async () => {
        await deleteMethod.mutateAsync(row.id.toString());
        actions.onDeletePaymentMethod(row.id.toString());
        setIsModalOpen(false);
    };
    // END DELETE action section

    // REPLACE Action section
    const replaceCCPaymentMethod =
        UsersActions.useReplaceCreditCardPaymentMethod(
            row.id.toString() || '',
            (error) =>
                errorActions.creditCardMethodError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance,
                    setIsCreditCardModalOpen,
                    Company.contactInformation
                )
        );

    const handleGetBillingInfoSuccess = async (
        billingInformation: Wrapper<BillingInformation>
    ) => {
        const creditCardMethod: CreditCardPaymentMethodReplace =
            CreateRevertCreditCardMethod(
                billingInformation.data[0],
                TokenHelper.getUserInformation().emailAddress
            );

        const response = await replaceCCPaymentMethod.mutateAsync({
            body: creditCardMethod,
            id: '',
        });

        actions.onSetCreditCardTransactionSettings(response.data);
        setupEventStream(response.data.hostedTransactionId);
    };

    const { refetch: refetchBillingInfo } =
        UsersActions.useGetBillingInformation(
            row.id.toString() || '',
            handleGetBillingInfoSuccess,
            (error) =>
                errorActions.creditCardMethodError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance,
                    setIsCreditCardModalOpen,
                    Company.contactInformation
                )
        );

    // Initialize event stream; when first message is received, closes stream
    const setupEventStream = (hostedTransactionId: string) => {
        const eventSource = PaymentActions.setEventSource(
            hostedTransactionId,
            HostedTransactionType.PaymentMethod
        );
        eventSource.addEventListener('message', handleEvent);
        eventSource.onmessage = () => {
            eventSource.close();
        };
        eventSource.onerror = () => {
            actions.onSetHasEventSourceFailed(true);
            eventSource.close();
        };
    };

    const handleEvent = (event: any) => {
        const parsedData = JSON.parse(event.data);
        const status = parsedData.status;
        actions.onSetStreamEventStatus(
            EventStatus[status as keyof typeof EventStatus]
        );

        if (status === EventStatus.TimedOut) {
            actions.onCreditCardModalReset();
            setIsCreditCardModalOpen(false);
        }
    };

    const handleUpdateAction = () => {
        actions.onSetIsCreditCardLoading(true);
        setIsCreditCardModalOpen(true);
        refetchBillingInfo();
    };

    const menu: Actions[] = [
        { label: ButtonConstants.DELETE_REMOVE, action: handleRemoveAction },
        ...(row.isExpired
            ? [
                  {
                      label: ButtonConstants.UPDATE_CREDIT_CARD,
                      action: handleUpdateAction,
                  },
              ]
            : []),
    ];

    const onCloseCreditCardModal = () => {
        actions.onCreditCardModalReset();
        setIsCreditCardModalOpen(false);
    };

    return (
        <>
            <ActionCell
                row={methodRow}
                menu={menu}
                modalMessage={ScreenConstants.DELETE_METHOD_TEXT}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                actionItemMutate={handleDeleteMethod}
            />
            <CreditCardMethodModal
                isCreditCardModalOpen={isCreditCardModalOpen}
                isCreditCardLoading={isCreditCardLoading}
                hasEventSourceFailed={hasEventSourceFailed}
                creditCardTransactionSettings={creditCardTransactionSettings}
                streamEventStatus={streamEventStatus}
                onCreditCardModalReset={onCloseCreditCardModal}
                onSuccessClick={() => {
                    onCloseCreditCardModal();
                    refetchMethods();
                }}
            />
        </>
    );
}
