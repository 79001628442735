export default {
    LOGIN_TAB_TITLE: 'Login - {0}',
    LOGIN_DESCRIPTION:
        // eslint-disable-next-line max-len
        'Use this page for quick and secure payment of your {0} invoice. Please use the invoice and customer number to access your account.',
    INVOICES_TAB_TITLE: 'Open Invoices',
    INVOICES_DESCRIPTION:
        // eslint-disable-next-line max-len
        'Use this page to view all outstanding invoices and balances for your account with {0}.',
    PAYMENTS_TAB_TITLE: 'Review & Submit Payment',
    PAYMENTS_DESCRIPTION:
        'Review your payment details and submit your secure payment to {0}.',
    SUMMARY_TAB_TITLE: 'Payment Confirmation',
    SUMMARY_DESCRIPTION:
        'View your payment receipt and reference number for your payment submitted to {0}.',
    ERROR_TAB_TITLE: 'Error',
    ERROR_DESCRIPTION:
        // eslint-disable-next-line max-len
        'This page displays errors you may have run into while using the online payments portal.',
    CUSTOMERS_TAB_TITLE: 'Customer Select',
    CUSTOMERS_DESCRIPTION:
        'Use this page to view, select, or add a customer under your account.',
    METHODS_TAB_TITLE: 'Payment Methods',
    METHODS_DESCRIPTION:
        'Use this page to view, update, add, or delete a payment method under your account.',
    ADD_METHOD_TAB_TITLE: 'Add Payment Method',
    ADD_METHOD_DESCRIPTION:
        'Use this page to add a new payment method to your account.',
    HISTORY_TAB_TITLE: 'Payment History',
    HISTORY_DESCRIPTION:
        'Use this page to view previous transactions made under your account.',
    EMAIL_RESPONSE_TAB_TITLE: 'Registration Status',
    EMAIL_RESPONSE_DESCRIPTION:
        'Shows the status of a payment portal account registration attempt.',
} as const;
