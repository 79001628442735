import Typography from '../../../../components/Typography/Typography';
import Box from '../../../../components/box/Box';
import Modal from '../../../../components/modal/Modal';
import { SuccessModalProps } from './SuccessModal.types';
import Button from '../../../../components/Button/Button';
import {
    ADD_SUCCESSFULLY_MESSAGE,
    METHOD_ADDED_SUCCESSFULLY_TEXT,
    RETURN_TO_PAYMENT_METHOD_TITLE,
} from '../../../../helpers/Constants';

export default function SuccessModal(props: SuccessModalProps) {
    const { isOpenSuccessModal, navigate } = props;

    const handleCancel = () => {
        navigate('/payment-methods');
    };

    return (
        <Modal open={isOpenSuccessModal} maxWidth="xs" fullWidth>
            <Box>
                <Typography
                    variant="h6"
                    color="otherText.primary"
                    sx={{ fontWeight: 'bold' }}
                >
                    {METHOD_ADDED_SUCCESSFULLY_TEXT}
                </Typography>
                <Typography
                    variant="body1"
                    color="otherText.primary"
                    sx={{ mt: '32px', mb: '32px' }}
                >
                    {ADD_SUCCESSFULLY_MESSAGE}
                </Typography>
                <Button
                    variant="contained"
                    title={RETURN_TO_PAYMENT_METHOD_TITLE}
                    onClick={handleCancel}
                    fullWidth
                />
            </Box>
        </Modal>
    );
}
