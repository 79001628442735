import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import { AddMethodActions, AddMethodFactory } from './AddMethodContext.types';
import {
    SavedPaymentMethod,
    BankAccountInformation,
    TransactionSettings,
} from '../../../models/Transaction.interfaces';
import { Company, Wrapper } from '../../../models/App.interfaces';
import { EventStatus, PaymentMethodType } from '../../../models/App.types';

function SetPaymentMethodType(
    payload: PaymentMethodType,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetPaymentMethodType, payload: payload });
}

function SetPaymentMethodDetails(
    payload: SavedPaymentMethod,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetPaymentMethodDetails, payload: payload });
}

function SetCompanies(
    payload: Wrapper<Company>,
    dispatch: Dispatch<AddMethodActions>
) {
    const companies: Company[] = payload?.data ? payload?.data : [];
    dispatch({ type: ActionTypes.SetCompanies, payload: companies });
}

function setCompanyNumber(
    payload: number,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetCompanyNumber, payload: payload });
}

function SetBankAccountInformation(
    payload: BankAccountInformation,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetBankAccountInformation, payload: payload });
}

function SetIsCreditCardModalOpen(
    payload: boolean,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetIsCreditCardModalOpen, payload: payload });
}

function SetHasEventSourceFailed(
    payload: boolean,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetHasEventSourceFailed, payload: payload });
}

function SetCreditCardTransactionSettings(
    payload: TransactionSettings,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({
        type: ActionTypes.SetCreditCardTransactionSettings,
        payload: payload,
    });
}

function SetStreamEventStatus(
    payload: EventStatus,
    dispatch: Dispatch<AddMethodActions>
) {
    dispatch({ type: ActionTypes.SetStreamEventStatus, payload: payload });
}

function CreditCardModalReset(dispatch: Dispatch<AddMethodActions>) {
    dispatch({ type: ActionTypes.SetCreditCardModalReset });
}

export default function ActionFactory(
    dispatch: Dispatch<AddMethodActions>
): AddMethodFactory {
    return {
        onSetPaymentMethodType: (payload: PaymentMethodType) =>
            SetPaymentMethodType(payload, dispatch),
        onSetPaymentMethodDetails: (payload: SavedPaymentMethod) =>
            SetPaymentMethodDetails(payload, dispatch),
        onSetCompanies: (payload: Wrapper<Company>) =>
            SetCompanies(payload, dispatch),
        onSetCompanyNumber: (payload: number) =>
            setCompanyNumber(payload, dispatch),
        onSetBankAccountInformation: (payload: BankAccountInformation) =>
            SetBankAccountInformation(payload, dispatch),
        onSetIsCreditCardModalOpen: (payload: boolean) =>
            SetIsCreditCardModalOpen(payload, dispatch),
        onSetHasEventSourceFailed: (payload: boolean) =>
            SetHasEventSourceFailed(payload, dispatch),
        onSetCreditCardTransactionSettings: (payload: TransactionSettings) =>
            SetCreditCardTransactionSettings(payload, dispatch),
        onSetStreamEventStatus: (payload: EventStatus) =>
            SetStreamEventStatus(payload, dispatch),
        onCreditCardModalReset: () => CreditCardModalReset(dispatch),
    };
}
