import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import IconButton from '../Button/IconButton';
import Typography from '../Typography/Typography';
import { InputFieldProps } from './InputField.types';

export default function InputField(props: InputFieldProps) {
    const { register } = useFormContext();
    const {
        variant,
        autoFocus,
        labelSx,
        text,
        label,
        required,
        id,
        fullWidth,
        startAdornment,
        error,
        helperText,
        textFieldSx,
        registerText,
        handleChange,
        color,
        size,
        inputProps,
        inputComponent,
        disabled,
        type,
        endAdornment,
        password,
        multiline,
        rows,
    } = props;

    const passwordInputProps: object = password
        ? {
              style: {
                  textSecurity: `${password.isValueMasked ? 'disc' : ''} `,
                  fontFamily: `${
                      password.isValueMasked ? 'text-security-disc' : ''
                  } `,
                  WebkitTextSecurity: `${
                      password.isValueMasked ? 'disc' : ''
                  } `,
              },
          }
        : {};

    return (
        <>
            {text && (
                <Typography variant={variant} sx={labelSx} color={color}>
                    {text}
                </Typography>
            )}
            <TextField
                autoComplete="off"
                label={label}
                required={required}
                autoFocus={autoFocus}
                id={id}
                fullWidth={fullWidth}
                InputProps={{
                    inputComponent: inputComponent,
                    startAdornment: startAdornment ? (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ) : undefined,
                    endAdornment: endAdornment ? (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    ) : password ? (
                        <IconButton
                            onClick={password.onClick}
                            edge="end"
                            tabIndex={-1}
                        >
                            {password.isValueMasked ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    ) : undefined,
                }}
                inputProps={{
                    ...inputProps,
                    'data-testid': registerText,
                    'aria-autocomplete': 'none',
                    ...passwordInputProps,
                }}
                error={error}
                helperText={helperText}
                sx={textFieldSx}
                size={size}
                {...register(registerText, {
                    onChange: (event) => handleChange(event),
                })}
                disabled={disabled}
                type={type}
                multiline={multiline}
                rows={rows}
            />
        </>
    );
}
