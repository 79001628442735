import styled from '@emotion/styled';

export const StyledFooterBox = styled.div`
    padding-bottom: 20px;
    height: auto;
    position: relative;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
`;

export const StyledFooter = styled.div`
    position: relative;
    text-align: center;
`;
