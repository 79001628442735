import { useState } from 'react';
import Select from '../../../components/Select/Select';
import DataGrid from '../../../components/DataGrid/DataGrid';
import Typography from '../../../components/Typography/Typography';
import Container from '../../../components/container/Container';
import Modal from '../../../components/modal/Modal';
import Spinner from '../../../components/Spinner/Spinner';
import Box from '../../../components/box/Box';
import Button from '../../../components/Button/Button';
import UsersActions from '../../../hooks/UsersActions';
import { PatchPaymentMethodPath } from '../../../models/App.types';
import { useMsal } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { errorActions } from '../../../helpers/ErrorActions';
import {
    Wrapper,
    PaymentMethods,
    Company,
} from '../../../models/App.interfaces';
import { useMethods } from '../context/MethodsContext';
import { CreateUpdatePaymentMethod } from '../../../helpers/Utilities';
import theme from '../../../Theme';
import Paper from '../../../components/paper/Paper';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import {
    UPDATE_PRIMARY_TITLE,
    UPDATE_PRIMARY_TEXT,
    ADD_PAYMENT_METHOD_LABEL,
} from '../../../helpers/Constants';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MetadataConstants from '../../../constants/MetadataConstants';
import Metadata from '../../../components/metadata/Metadata';
import { getMethodsColumns } from '../helpers/MethodColumns';

export default function MethodsView() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [state, actions] = useMethods();
    const { instance } = useMsal();
    const [loading, setLoading] = useState<boolean>();

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const handleCompanySuccess = (data: Wrapper<Company>) => {
        actions.onSetCompanies(data);
        actions.onUpdateCompanyNumber(data.data[0].companyNumber);
    };

    const {
        isLoading: companiesLoading,
        isFetching: companiesFetching,
        isSuccess: isSuccessCompanies,
    } = UsersActions.useGetCompaniesbyUser(
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            ),
        handleCompanySuccess
    );

    const handlePaymentMethodSuccess = (data: Wrapper<PaymentMethods>) => {
        if (data !== undefined) {
            actions.onSetPaymentMethods(data);
        }
    };

    const { isLoading, isFetching, refetch } =
        UsersActions.useGetPaymentMethods(
            {
                companyNumber: state.companyNumber,
                excludeExpired: false,
            },
            isSuccessCompanies && state.companyNumber > 0,
            (error) =>
                errorActions.defaultError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance
                ),
            handlePaymentMethodSuccess
        );

    const handlePaymentMethodUpdateSuccess = () => {
        setLoading(false);
        actions.onUpdateIsPrimaryPopupOpen(false);
        refetch();
    };

    const updatePaymentMethod = UsersActions.useUpdatePaymentMethod((error) => {
        actions.onUpdateIsPrimaryPopupOpen(false);
        errorActions.defaultError(error, navigate, enqueueSnackbar, instance);
    }, handlePaymentMethodUpdateSuccess);

    const handleCancel = () => {
        actions.onUpdateIsPrimaryPopupOpen(false);
    };

    const handlePrimaryChange = () => {
        setLoading(true);
        const body = CreateUpdatePaymentMethod(
            PatchPaymentMethodPath.primary,
            true
        );
        updatePaymentMethod.mutate({
            body: body,
            id: state.primaryMethodId,
        });
    };

    const handleCompanyChange = (event: any) => {
        actions.onUpdateCompanyNumber(parseInt(event.target.value));
    };

    function customNoRowsOverlay() {
        const message: string = 'No Payment Methods for this Company.';
        return (
            <Typography
                variant="h5"
                text={message}
                sx={{ textAlign: 'center', mt: 22 }}
                color="text.primary"
            />
        );
    }

    const handleAddMethod = () => {
        navigate('/add-method');
    };

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.METHODS_TAB_TITLE}
                description={MetadataConstants.METHODS_DESCRIPTION}
                noIndex
            />
            <Container maxWidth="xl" sx={{ pt: '70px', minWidth: '900px' }}>
                <Select
                    native
                    sx={{
                        minWidth: '200px',
                        bgcolor: 'background.paper',
                        mb: 4,
                    }}
                    label="Company"
                    labelId="company"
                    id="company"
                    value={String(state.companyNumber) || ''}
                    onChange={handleCompanyChange}
                    disabled={state.companies.length <= 1 || isLoading}
                >
                    {state.companies.map((company) => (
                        <option
                            key={company.companyNumber}
                            value={company.companyNumber}
                        >
                            {company.companyName}
                        </option>
                    ))}
                </Select>
                <Button
                    title={ADD_PAYMENT_METHOD_LABEL}
                    variant="contained"
                    sx={{ float: 'right' }}
                    onClick={handleAddMethod}
                    disabled={loading}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                />
                <Paper>
                    <Typography
                        variant="h5"
                        text={'Saved Payment Methods'}
                        color={'otherText.primary'}
                        sx={{ p: 1, pt: 2 }}
                    />
                    <DataGrid
                        rows={state.paymentMethods}
                        columns={getMethodsColumns(refetch)}
                        loading={
                            isLoading ||
                            companiesLoading ||
                            isFetching ||
                            companiesFetching
                        }
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        hideFooter
                        sx={{
                            border: 0,
                            borderRadius: 0,
                            borderColor: 'divider',
                            minHeight:
                                state.paymentMethods.length > 0
                                    ? '100px'
                                    : '500px',
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: theme.palette.dark.main,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '& .disabled': {
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '& .principal-cell--cell': {
                                borderRight: '2px solid',
                                borderColor: 'divider',
                                justifyContent: 'space-between',
                            },
                            '& .principal-cell--cell .menu': {
                                visibility: 'hidden',
                            },
                            '& .principal-cell--cell:hover .menu': {
                                visibility: 'visible',
                            },
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'isDefault', sort: 'desc' },
                                ],
                            },
                        }}
                        components={{ NoRowsOverlay: customNoRowsOverlay }}
                        autoHeight
                        getRowClassName={(params) =>
                            params.row.isExpired ? 'disabled' : ''
                        }
                    />
                </Paper>
            </Container>
            <Modal open={state.isPrimaryPopupOpen} maxWidth="xs" fullWidth>
                <Box>
                    <Typography
                        variant="h6"
                        color={'otherText.primary'}
                        sx={{ fontWeight: 'bold' }}
                    >
                        {UPDATE_PRIMARY_TITLE}
                    </Typography>
                    <Typography
                        variant="body1"
                        color={'otherText.primary'}
                        sx={{ pb: 4, pt: 4 }}
                    >
                        {UPDATE_PRIMARY_TEXT}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            title="Cancel"
                            variant="outlined"
                            sx={{ maxWidth: '30%' }}
                            fullWidth
                            onClick={handleCancel}
                            disabled={loading}
                        />
                        <Button
                            title="Confirm"
                            variant="contained"
                            sx={{ maxWidth: '40%' }}
                            fullWidth
                            onClick={handlePrimaryChange}
                            disabled={loading}
                            startIcon={
                                loading && (
                                    <Spinner
                                        color="inherit"
                                        size={'30px'}
                                        sx={{ ml: '8px' }}
                                    />
                                )
                            }
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
