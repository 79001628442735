import LoginView from './views/LoginView';

function Login() {
    return (
        <>
            <LoginView />
        </>
    );
}

export default Login;
