import Radio from '../../../../components/Radio/Radio';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { PaymentMethods } from '../../../../models/App.interfaces';
import { usePayments } from '../../context/PaymentsContext';

export default function SelectedMethodCell(
    params: GridRenderCellParams<boolean, PaymentMethods>
) {
    const { row } = params;
    const [state, actions] = usePayments();

    const handleRadioSelect = () => {
        actions.onSetSelectedMethod(row);
    };

    return (
        <>
            <Radio
                id={row.id.toString()}
                checked={state.selectedMethod?.id === row.id}
                onChange={handleRadioSelect}
            />
        </>
    );
}
