import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import {
    PaymentMethods,
    TokenData,
    Wrapper,
} from '../../../models/App.interfaces';
import {
    InvoiceInformation,
    InvoiceInformationPayment,
} from '../../../models/Invoice.interfaces';
import {
    BankAccountInformation,
    BillingInformation,
    PaymentInformation,
    TransactionSettings,
} from '../../../models/Transaction.interfaces';
import { PaymentActions, PaymentFactory } from './PaymentsContext.types';
import { TokenHelper } from '../../../helpers/TokenHelper';
import { Item } from '../../../models/Props.types';
import { AmountErrorType, PaymentMethodType } from '../../../models/App.types';

function SetPaymentMethods(dispatch: Dispatch<PaymentActions>) {
    const initialData: TokenData = TokenHelper.getInitialData();
    const paymentMethods: Item[] = [
        ...(initialData.allowACH
            ? [{ value: PaymentMethodType.ACH, label: 'ACH' }]
            : []),
        ...(initialData.allowCreditCard
            ? [{ value: PaymentMethodType.CreditCard, label: 'Credit Card' }]
            : []),
    ];

    dispatch({
        type: ActionTypes.SetPaymentMethodList,
        payload: paymentMethods,
    });
}

function SetInvoices(
    payload: InvoiceInformation[],
    dispatch: Dispatch<PaymentActions>
) {
    const newInvoices = payload.map<InvoiceInformationPayment>((invoice) => ({
        invoiceNumber: invoice.invoiceNumber,
        date: invoice.date,
        pastDueDate: invoice.pastDueDate,
        originalAmount: invoice.originalAmount,
        remainingDue: invoice.remainingDue,
        serviceLocationName: invoice.serviceLocationName,
        amount: invoice.remainingDue - invoice.pendingAmount,
        pendingAmount: invoice.pendingAmount,
        errorType: AmountErrorType.NONE,
    }));
    dispatch({ type: ActionTypes.AddInvoices, payload: newInvoices });
    SetTotalAmount(newInvoices, dispatch);
}

function SetCompanyNumber(payload: number, dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.AddCompanyNumber, payload: payload });
}

function setAllSelected(
    isSelected: boolean,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetAllSelected, payload: isSelected });
}

function DeleteInvoicesSelected(dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.DeleteSelectedInvoices });
}

/**
 * @param {[number, number]} invoiceAmountInfo [invoiceNumber, amount]
 */
function updateInvoiceAmount(
    invoiceAmountInfo: [number, number],
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({
        type: ActionTypes.SetInvoiceAmount,
        payload: invoiceAmountInfo,
    });
}

function setInvoiceSelected(
    invoiceId: number,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetInvoiceSelected, payload: invoiceId });
}

function SetTotalAmount(
    payload: InvoiceInformationPayment[],
    dispatch: Dispatch<PaymentActions>
) {
    const total = payload.reduce(
        (sum, value) => (!isNaN(value.amount) ? sum + value.amount : sum),
        0
    );
    dispatch({ type: ActionTypes.SetAmount, payload: total });
}

function SetPaymentMethodType(
    payload: PaymentMethodType,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetPaymentMethodType, payload: payload });
}

function SetBillingInformation(
    payload: BillingInformation,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetBillingInformation, payload: payload });
}

function SetBankAccountInformation(
    payload: BankAccountInformation,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetBankAccountInformation, payload: payload });
}

function SetModalOpen(payload: boolean, dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.SetModalOpen, payload: payload });
}

function SetIsACHModalOpen(
    payload: boolean,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetIsACHModalOpen, payload: payload });
}

function SetTransactionSettings(
    payload: TransactionSettings,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetTransactionSettings, payload: payload });
}

function SetCancelled(payload: boolean, dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.SetCancelled, payload: payload });
}

function SetPaymentInformation(
    payload: PaymentInformation,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetPaymentInformation, payload: payload });
}

function SetNotes(payload: string, dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.SetNotes, payload: payload });
}

function setIsSaveMethodChecked(
    payload: boolean,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetIsSaveMethodChecked, payload: payload });
}

function setNickname(payload: string, dispatch: Dispatch<PaymentActions>) {
    dispatch({ type: ActionTypes.SetNickname, payload: payload });
}

function SetSavedPaymentMethods(
    payload: Wrapper<PaymentMethods>,
    dispatch: Dispatch<PaymentActions>
) {
    const paymentMethods: PaymentMethods[] = payload?.data ? payload?.data : [];
    dispatch({
        type: ActionTypes.ListSavedPaymentMethods,
        payload: paymentMethods,
    });
}

function SetSelectedMethod(
    payload: PaymentMethods,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetSelectedMethod, payload: payload });
}

function UpdateAddingMethod(
    payload: boolean,
    dispatch: Dispatch<PaymentActions>
) {
    dispatch({ type: ActionTypes.SetAddingMethod, payload: payload });
}

export default function ActionFactory(
    dispatch: Dispatch<PaymentActions>
): PaymentFactory {
    return {
        onSetInvoices: (payload: InvoiceInformation[]) =>
            SetInvoices(payload, dispatch),
        onSetCompanyNumber: (payload: number) =>
            SetCompanyNumber(payload, dispatch),
        onDeleteInvoicesSelected: () => DeleteInvoicesSelected(dispatch),
        onSetTotalAmount: (payload: InvoiceInformationPayment[]) =>
            SetTotalAmount(payload, dispatch),
        onSetPaymentMethodType: (payload: PaymentMethodType) =>
            SetPaymentMethodType(payload, dispatch),
        onSetBillingInformation: (payload: BillingInformation) =>
            SetBillingInformation(payload, dispatch),
        onSetModalOpen: (payload: boolean) => SetModalOpen(payload, dispatch),
        onSetTransactionSettings: (payload: TransactionSettings) =>
            SetTransactionSettings(payload, dispatch),
        onSetCancelled: (payload: boolean) => SetCancelled(payload, dispatch),
        onSetPaymentInformation: (payload: PaymentInformation) =>
            SetPaymentInformation(payload, dispatch),
        onSetPaymentMethods: () => SetPaymentMethods(dispatch),
        onSetBankAccountInformation: (payload: BankAccountInformation) =>
            SetBankAccountInformation(payload, dispatch),
        onSetIsACHModalOpen: (payload: boolean) =>
            SetIsACHModalOpen(payload, dispatch),
        onSetNotes: (payload: string) => SetNotes(payload, dispatch),
        onSetAllSelected: (payload: boolean) =>
            setAllSelected(payload, dispatch),
        onSetInvoiceSelected: (payload: number) =>
            setInvoiceSelected(payload, dispatch),
        onUpdateInvoiceAmount: (payload: [number, number]) =>
            updateInvoiceAmount(payload, dispatch),
        onSetIsSaveMethodChecked: (payload: boolean) =>
            setIsSaveMethodChecked(payload, dispatch),
        onSetNickname: (payload: string) => setNickname(payload, dispatch),
        onSetSavedPaymentMethods: (payload: Wrapper<PaymentMethods>) =>
            SetSavedPaymentMethods(payload, dispatch),
        onSetSelectedMethod: (payload: PaymentMethods) =>
            SetSelectedMethod(payload, dispatch),
        onUpdateAddingMethod: (payload: boolean) =>
            UpdateAddingMethod(payload, dispatch),
    };
}
