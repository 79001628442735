import { Toolbar } from '@mui/material';
import Typography from '../Typography/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../Button/Button';
import { HeaderGridProps } from '../../models/Props.types';

export default function TableToolbar(props: HeaderGridProps) {
    const { isSelectedHeader, headerText, onClick, disabled } = props;

    return (
        <Toolbar
            disableGutters
            variant={isSelectedHeader ? 'selected' : 'dense'}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant={isSelectedHeader ? 'subtitle1' : 'h6'}
                text={headerText}
                color={'otherText.primary'}
            />
            {isSelectedHeader && (
                <Button
                    title={'REMOVE'}
                    startIcon={<DeleteIcon />}
                    onClick={onClick}
                    disabled={disabled}
                />
            )}
        </Toolbar>
    );
}
