import { PAYMENTS_API } from '../config';
import {
    Customer,
    Wrapper,
    CustomerValidation,
    PaymentMethods,
    PatchPaymentMethodBody,
    Company,
    PagedResults,
    PaymentHistory,
    PaymentHistoryParams,
    PaymentMethodsParams,
} from '../models/App.interfaces';
import { useGet } from '../api/useGet';
import { usePost } from '../api/usePost';
import { AxiosError } from 'axios';
import { useDelete } from '../api/useDelete';
import { updateType, usePatch } from '../api/usePatch';
import { objectToQueryString } from '../helpers/Utilities';
import {
    ACHPaymentMethodPost,
    BillingInformation,
    CreditCardMethodPut,
    CreditCardPaymentMethodPost,
    CreditCardPaymentMethodReplace,
    TransactionSettings,
} from '../models/Transaction.interfaces';
import { usePut } from '../api/usePut';

const base = 'users';

function useGetCustomers(
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (data: Wrapper<Customer>) => Promise<void> | void
) {
    return useGet<Wrapper<Customer>>(PAYMENTS_API + `${base}/customers`, true, {
        onErrorCallback: onErrorCallback,
        onSuccess: onSuccessCallback,
        staleTime: 0,
    });
}

function usePostCustomer(onError: (error: AxiosError) => Promise<void> | void) {
    return usePost<CustomerValidation, Wrapper<Customer>>(
        PAYMENTS_API + `${base}/customers`,
        {
            onErrorCallback: onError,
            staleTime: 0,
        }
    );
}

function useGetPaymentMethods(
    params: PaymentMethodsParams,
    enable: boolean,
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (data: Wrapper<PaymentMethods>) => Promise<void> | void
) {
    const queryParams = objectToQueryString(params);
    return useGet<Wrapper<PaymentMethods>>(
        PAYMENTS_API + `${base}/payment-methods?${queryParams}`,
        enable,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
            staleTime: 0,
        }
    );
}

function useDeleteCustomer(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return useDelete<string>(PAYMENTS_API + `${base}/customers`, {
        onErrorCallback: onErrorCallback,
    });
}

function useDeletePaymentMethod(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return useDelete<string>(PAYMENTS_API + `${base}/payment-methods`, {
        onErrorCallback: onErrorCallback,
    });
}

function useUpdatePyamentMethod(
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (
        data: updateType<PatchPaymentMethodBody[]>
    ) => Promise<void> | void
) {
    return usePatch<PatchPaymentMethodBody[]>(
        PAYMENTS_API + `${base}/payment-methods`,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
        }
    );
}

function useGetCompaniesbyUser(
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (data: Wrapper<Company>) => Promise<void> | void
) {
    return useGet<Wrapper<Company>>(PAYMENTS_API + `${base}/companies`, true, {
        onErrorCallback: onErrorCallback,
        onSuccess: onSuccessCallback,
        staleTime: 0,
    });
}

function useGetPaymentHistory(
    companyNumber: number,
    params: PaymentHistoryParams,
    enable: boolean,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (
        data: Wrapper<PagedResults<PaymentHistory>>
    ) => Promise<void> | void
) {
    const queryParams = objectToQueryString(params);
    return useGet<Wrapper<PagedResults<PaymentHistory>>>(
        PAYMENTS_API +
            `${base}/payments?companyNumber=${companyNumber}&${queryParams}`,
        enable,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
            staleTime: 0,
        }
    );
}

function usePostAchPaymentMethod(
    onError: (error: AxiosError) => Promise<void> | void
) {
    return usePost<ACHPaymentMethodPost, PaymentMethods>(
        PAYMENTS_API + `${base}/transfer-accounts/payment-methods`,
        {
            onErrorCallback: onError,
            staleTime: 0,
        }
    );
}

function usePostCreditCardPaymentMethod(
    onError: (error: AxiosError) => Promise<void> | void
) {
    return usePost<CreditCardPaymentMethodPost, TransactionSettings>(
        PAYMENTS_API + `${base}/credit-cards/payment-methods`,
        {
            onErrorCallback: onError,
            staleTime: 0,
        }
    );
}

function usePutCreditCardPaymentMethod(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return usePut<CreditCardMethodPut>(
        PAYMENTS_API + `${base}/credit-cards/payment-methods`,
        {
            onErrorCallback: onErrorCallback,
            config: { retry: false },
        }
    );
}

function useDeleteCreditCardPaymentMethod(
    teamCorrelationId: string,
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return useDelete<string>(
        PAYMENTS_API + `${base}/credit-cards/payment-methods`,
        {
            params: { correlationId: teamCorrelationId },
            onErrorCallback: onErrorCallback,
            config: { retry: false },
        }
    );
}

function useGetBillingInformation(
    paymentMethodId: string,
    onSuccess?: (data: Wrapper<BillingInformation>) => Promise<void> | void,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void
) {
    return useGet<Wrapper<BillingInformation>>(
        PAYMENTS_API +
            `${base}/payment-methods/${paymentMethodId}/billing-information`,
        false,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccess,
            staleTime: 0,
        }
    );
}

function useReplaceCreditCardPaymentMethod(
    paymentMethodId: string,
    onError: (error: AxiosError) => Promise<void> | void
) {
    return usePut<CreditCardPaymentMethodReplace, TransactionSettings>(
        PAYMENTS_API +
            `${base}/credit-cards/payment-methods/${paymentMethodId}/replace`,
        {
            onErrorCallback: onError,
            staleTime: 0,
        }
    );
}

const UsersActions = {
    useGetCustomers: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (data: Wrapper<Customer>) => Promise<void> | void
    ) => useGetCustomers(onErrorCallback, onSuccessCallback),
    usePostCustomer: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => usePostCustomer(onErrorCallback),
    useGetPaymentMethods: (
        params: PaymentMethodsParams,
        enable: boolean,
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (
            data: Wrapper<PaymentMethods>
        ) => Promise<void> | void
    ) =>
        useGetPaymentMethods(
            params,
            enable,
            onErrorCallback,
            onSuccessCallback
        ),
    useDeleteCustomer: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useDeleteCustomer(onErrorCallback),
    useDeletePaymentMethod: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useDeletePaymentMethod(onErrorCallback),
    useUpdatePaymentMethod: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (
            data: updateType<PatchPaymentMethodBody[]>
        ) => Promise<void> | void
    ) => useUpdatePyamentMethod(onErrorCallback, onSuccessCallback),
    useGetCompaniesbyUser: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (data: Wrapper<Company>) => Promise<void> | void
    ) => useGetCompaniesbyUser(onErrorCallback, onSuccessCallback),
    useGetPaymentHistory: (
        companyNumber: number,
        params: PaymentHistoryParams,
        enable: boolean,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (
            data: Wrapper<PagedResults<PaymentHistory>>
        ) => Promise<void> | void
    ) =>
        useGetPaymentHistory(
            companyNumber,
            params,
            enable,
            onErrorCallback,
            onSuccessCallback
        ),
    usePostAchPaymentMethod: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => usePostAchPaymentMethod(onErrorCallback),
    usePostCreditCardPaymentMethod: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => usePostCreditCardPaymentMethod(onErrorCallback),
    usePutCreditCardPaymentMethod: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => usePutCreditCardPaymentMethod(onErrorCallback),
    useDeleteCreditCardPaymentMethod: (
        teamCorrelationId: string,
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useDeleteCreditCardPaymentMethod(teamCorrelationId, onErrorCallback),
    useGetBillingInformation: (
        paymentMethodId: string,
        onSuccess?: (data: Wrapper<BillingInformation>) => Promise<void> | void,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void
    ) => useGetBillingInformation(paymentMethodId, onSuccess, onErrorCallback),
    useReplaceCreditCardPaymentMethod: (
        paymentMethodId: string,
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useReplaceCreditCardPaymentMethod(paymentMethodId, onErrorCallback),
};

export default UsersActions;
