import { Children } from 'react';
import { Grid } from '@mui/material';
import { LayoutProps } from './Layout.types';

export default function Layout(props: LayoutProps) {
    const { alignItems, direction, topPadding, spacing, sx } = props;
    const children = Children.toArray(props.children);

    return (
        <Grid
            container
            pt={topPadding}
            spacing={spacing}
            alignItems={alignItems}
            direction={direction}
            sx={sx}
        >
            {children.map((child, i) => (
                <Grid item key={i}>
                    {child}
                </Grid>
            ))}
        </Grid>
    );
}
