import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import IconButton from '../Button/IconButton';
import { GridMenuProps } from './Menu.types';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function GridMenu(props: GridMenuProps) {
    const { id, values } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                className={open ? '' : 'menu'}
                sx={{
                    visibility: open ? 'visible' : 'hidden',
                }}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <MenuMui
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {values.map((value) => (
                    <MenuItem
                        key={value.label}
                        onClick={() => {
                            handleClose();
                            value.action(id);
                        }}
                    >
                        {value.label}
                    </MenuItem>
                ))}
            </MenuMui>
        </>
    );
}
