import { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button/Button';
import Box from '../../../../components/box/Box';
import InputField from '../../../../components/InputField/InputField';
import Typography from '../../../../components/Typography/Typography';
import { useCustomers } from '../../context/CustomersContext';
import { useApplication } from '../../../../context/ApplicationContext';
import { formLoginValidator } from '../../../../helpers/Validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { errorActions } from '../../../../helpers/ErrorActions';
import { AxiosError } from 'axios';
import ArrowBack from '@mui/icons-material/ArrowBack';
import UsersActions from '../../../../hooks/UsersActions';
import { CustomerValidation } from '../../../../models/App.interfaces';
import { AddCustomerModalProps } from './AddCustomerModal.types';
import { GO_BACK_TITLE } from '../../../../helpers/Constants';
import Spinner from '../../../../components/Spinner/Spinner';

export default function AddCustomerModal(props: AddCustomerModalProps) {
    const { customerNumber, setCustomerNumber } = props;
    const [mainError, setMainError] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState<number>(0);
    const [applicationState, actions] = useApplication();
    const { isPopupOpen, Company } = applicationState;
    const [state, customersActions] = useCustomers();

    const methods = useForm({
        resolver: yupResolver(formLoginValidator),
    });

    const handleValidationError = (error: AxiosError) => {
        setMainError(
            errorActions.customerValiationError(
                error,
                Company.contactInformation
            )
        );
    };

    const { mutateAsync: postCustomer, isLoading: loading } =
        UsersActions.usePostCustomer(handleValidationError);

    const {
        handleSubmit,
        resetField,
        formState: { errors },
    } = methods;

    const handleInvoiceChange = (event: any) => {
        setInvoiceNumber(event.target.value);
    };

    const handleCustomerChange = (event: any) => {
        setCustomerNumber(event.target.value);
    };

    const handleChange = () => {
        if (mainError !== '') {
            setMainError('');
        }
    };

    const handleCancel = () => {
        setCustomerNumber('');
        setInvoiceNumber(0);
        customersActions.onUpdateIsCustomerPopupOpen(false);
        setMainError('');
        resetField('customerNumber');
        resetField('invoiceNumber');
    };

    const onSubmit = async () => {
        const CustomerVaidation: CustomerValidation = {
            customerNumber: customerNumber,
            invoiceNumber: invoiceNumber,
        };

        const response = await postCustomer(CustomerVaidation);
        customersActions.onSetCustomers(response.data);
        customersActions.onUpdateIsCustomerPopupOpen(false);
        customersActions.onUpdateIsCustomerSuccessOpen(true);
        setInvoiceNumber(0);
        resetField('customerNumber');
        resetField('invoiceNumber');
    };

    return (
        <>
            <Modal open={state.isCustomerPopupOpen} maxWidth="xs" fullWidth>
                <Box>
                    <Typography variant="h6" color="otherText.primary">
                        <b>Add Customer</b>
                    </Typography>
                    <FormProvider {...methods}>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            onChange={handleChange}
                        >
                            <Typography
                                variant="body1"
                                text={mainError}
                                sx={{ mt: 2, pb: 2, textAlign: 'center' }}
                                color={'error'}
                            />
                            <InputField
                                variant="subtitle1"
                                labelSx={{ fontWeight: 'bold' }}
                                label="Invoice Number"
                                required
                                id="invoice"
                                fullWidth
                                error={!!errors.invoiceNumber}
                                helperText={
                                    errors.invoiceNumber &&
                                    errors?.invoiceNumber?.message?.toString()
                                }
                                registerText="invoiceNumber"
                                handleChange={handleInvoiceChange}
                                color={'text.primary'}
                                disabled={isPopupOpen}
                            />
                            <InputField
                                variant="subtitle1"
                                labelSx={{ fontWeight: 'bold' }}
                                label="Customer Number"
                                required
                                id="customer"
                                fullWidth
                                error={!!errors.customerNumber}
                                helperText={
                                    errors.customerNumber &&
                                    errors?.customerNumber?.message?.toString()
                                }
                                textFieldSx={{
                                    mt: 4,
                                    mb: 4,
                                }}
                                registerText="customerNumber"
                                handleChange={handleCustomerChange}
                                color={'text.primary'}
                                disabled={isPopupOpen}
                            />
                            <Box sx={{ display: 'flex', gap: '17%' }}>
                                <Button
                                    title={GO_BACK_TITLE}
                                    variant="outlined"
                                    sx={{ maxWidth: '33%' }}
                                    startIcon={<ArrowBack />}
                                    fullWidth
                                    onClick={handleCancel}
                                />
                                <Button
                                    type="submit"
                                    title="Add Customer"
                                    variant="contained"
                                    sx={{ maxWidth: '50%' }}
                                    startIcon={
                                        loading && (
                                            <Spinner
                                                color="inherit"
                                                size={'30px'}
                                                sx={{ ml: '8px' }}
                                            />
                                        )
                                    }
                                    fullWidth
                                />
                            </Box>
                        </form>
                    </FormProvider>
                </Box>
            </Modal>
        </>
    );
}
