import { ActionTypes } from './ActionTypes';
import { CustomerActions, CustomerData } from './CustomersContext.types';

export const State: CustomerData = {
    customers: [],
    isCustomerPopupOpen: false,
    isCustomerSuccessOpen: false,
};

export function Reducer(
    draft: CustomerData,
    action: CustomerActions
): CustomerData {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.ListCustomers:
            draft.customers = payload;
            break;
        case ActionTypes.UpdateIsCustomerPopupOpen:
            draft.isCustomerPopupOpen = payload;
            break;
        case ActionTypes.UpdateIsCustomerSuccessOpen:
            draft.isCustomerSuccessOpen = payload;
            break;
        case ActionTypes.DeleteCustomer:
            draft.customers = draft.customers.filter(
                (customer) => customer.customerNumber !== payload
            );
            break;
    }

    return draft;
}
