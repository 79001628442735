import { ActionTypes } from './ActionTypes';
import { HistoryActions, HistoryData } from './HistoryContext.types';

export const State: HistoryData = {
    companies: [],
    companyNumber: 0,
    paymentHistory: [],
    historyParams: {
        includeReturns: false,
        startDate: '',
        endDate: '',
        pageSize: 10,
        pageNumber: 1,
        orderBy: 'paymentDate',
        ascending: false,
    },
    historyPagination: {
        totalPages: 0,
        rowCount: 0,
    },
    dateValue: '90',
    confirmationPopoverOpen: false,
    confirmationNumber: '',
    invoicesPopoverOpen: false,
    invoicesList: [],
};

export function Reducer(
    draft: HistoryData,
    action: HistoryActions
): HistoryData {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.ListCompanies:
            draft.companies = payload;
            break;
        case ActionTypes.SetCompanyNumber:
            draft.companyNumber = payload;
            break;
        case ActionTypes.ListHistory:
            draft.paymentHistory = payload;
            break;
        case ActionTypes.UpdateHistoryParameters:
            draft.historyParams = payload;
            break;
        case ActionTypes.SetPaymentHistoryPagination:
            draft.historyPagination = payload;
            break;
        case ActionTypes.SetDateValue:
            draft.dateValue = payload;
            break;
        case ActionTypes.SetConfirmationPopoverOpen:
            draft.confirmationPopoverOpen = payload;
            break;
        case ActionTypes.SetConfirmationNumber:
            draft.confirmationNumber = payload;
            break;
        case ActionTypes.SetInvoicesPopoverOpen:
            draft.invoicesPopoverOpen = payload;
            break;
        case ActionTypes.SetInvoicesList:
            draft.invoicesList = payload;
            break;
    }

    return draft;
}
