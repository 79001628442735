import { Grid as MUIGrid } from '@mui/material';
import { GridItemProps } from './Grid.types';

export default function GridItem(props: GridItemProps) {
    const { xs, md, children } = props;
    return (
        <MUIGrid item xs={xs} md={md}>
            {children}
        </MUIGrid>
    );
}
