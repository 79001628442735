import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputField from '../../../components/InputField/InputField';
import Typography from '../../../components/Typography/Typography';
import Spinner from '../../../components/Spinner/Spinner';
import PaymentActions from '../../../hooks/PaymentsActions';
import { useApplication } from '../../../context/ApplicationContext';
import { formLoginValidator } from '../../../helpers/Validators';
import {
    StyledBox,
    StyledImage,
    StyledError,
    StyledButton,
    StyledFooterBox,
    StyledMain,
    StyledMiddle,
    StyledSpinner,
} from './styling';
import { errorActions } from '../../../helpers/ErrorActions';
import { AxiosError } from 'axios';
import { FOOTER } from '../../../helpers/Constants';
import {
    CreateAuthInformation,
    FormatFooter,
    FormatString,
} from '../../../helpers/Utilities';
import { useSnackbar } from 'notistack';
import Analytics from '../../../Analytics';
import {
    AuthInformation,
    GeneralInformation,
    TokenData,
    Wrapper,
} from '../../../models/App.interfaces';
import { TokenHelper } from '../../../helpers/TokenHelper';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { B2C_CONSTANTS } from '../../../config/B2CConfig';
import AuthActions from '../../../hooks/AuthActions';
import CustomersActions from '../../../hooks/CustomersActions';
import Metadata from '../../../components/metadata/Metadata';
import MetadataConstants from '../../../constants/MetadataConstants';
import LogoFooter from '../../../components/Footer/LogoFooter/LogoFooter';

function LoginView() {
    const [mainError, setMainError] = useState('');
    const [isSystemDown, setIsSystemDown] = useState<boolean>(false);
    const [customerNumber, setCustomerNumber] = useState<string>('');
    const [invoiceNumber, setInvoiceNumber] = useState<number>(0);
    const [applicationState, actions] = useApplication();
    const { isPopupOpen, Company } = applicationState;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { instance } = useMsal();
    const accounts: AccountInfo[] = instance.getAllAccounts();

    const handleInformationSuccess = (
        generalInformation: Wrapper<GeneralInformation>
    ) => {
        actions.onAddCompanyData(generalInformation.data[0]);
        Analytics.init(generalInformation.data[0].name);
    };

    // error function for get information
    const handleInformationError = (error: AxiosError) => {
        if (!error.response?.status) {
            enqueueSnackbar('The system is temporarily unavailable.', {
                variant: 'error',
            });
            setIsSystemDown(true);
        } else {
            errorActions.informationError(error, navigate);
        }

        const companyData: GeneralInformation = {
            logo: '',
            contactInformation: '',
            name: '',
        };
        actions.onAddCompanyData(companyData);
        Analytics.init('unknown');
    };

    // Get annonymous information
    PaymentActions.useGetInformation(
        handleInformationSuccess,
        handleInformationError
    );

    // Post sing in
    const { mutateAsync: postSignIn, isLoading: signInLoading } =
        AuthActions.useSignIn((error) =>
            errorActions.signInError(navigate, enqueueSnackbar, instance)
        );

    //error function for Token API
    const handleTokenError = (error: AxiosError) => {
        setMainError(
            errorActions.tokenError(error, Company.contactInformation)
        );
    };

    const handleGetTokenSuccess = (dataToken: Wrapper<TokenData>) => {
        TokenHelper.saveInitialData(dataToken.data[0]);
        TokenHelper.saveEncodedTenantInformation(
            TokenHelper.getInitialData().encodedTenantInformation
        );
        navigate('/invoices');
    };

    // refetch to call Get token
    const { isLoading: tokenLoading, refetch: refetchToken } =
        CustomersActions.useFetchToken(
            customerNumber,
            invoiceNumber,
            handleTokenError,
            handleGetTokenSuccess
        );

    //initializing react-hook-form and setting yup as validation resolver
    const methods = useForm({
        resolver: yupResolver(formLoginValidator),
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    //calling Outstanding Invoices API to validate invoiceNumber
    const onSubmit = async () => {
        if (accounts.length > 0) {
            TokenHelper.logout(instance, navigate, true);
            actions.onUpdateIsUserSignedIn();
        }
        refetchToken();
    };

    //reset MainError error when input is changed
    const handleChange = () => {
        if (mainError !== '') {
            setMainError('');
        }
    };

    //set invoiceNumber and customerNumber
    const handleInvoiceChange = (event: any) => {
        setInvoiceNumber(event.target.value);
    };

    const handleCustomerChange = (event: any) => {
        setCustomerNumber(event.target.value);
    };

    const onLogin = async () => {
        try {
            actions.onUpdateIsPopupOpen(true);
            const loginRequest = {
                scopes: B2C_CONSTANTS.scopes,
                authority: B2C_CONSTANTS.authoritySignIn,
            };
            const authenticationResult: AuthenticationResult =
                await instance.loginPopup(loginRequest);
            const request: AuthInformation = CreateAuthInformation();
            const { data } = await postSignIn(request);
            actions.onUpdateOriginalAccount(authenticationResult?.account);
            actions.onAddUserData(data);
            actions.onUpdateIsUserSignedIn();
            TokenHelper.saveInitialDataFromTenant(
                data.encodedTenantInformation
            );
            navigate('/customers');
        } finally {
            actions.onUpdateIsPopupOpen(false);
        }
    };

    const tabTitle = FormatString(
        MetadataConstants.LOGIN_TAB_TITLE,
        Company.name
    );

    const metaDescription = FormatString(
        MetadataConstants.LOGIN_DESCRIPTION,
        Company.name
    );

    return (
        <>
            <Metadata tabTitle={tabTitle} description={metaDescription} />
            <StyledMain>
                <StyledMiddle>
                    <StyledBox>
                        {Company.logo && (
                            <StyledImage
                                src={Company.logo}
                                alt="Company Logo"
                                className="App-logo"
                            />
                        )}
                        <Typography
                            variant="h6"
                            text={Company.name}
                            color={'text.primary'}
                        />
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate
                                onChange={handleChange}
                            >
                                <StyledError
                                    variant="body1"
                                    text={!tokenLoading ? mainError : ''}
                                    sx={{ mt: 2, pb: 2, textAlign: 'center' }}
                                    color={'error'}
                                />
                                <InputField
                                    variant="subtitle1"
                                    labelSx={{ fontWeight: 'bold' }}
                                    text="Invoice Number"
                                    required
                                    id="invoice"
                                    fullWidth
                                    startAdornment="#"
                                    error={!!errors.invoiceNumber}
                                    helperText={
                                        errors.invoiceNumber &&
                                        errors?.invoiceNumber?.message?.toString()
                                    }
                                    textFieldSx={{ maxWidth: '340px' }}
                                    registerText="invoiceNumber"
                                    handleChange={handleInvoiceChange}
                                    color={'text.primary'}
                                    disabled={
                                        tokenLoading ||
                                        isSystemDown ||
                                        isPopupOpen
                                    }
                                />
                                <InputField
                                    variant="subtitle1"
                                    labelSx={{
                                        mt: 3,
                                        fontWeight: 'bold',
                                    }}
                                    text="Customer Number"
                                    required
                                    id="customer"
                                    fullWidth
                                    startAdornment="#"
                                    error={!!errors.customerNumber}
                                    helperText={
                                        errors.customerNumber &&
                                        errors?.customerNumber.message?.toString()
                                    }
                                    textFieldSx={{ maxWidth: '340px' }}
                                    registerText="customerNumber"
                                    handleChange={handleCustomerChange}
                                    color={'text.primary'}
                                    disabled={
                                        tokenLoading ||
                                        isSystemDown ||
                                        isPopupOpen
                                    }
                                />
                                <StyledButton
                                    type="submit"
                                    variant="contained"
                                    disabled={
                                        tokenLoading ||
                                        isSystemDown ||
                                        isPopupOpen
                                    }
                                    sx={{
                                        mt: 5,
                                        maxWidth: '340px',
                                    }}
                                    title={
                                        !tokenLoading
                                            ? 'Make a one-time payment'
                                            : ''
                                    }
                                    fullWidth
                                    startIcon={
                                        tokenLoading && (
                                            <StyledSpinner>
                                                <Spinner
                                                    color="primary"
                                                    size={'30px'}
                                                    sx={{ ml: '8px' }}
                                                />
                                            </StyledSpinner>
                                        )
                                    }
                                />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        maxWidth: '340px',
                                        mt: '17px',
                                        mb: '17px',
                                    }}
                                    align="center"
                                >
                                    Or
                                </Typography>
                                <StyledButton
                                    type="button"
                                    variant="contained"
                                    onClick={() => onLogin()}
                                    disabled={
                                        tokenLoading ||
                                        isSystemDown ||
                                        isPopupOpen
                                    }
                                    sx={{
                                        maxWidth: '340px',
                                    }}
                                    title={!signInLoading ? 'Sign In' : ''}
                                    fullWidth
                                    startIcon={
                                        signInLoading && (
                                            <StyledSpinner>
                                                <Spinner
                                                    color="primary"
                                                    size={'30px'}
                                                    sx={{ ml: '8px' }}
                                                />
                                            </StyledSpinner>
                                        )
                                    }
                                />
                            </form>
                        </FormProvider>
                    </StyledBox>
                    <br />
                    <StyledFooterBox>
                        <Typography
                            variant="body1"
                            text={FormatFooter(
                                FormatString(FOOTER, Company.contactInformation)
                            )}
                            color={'text.primary'}
                        />
                    </StyledFooterBox>
                    <LogoFooter />
                </StyledMiddle>
            </StyledMain>
        </>
    );
}
export default LoginView;
