import { Helmet } from 'react-helmet-async';
import { MetadataTypes } from './Metadata.types';

export default function Metadata(props: MetadataTypes) {
    const { tabTitle, description, noIndex, robotsOnly } = props;
    return (
        <>
            {robotsOnly ? (
                <meta name="robots" content="noindex, nofollow" />
            ) : (
                <Helmet>
                    <title>Pay Online | {tabTitle}</title>
                    <meta name="description" content={description} />
                    {noIndex ? (
                        <meta
                            name="robots"
                            content="noindex, nofollow"
                            data-react-helmet="true"
                        />
                    ) : (
                        <meta name="robots" content="nofollow" />
                    )}
                </Helmet>
            )}
        </>
    );
}
