import Close from '@mui/icons-material/Close';
import {
    StyledFrame,
    StyledModal,
    StyledSpinner,
    StyledExit,
} from './CreditCardMethodModal.style';
import { CreditCardMethodModalProps } from './CreditCardMethodModal.types';
import { useNavigate } from 'react-router-dom';
import { EventStatus } from '../../models/App.types';
import Modal from '../modal/Modal';
import Typography from '../Typography/Typography';
import ScreenConstants from '../../constants/ScreenConstants';
import IconButton from '../Button/IconButton';
import ModalText from '../modal/ModalText';
import Spinner from '../Spinner/Spinner';
import Box from '../box/Box';
import Button from '../Button/Button';
import ButtonConstants from '../../constants/ButtonConstants';

export default function CreditCardMethodModal(
    props: CreditCardMethodModalProps
) {
    const {
        isCreditCardModalOpen,
        creditCardTransactionSettings,
        isCreditCardLoading,
        hasEventSourceFailed,
        streamEventStatus,
        onCreditCardModalReset,
        onSuccessClick,
    } = props;

    const navigate = useNavigate();

    const isTransactionCanceled: boolean =
        streamEventStatus === EventStatus.Deleted;
    const isTransactionApproved: boolean =
        streamEventStatus === EventStatus.Approved;

    const { hostedTransactionUrl } = creditCardTransactionSettings;

    return (
        <Modal
            open={isCreditCardModalOpen}
            sx={{ mt: 4, mb: 4 }}
            maxWidth="md"
            fullWidth
            title={
                !isCreditCardLoading &&
                !hasEventSourceFailed &&
                !isTransactionCanceled &&
                !isTransactionApproved && (
                    <Typography
                        variant="h6"
                        color={'otherText.primary'}
                        sx={{ fontWeight: 'bold' }}
                    >
                        {ScreenConstants.METHOD_CC_MODAL_TITLE}
                    </Typography>
                )
            }
        >
            <StyledModal>
                {hasEventSourceFailed && (
                    <StyledExit>
                        <IconButton
                            color={'primary'}
                            onClick={onCreditCardModalReset}
                        >
                            <Close fontSize={'large'} />
                        </IconButton>
                    </StyledExit>
                )}
                {!isCreditCardLoading ? (
                    <>
                        <StyledFrame
                            src={hostedTransactionUrl}
                            title={ScreenConstants.IFRAME}
                        />
                        {!hasEventSourceFailed &&
                            !isTransactionCanceled &&
                            !isTransactionApproved && (
                                <ModalText sx={{ mt: '18px' }}>
                                    {
                                        ScreenConstants.PAYMENT_WILL_BE_STORED_WARNING
                                    }
                                </ModalText>
                            )}
                    </>
                ) : (
                    <StyledSpinner>
                        <Spinner color={'primary'} size={'50px'} />
                    </StyledSpinner>
                )}
                <br />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: isTransactionCanceled
                            ? 'flex-start'
                            : 'flex-end',
                    }}
                >
                    {isTransactionCanceled && (
                        <Button
                            variant="outlined"
                            title={ButtonConstants.CLOSE}
                            onClick={onCreditCardModalReset}
                            sx={{ mt: 3 }}
                        />
                    )}
                    {isTransactionApproved && (
                        <Button
                            variant="contained"
                            title={ButtonConstants.RETURN_METHODS_PAGE}
                            onClick={onSuccessClick}
                            sx={{ mt: 3 }}
                        />
                    )}
                </Box>
            </StyledModal>
        </Modal>
    );
}
