export default {
    CLOSE: 'Close',
    RETURN_METHODS_PAGE: 'Return to Payment Methods',
    BACK_METHOD: 'Back to payment method',
    RETURN_INVOICES_PAGE: 'Return to invoices',
    VIEW_SUMMARY: 'View payment summary',
    PROCESS_PAYMENT: 'Process payment',
    ADD_PROCESS_PAYMENT: 'Add & Process payment',
    CANCEL: 'Cancel',
    REMOVE: 'Remove',
    DELETE_REMOVE: 'Delete/Remove',
    UPDATE_CREDIT_CARD: 'Update Credit Card Details',
} as const;
