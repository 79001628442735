import { DOLLAR_FORMAT } from '../../../helpers/Constants';
import CustomerActionCell from '../components/CustomerActionCell/CustomerActionCell';

export const CUSTOMER_COLUMNS = [
    {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: 1,
        cellClassName: 'principal-cell--cell',
        sortable: false,
        renderCell: CustomerActionCell,
    },
    {
        field: 'customerNumber',
        headerName: 'Number',
        flex: 0.45,
        sortable: false,
    },
    {
        field: 'invoiceCount',
        headerName: 'Open Invoices',
        flex: 0.55,
        sortable: false,
    },
    {
        field: 'remainingDueBalance',
        headerName: 'Total Due',
        flex: 0.75,
        sortable: false,
        valueFormatter: (params: any) => {
            const formattedValue =
                '$' + params.value.toFixed(2).replace(DOLLAR_FORMAT, ',');
            return `${formattedValue}`;
        },
    },
    {
        field: 'pendingPayments',
        headerName: 'Pending Payment',
        flex: 0.75,
        sortable: false,
        valueFormatter: (params: any) =>
            params.value > 0
                ? `$${params.value.toFixed(2).replace(DOLLAR_FORMAT, ',')}`
                : '-',
    },
    {
        field: 'pastDueBalance',
        headerName: 'Past Due',
        flex: 0.75,
        sortable: false,
        valueFormatter: (params: any) =>
            params.value > 0
                ? `$${params.value.toFixed(2).replace(DOLLAR_FORMAT, ',')}`
                : '-',
    },
];
