import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '../../../components/box/Box';
import Button from '../../../components/Button/Button';
import Container from '../../../components/container/Container';
import Divider from '../../../components/divider/Divider';
import Footer from '../../../components/Footer/TextFooter/Footer';
import Metadata from '../../../components/metadata/Metadata';
import Paper from '../../../components/paper/Paper';
import Spinner from '../../../components/Spinner/Spinner';
import { B2C_CONSTANTS } from '../../../config/B2CConfig';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import MetadataConstants from '../../../constants/MetadataConstants';
import { useApplication } from '../../../context/ApplicationContext';
import {
    BACK_INVOICES_BUTTON_TEXT,
    PAY_BUTTON_TEXT,
} from '../../../helpers/Constants';
import { errorActions } from '../../../helpers/ErrorActions';
import { TokenHelper } from '../../../helpers/TokenHelper';
import {
    CreateAuthInformation,
    FormatString,
} from '../../../helpers/Utilities';
import AuthActions from '../../../hooks/AuthActions';
import CustomersActions from '../../../hooks/CustomersActions';
import useFullPageLoader from '../../../hooks/useFullPageLoader';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import UsersActions from '../../../hooks/UsersActions';
import {
    AuthInformation,
    PaymentMethods,
    PaymentMethodsParams,
    TokenData,
    Wrapper,
} from '../../../models/App.interfaces';
import { PaymentMethodType } from '../../../models/App.types';
import { FormRef } from '../components/formSection/FormSection.types';
import ModalACH from '../components/modalACH/ModalACH';
import ModalPayment from '../components/modalPayment/ModalPayment';
import { usePayments } from '../context/PaymentsContext';
import MethodFormsView from './methodFormsView/MethodFormsView';
import MethodTableView from './methodTableView/MethodTableView';
import PayInvoicesView from './PayInvoicesView/PayInvoicesView';

export default function PaymentsView() {
    const { instance } = useMsal();
    const [applicationState, applicationActions] = useApplication();
    const { Company, isPopupOpen, isUserSignedIn } = applicationState;
    const [state, actions] = usePayments();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const formRef = useRef<FormRef>(null);
    const accounts: AccountInfo[] = instance.getAllAccounts();
    const { fullPageLoader, showLoader, hideLoader } = useFullPageLoader();

    const postSignIn = AuthActions.useInternalSignIn((error) =>
        errorActions.signInError(navigate, enqueueSnackbar, instance)
    );

    //scroll to top when Modal is closed
    useEffect(() => {
        if (state.ModalOpen === false) {
            window.scroll(0, 0);
        }
    }, [state.ModalOpen]);

    const {
        Invoices,
        totalSelected,
        TotalAmount,
        paymentMethodType,
        isErrorAmount,
        BillingInformation,
        CompanyNumber,
        paymentMethods,
        bankAccountInformation,
        notes,
        allowPending,
        isSavePaymentMethodChecked,
        nickname,
        savedPaymentMethods,
        selectedMethod,
        addingMethod,
    } = state;

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        actions.onSetAllSelected(event.target.checked);
    };

    const handleInvoiceCheck = (
        event: ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        actions.onSetInvoiceSelected(id);
    };

    const handleInvoiceInputs = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        invoiceNumber: number
    ) => {
        actions.onUpdateInvoiceAmount([
            invoiceNumber,
            Number(event.target.value),
        ]);
    };

    const handleClickRemove = () => {
        if (totalSelected === Invoices.length) {
            navigate('/invoices');
        } else {
            actions.onDeleteInvoicesSelected();
        }
    };

    // GET refresh token section
    const handleRefreshTokenSuccess = (dataToken: Wrapper<TokenData>) => {
        if (paymentMethodType === PaymentMethodType.CreditCard) {
            actions.onSetModalOpen(true);
        } else {
            actions.onSetIsACHModalOpen(true);
        }
    };

    // refetch to call Get refresh token
    const { refetch: refetchToken, isFetching: isRefreshLoading } =
        CustomersActions.useFetchRefreshToken(
            handleRefreshTokenSuccess,
            (error) =>
                errorActions.defaultError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance
                )
        );

    // GET saved payment methods
    const handlePaymentMethodSuccess = (data: Wrapper<PaymentMethods>) => {
        if (data !== undefined) {
            actions.onSetSavedPaymentMethods(data);
        }
    };

    const customerData: TokenData = TokenHelper.getInitialData();
    let methodsParam: PaymentMethodsParams = {
        companyNumber: CompanyNumber,
        customerNumber: customerData.customerNumber,
    };

    if (!customerData.allowACH || !customerData.allowCreditCard) {
        methodsParam.paymentMethodType = !customerData.allowACH
            ? PaymentMethodType.CreditCard
            : PaymentMethodType.ACH;
    }

    const { isLoading, isFetching } = UsersActions.useGetPaymentMethods(
        methodsParam,
        accounts.length > 0 && CompanyNumber > 0 && isUserSignedIn,
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            ),
        handlePaymentMethodSuccess
    );

    const handleMethodTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const valueType: string = (event.target as HTMLInputElement).value;
        actions.onSetPaymentMethodType(
            PaymentMethodType[valueType as keyof typeof PaymentMethodType]
        );
        formRef?.current?.cleanForm();
    };

    const onSubmit = () => {
        const accounts: AccountInfo[] = instance.getAllAccounts();
        if (accounts.length > 0) {
            if (paymentMethodType === PaymentMethodType.CreditCard) {
                actions.onSetModalOpen(true);
            } else {
                actions.onSetIsACHModalOpen(true);
            }
        } else {
            refetchToken();
        }
    };

    const onSignIn = async () => {
        applicationActions.onUpdateIsPopupOpen(true);
        showLoader();
        try {
            const loginRequest = {
                scopes: B2C_CONSTANTS.scopes,
                authority: B2C_CONSTANTS.authoritySignIn,
            };
            await instance.loginPopup(loginRequest);
            const request: AuthInformation = CreateAuthInformation();
            const { data } = await postSignIn.mutateAsync(request);
            applicationActions.onAddUserData(data);
            applicationActions.onUpdateIsUserSignedIn();
        } finally {
            applicationActions.onUpdateIsPopupOpen(false);
            hideLoader();
        }
    };

    const metaDescription = FormatString(
        MetadataConstants.PAYMENTS_DESCRIPTION,
        Company.name
    );

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.PAYMENTS_TAB_TITLE}
                description={metaDescription}
                noIndex
            />
            <Container sx={{ minWidth: '900px' }}>
                <Paper sx={{ mb: '49px', mt: '82px', pt: '32px' }}>
                    <Box sx={{ pl: '30px', pr: '30px' }}>
                        <PayInvoicesView
                            totalSelected={totalSelected}
                            totalAmount={TotalAmount}
                            invoices={Invoices}
                            allowPending={allowPending}
                            isPopupOpen={isPopupOpen}
                            navigate={navigate}
                            handleClickRemove={handleClickRemove}
                            handleSelectAllClick={handleSelectAllClick}
                            handleInvoiceCheck={handleInvoiceCheck}
                            handleInvoiceInputs={handleInvoiceInputs}
                        />
                        <Divider />
                        {isLoading || isFetching ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner
                                    color="primary"
                                    size="45px"
                                    sx={{ mt: 3, mb: 3 }}
                                />
                            </Box>
                        ) : (
                            <>
                                {savedPaymentMethods.length <= 0 ||
                                addingMethod ? (
                                    <MethodFormsView
                                        paymentMethodType={paymentMethodType}
                                        paymentMethods={paymentMethods}
                                        formRef={formRef}
                                        billingInformation={BillingInformation}
                                        isErrorAmount={isErrorAmount}
                                        bankAccountInformation={
                                            bankAccountInformation
                                        }
                                        isRefreshLoading={isRefreshLoading}
                                        isB2CUserAccount={accounts.length > 0}
                                        isSavePaymentMethodChecked={
                                            isSavePaymentMethodChecked
                                        }
                                        isPopupOpen={isPopupOpen}
                                        onSignIn={onSignIn}
                                        onSubmit={onSubmit}
                                        handleMethodTypeChange={
                                            handleMethodTypeChange
                                        }
                                        onSetBillingInformation={
                                            actions.onSetBillingInformation
                                        }
                                        onSetBankAccountInformation={
                                            actions.onSetBankAccountInformation
                                        }
                                        onSetNotes={actions.onSetNotes}
                                        onSetNickname={actions.onSetNickname}
                                        onSetIsSaveMethodChecked={
                                            actions.onSetIsSaveMethodChecked
                                        }
                                        isAddingMethod={addingMethod}
                                        handleReturnClick={() =>
                                            actions.onUpdateAddingMethod(false)
                                        }
                                        isSuccessfullyLoggedIn={isUserSignedIn}
                                    />
                                ) : (
                                    <MethodTableView
                                        savedPaymentMethods={
                                            savedPaymentMethods
                                        }
                                        onAddMethodClick={() =>
                                            actions.onUpdateAddingMethod(true)
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    <ModalPayment
                        companyNumber={CompanyNumber}
                        billingInformation={BillingInformation}
                        invoices={Invoices}
                        totalAmount={TotalAmount}
                        notes={notes}
                        isPopupOpen={isPopupOpen}
                        isB2CUserAccount={accounts.length > 0}
                        nickname={nickname}
                        isSavedMethod={savedPaymentMethods.length > 0}
                        isAddingMethod={addingMethod}
                    />
                    <ModalACH
                        companyNumber={CompanyNumber}
                        billingInformation={BillingInformation}
                        invoices={Invoices}
                        totalAmount={TotalAmount}
                        bankAccountInformation={bankAccountInformation}
                        notes={notes}
                        isB2CUserAccount={accounts.length > 0}
                        nickname={nickname}
                        isSavedMethod={savedPaymentMethods.length > 0}
                        selectedMethod={selectedMethod}
                        isAddingMethod={addingMethod}
                    />
                </Paper>
                {savedPaymentMethods.length > 0 && !addingMethod ? (
                    <>
                        <Footer />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 5,
                                mb: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                title={BACK_INVOICES_BUTTON_TEXT}
                                onClick={() => {
                                    navigate('/invoices');
                                }}
                                startIcon={<KeyboardArrowLeftIcon />}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                title={PAY_BUTTON_TEXT}
                                disabled={
                                    !selectedMethod && !isLoading && !isFetching
                                }
                                onClick={() =>
                                    actions.onSetIsACHModalOpen(true)
                                }
                            />
                        </Box>
                    </>
                ) : (
                    <Footer />
                )}
            </Container>
            {fullPageLoader}
        </>
    );
}
