import styled from '@emotion/styled';

export const StyledPaperTop = styled.div`
    background-color: #ffffff;
    border-radius: 4px 4px 0px 0px;
    padding: 32px 32px 0px 32px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
    margin-left: 16px;
    margin-right: 16px;
`;

export const StyledPaperBottom = styled.div`
    background-color: #ffffff;
    border-radius: 0px 0px 4px 4px;
    margin-bottom: 30px;
    padding: 0px 32px 32px 32px;
    box-shadow: 0px 3px 1px -1px rgba(0, 0, 0, 0.2),
        0px 2px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
    margin-left: 16px;
    margin-right: 16px;
`;

export const StyledBreak = styled.hr`
    margin-top: 25px;
    margin-bottom: 40px;
    border-top-color: rgba(0, 0, 0, 0.12);
`;
