import Card from '../../../../components/Card/Card';
import Typography from '../../../../components/Typography/Typography';
import { StyledCard, StyledCardGrid } from '../../views/styling';
import { CardListProps, ListItemProps } from './CardList.types';

function ListItem(props: ListItemProps) {
    const { item } = props;
    return (
        <>
            <Typography
                variant="body1"
                display="inline"
                sx={{ fontWeight: 'bold' }}
                text={item.label}
                align="left"
            />
            <Typography
                variant="body1"
                display="inline"
                align="right"
                text={item.value}
            />
        </>
    );
}

export default function CardList(props: CardListProps) {
    const { items } = props;
    return (
        <StyledCard>
            <Card>
                <StyledCardGrid>
                    {items.map((item) => {
                        return <ListItem key={item.label} item={item} />;
                    })}
                </StyledCardGrid>
            </Card>
        </StyledCard>
    );
}
