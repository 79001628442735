import { Popover as MUIPopover } from '@mui/material';
import { PopoverProps } from './Popover.types';

export default function Popover(props: PopoverProps) {
    const {
        open,
        onClose,
        anchorEl,
        anchorOrigin,
        transformOrigin,
        sx,
        children,
    } = props;

    return (
        <MUIPopover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            sx={sx}
        >
            {children}
        </MUIPopover>
    );
}
