import { StyledEmpty, StyledHeaderText, StyledSelected } from './Styling';
import Typography from '../Typography/Typography';
import { HeaderGridProps } from '../../models/Props.types';

export default function HeaderGrid(props: HeaderGridProps) {
    const { isSelectedHeader, headerText } = props;

    return (
        <>
            {isSelectedHeader ? (
                <StyledSelected>
                    <StyledHeaderText>
                        <Typography
                            text={headerText}
                            variant="subtitle1"
                            color="otherText.primary"
                        />
                    </StyledHeaderText>
                </StyledSelected>
            ) : (
                <StyledEmpty>
                    <StyledHeaderText>
                        <Typography
                            text={headerText}
                            variant="h5"
                            color="otherText.primary"
                        />
                    </StyledHeaderText>
                </StyledEmpty>
            )}
        </>
    );
}
