import { useGet } from '../api/useGet';
import { PAYMENTS_API } from '../config';
import { Wrapper, PagedResults } from '../models/App.interfaces';
import { AxiosError } from 'axios';
import { InvoiceInformation, Summary } from '../models/Invoice.interfaces';
import { TokenHelper } from '../helpers/TokenHelper';

const base = 'accounts';

function useSummary(
    onErrorCallback: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (data: Wrapper<Summary>) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return useGet<Wrapper<Summary>>(
        PAYMENTS_API +
            `${base}/receivables/customers/${customerNumber}/summary`,
        true,
        {
            onSuccess: onSuccessCallback,
            onErrorCallback: onErrorCallback,
            staleTime: 0,
        }
    );
}

function useGetInvoices(
    company: number,
    pageSize: number,
    pageNumber: number,
    orderBy: string,
    ascending: boolean,
    enable: boolean,
    onErrorCallback?: (error: AxiosError) => Promise<void> | void,
    onSuccessCallback?: (
        data: Wrapper<PagedResults<InvoiceInformation>>
    ) => Promise<void> | void
) {
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    return useGet<Wrapper<PagedResults<InvoiceInformation>>>(
        PAYMENTS_API +
            // eslint-disable-next-line max-len
            `${base}/receivables/customers/${customerNumber}/outstanding-invoices?companyNumber=${company}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&ascending=${ascending}`,
        enable,
        {
            onErrorCallback: onErrorCallback,
            onSuccess: onSuccessCallback,
            staleTime: 0,
        }
    );
}

const AccountActions = {
    useSummary: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (data: Wrapper<Summary>) => Promise<void> | void
    ) => useSummary(onErrorCallback, onSuccessCallback),
    useGetInvoices: (
        company: number,
        pageSize: number,
        pageNumber: number,
        orderBy: string,
        ascending: boolean,
        enable: boolean,
        onErrorCallback?: (error: AxiosError) => Promise<void> | void,
        onSuccessCallback?: (
            data: Wrapper<PagedResults<InvoiceInformation>>
        ) => Promise<void> | void
    ) =>
        useGetInvoices(
            company,
            pageSize,
            pageNumber,
            orderBy,
            ascending,
            enable,
            onErrorCallback,
            onSuccessCallback
        ),
};

export default AccountActions;
