import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import { Company, Wrapper } from '../../../models/App.interfaces';
import {
    InvoiceInformation,
    Summary,
} from '../../../models/Invoice.interfaces';
import { InvoiceActions, InvoiceFactory } from './InvoicesContext.types';
import { TokenHelper } from '../../../helpers/TokenHelper';

function SetSummary(
    payload: Wrapper<Summary>,
    dispatch: Dispatch<InvoiceActions>
) {
    dispatch({ type: ActionTypes.Summary, payload: payload.data[0] });
}

function SetCompanyNumber(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.CompanyNumber, payload: payload });
}

function SetCompanies(
    payload: Wrapper<Company>,
    companyNumber: number,
    dispatch: Dispatch<InvoiceActions>
) {
    const companies = payload.data;
    const hasInvoices: boolean = companies.some(
        (company) =>
            company.companyNumber === companyNumber &&
            company.hasInvoices === true
    );
    if (!hasInvoices) {
        const company: Company | undefined = companies.find(
            (company) => company.hasInvoices === true
        );
        if (company !== undefined) {
            SetCompanyNumber(company.companyNumber, dispatch);
        } else {
            SetCompanyNumber(payload.data[0].companyNumber, dispatch);
        }
    }
    dispatch({ type: ActionTypes.Companies, payload: companies });
}

function SetRows(
    payload: InvoiceInformation[],
    dispatch: Dispatch<InvoiceActions>
) {
    dispatch({ type: ActionTypes.Rows, payload: payload });
}

function SetPageSize(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.PageSize, payload: payload });
}

function SetTotalPages(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.TotalPages, payload: payload });
}

function SetRowCount(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.RowCount, payload: payload });
}

function SetCurrentPage(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.CurrentPage, payload: payload });
}

function SetSelectedIDs(payload: number[], dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.SelectedIDs, payload: payload });
}

function SetSelectedRows(
    payload: InvoiceInformation[],
    dispatch: Dispatch<InvoiceActions>
) {
    dispatch({ type: ActionTypes.SelectedRows, payload: payload });
}

function SetSelectedCount(payload: number, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.SelectedCount, payload: payload });
}

function SetSelectedDollars(
    payload: number,
    dispatch: Dispatch<InvoiceActions>
) {
    dispatch({ type: ActionTypes.SelectedDollars, payload: payload });
}

function SetCurrentSelected(
    payload: InvoiceInformation[],
    dispatch: Dispatch<InvoiceActions>
) {
    dispatch({ type: ActionTypes.CurrentSelected, payload: payload });
}

function SetOrderBy(payload: string, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.OrderBy, payload: payload });
}

function SetAscending(payload: boolean, dispatch: Dispatch<InvoiceActions>) {
    dispatch({ type: ActionTypes.Ascending, payload: payload });
}

function SetCustomerName(payload: string, dispatch: Dispatch<InvoiceActions>) {
    const customerName = TokenHelper.getInitialData().customerName;
    payload = customerName;
    dispatch({ type: ActionTypes.CustomerName, payload: payload });
}

function UpdateCustomerName(dispatch: Dispatch<InvoiceActions>) {
    dispatch({
        type: ActionTypes.CustomerName,
        payload: TokenHelper.getInitialData().customerName,
    });
}

function SetHeaderText(payload: number, dispatch: Dispatch<InvoiceActions>) {
    if (payload === 1) {
        dispatch({
            type: ActionTypes.HeaderText,
            payload: ' Invoice Selected | $',
        });
    } else if (payload > 1) {
        dispatch({
            type: ActionTypes.HeaderText,
            payload: ' Invoices Selected | $',
        });
    }
}

function ResetGrid(dispatch: Dispatch<InvoiceActions>) {
    SetSelectedRows([], dispatch);
    SetSelectedIDs([], dispatch);
    SetCurrentSelected([], dispatch);
    SetRows([], dispatch);
    SetCurrentPage(1, dispatch);
}

export default function ActionFactory(
    dispatch: Dispatch<InvoiceActions>
): InvoiceFactory {
    return {
        onSetSummary: (payload: Wrapper<Summary>) =>
            SetSummary(payload, dispatch),
        onSetCompanyNumber: (payload: number) =>
            SetCompanyNumber(payload, dispatch),
        onSetCompanies: (payload: Wrapper<Company>, companyNumber: number) =>
            SetCompanies(payload, companyNumber, dispatch),
        onSetRows: (payload: InvoiceInformation[]) =>
            SetRows(payload, dispatch),
        onSetPageSize: (payload: number) => SetPageSize(payload, dispatch),
        onSetTotalPages: (payload: number) => SetTotalPages(payload, dispatch),
        onSetRowCount: (payload: number) => SetRowCount(payload, dispatch),
        onSetCurrentPage: (payload: number) =>
            SetCurrentPage(payload, dispatch),
        onSetSelectedIDs: (payload: number[]) =>
            SetSelectedIDs(payload, dispatch),
        onSetSelectedRows: (payload: InvoiceInformation[]) =>
            SetSelectedRows(payload, dispatch),
        onSetSelectedCount: (payload: number) =>
            SetSelectedCount(payload, dispatch),
        onSetSelectedDollars: (payload: number) =>
            SetSelectedDollars(payload, dispatch),
        onSetCurrentSelected: (payload: InvoiceInformation[]) =>
            SetCurrentSelected(payload, dispatch),
        onSetOrderBy: (payload: string) => SetOrderBy(payload, dispatch),
        onSetAscending: (payload: boolean) => SetAscending(payload, dispatch),
        onSetCustomerName: (payload: string) =>
            SetCustomerName(payload, dispatch),
        onUpdateCustomerName: () => UpdateCustomerName(dispatch),
        onSetHeaderText: (payload: number) => SetHeaderText(payload, dispatch),
        onResetGrid: () => ResetGrid(dispatch),
    };
}
