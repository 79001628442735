import styled from '@emotion/styled';

export const StyledBox = styled.div`
    top: 50px;
    width: 80%;
    max-width: 750px;
    min-width: 600px;
    margin: auto;
    height: auto;
    position: relative;
    padding: 30px;
`;

export const StyledMain = styled.div`
    background-color: #ffffff;
    height: auto;
    position: relative;
    margin: auto;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledHeader = styled.div`
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

export const StyledReceipt = styled.div`
    padding-right: 35px;
    padding-left: 35px;
`;

export const StyledDivider = styled.hr`
    border-top: 1px solid rgba(172, 183, 205, 0.8);
`;
