import { useMsal } from '@azure/msal-react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionCell from '../../../../components/ActionCell/ActionCell';
import ButtonConstants from '../../../../constants/ButtonConstants';
import ScreenConstants from '../../../../constants/ScreenConstants';
import { errorActions } from '../../../../helpers/ErrorActions';
import UsersActions from '../../../../hooks/UsersActions';
import { Customer } from '../../../../models/App.interfaces';
import { Actions, Row } from '../../../../models/Props.types';
import { useCustomers } from '../../context/CustomersContext';

export default function CustomerActionCell(
    params: GridRenderCellParams<string, Customer>
) {
    const { row } = params;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { instance } = useMsal();
    const [, actions] = useCustomers();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const customerRow: Row = { name: row.customerName, id: row.customerNumber };

    const deleteCustomer = UsersActions.useDeleteCustomer((error) => {
        errorActions.defaultError(error, navigate, enqueueSnackbar, instance);
        setIsModalOpen(false);
    });

    const handleRemoveAction = () => {
        setIsModalOpen(true);
    };

    const handleDeleteCustomer = async () => {
        await deleteCustomer.mutateAsync(row.customerNumber);
        actions.onDeleteCustomer(row.customerNumber);
        setIsModalOpen(false);
    };

    const menu: Actions[] = [
        { label: ButtonConstants.DELETE_REMOVE, action: handleRemoveAction },
    ];

    return (
        <ActionCell
            row={customerRow}
            menu={menu}
            modalMessage={ScreenConstants.DELETE_CUSTOMER_TEXT}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            actionItemMutate={handleDeleteCustomer}
        />
    );
}
