import { DividerProps, TableCellProps } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';

declare module '@mui/material/Toolbar' {
    interface ToolbarPropsVariantOverrides {
        selected: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        dark: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        dark: Palette['primary'];
        otherText: Palette['text'];
    }

    interface PaletteOptions {
        dark?: PaletteOptions['primary'];
        otherText?: PaletteOptions['text'];
    }
}

let theme = createTheme({
    palette: {
        dark: {
            main: '#002A3D',
        },
        otherText: {
            primary: 'rgba(0, 36, 61, 0.85)', // TODO: define text colors on Figma with Tidal
            secondary: '#000000',
        },
    },
});

theme = createTheme(theme, {
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: (root: {
                    ownerState: TableCellProps & Record<string, unknown>;
                }) => ({
                    borderBottom: 'none',
                    ...(root.ownerState.variant === 'head' && {
                        fontWeight: '600',
                    }),
                }),
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: (root: { ownerState: DividerProps }) => ({
                    ...(root.ownerState.orientation === 'horizontal' && {
                        marginTop: '24px',
                        marginBottom: '24px',
                    }),
                }),
            },
        },
        MuiToolbar: {
            variants: [
                {
                    props: { variant: 'selected' },
                    style: {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        borderRadius: '6px',
                        boxShadow:
                            '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                        minHeight: '48px',
                    },
                },
            ],
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                },
            },
        },
    },
});

export default theme;
