import * as Yup from 'yup';
import { ALPHANUMERIC_REGEX, NUMERIC_REGEX, ZIP_REGEX } from './Constants';

export const formLoginValidator = Yup.object()
    .shape({
        invoiceNumber: Yup.string()
            .matches(NUMERIC_REGEX, {
                message: 'Invoice Number should only contain numbers',
                excludeEmptyString: true,
            })
            .required('Invoice Number is required'),
        customerNumber: Yup.string()
            .matches(ALPHANUMERIC_REGEX, {
                message:
                    'Customer Number should only contain letters and numbers',
                excludeEmptyString: true,
            })
            .required('Customer Number is required'),
    })
    .required();

export const formBillingInfoValidator = (isB2CUserAccount: boolean) =>
    Yup.object()
        .shape({
            firstName: Yup.string()
                .required('First Name is required')
                .max(24, 'First Name must be at most 24 characters'),
            lastName: Yup.string()
                .required('Last Name is required')
                .max(24, 'Last Name must be at most 24 characters'),
            city: Yup.string()
                .required('City is required')
                .max(40, 'City must be at most 40 characters'),
            email: Yup.string().when([], {
                is: () => isB2CUserAccount,
                then: Yup.string().nullable(),
                otherwise: Yup.string()
                    .required('Email is required')
                    .max(100, 'Email must be at most 100 characters')
                    .email('Email must be a valid email'),
            }),
            address: Yup.string()
                .required('Address is required')
                .max(50, 'Address must be at most 50 characters'),
            postalCode: Yup.string()
                .required('Zip Code is required')
                .matches(ZIP_REGEX, {
                    message: 'Invalid Zip Code format',
                    excludeEmptyString: true,
                })
                .max(50, 'Zip code must be at most 50 characters'),
            state: Yup.string().required('State is required'),
        })
        .required();

export const formBankAccountInformationValidator = Yup.object()
    .shape({
        routingNumber: Yup.string()
            .required('Routing Number is required')
            .length(9, 'Please enter a 9-digit number'),
        accountNumber: Yup.string()
            .required('Account Number is required')
            .min(5, 'Account Number must be at least 5 characters')
            .max(17, 'Account Number must be at most 17 characters'),
        accountNumberConfirm: Yup.string()
            .required('Account Number is required')
            .oneOf([Yup.ref('accountNumber')], 'Account Numbers do not match'),
        accountType: Yup.string().required('Account Type is required'),
    })
    .required();

export const formNoteValidator = Yup.object().shape({
    note: Yup.string().max(256, 'Note must be at most 256 characters'),
});
