import { Table as MUITable } from '@mui/material';
import { TableProps } from './Table.types';

export default function Table(props: TableProps) {
    const { sx, size, children } = props;

    return (
        <MUITable sx={sx} size={size}>
            {children}
        </MUITable>
    );
}
