import KeyBoardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/divider/Divider';
import Typography from '../../../../components/Typography/Typography';
import {
    BACK_SAVED_PAYMENTS_LABEL,
    RETURN_BUTTON_TEXT,
} from '../../../../helpers/Constants';
import FormSection from '../../components/formSection/FormSection';
import PaymentMethodSection from '../../components/paymentMethodSection/PaymentMethodSection';
import { MethodsFormsViewProps } from './MethodFormsView.types';

export default function MethodFormsView(props: MethodsFormsViewProps) {
    const {
        paymentMethodType,
        paymentMethods,
        isPopupOpen,
        formRef,
        billingInformation,
        isErrorAmount,
        bankAccountInformation,
        isRefreshLoading,
        isSavePaymentMethodChecked,
        isB2CUserAccount,
        onSignIn,
        onSubmit,
        handleMethodTypeChange,
        onSetBillingInformation,
        onSetBankAccountInformation,
        onSetNotes,
        onSetNickname,
        onSetIsSaveMethodChecked,
        isAddingMethod,
        handleReturnClick,
        isSuccessfullyLoggedIn,
    } = props;

    return (
        <>
            {!isSuccessfullyLoggedIn && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '15px',
                    }}
                >
                    <Typography
                        variant="body2"
                        text={'Looking for previously saved payment methods?'}
                        color={'otherText.primary'}
                    />
                    <Button
                        title={'Sign In'}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            ml: 2,
                        }}
                        size={'small'}
                        endIcon={<KeyBoardArrowRightIcon />}
                        onClick={() => onSignIn()}
                        variant="outlined"
                        disabled={isPopupOpen}
                    />
                </div>
            )}
            {isAddingMethod && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '15px',
                    }}
                >
                    <Typography
                        variant="body2"
                        text={BACK_SAVED_PAYMENTS_LABEL}
                        color={'otherText.primary'}
                    />
                    <Button
                        title={RETURN_BUTTON_TEXT}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            ml: 2,
                        }}
                        size={'small'}
                        endIcon={<KeyBoardArrowRightIcon />}
                        onClick={handleReturnClick}
                        variant="outlined"
                        disabled={isPopupOpen}
                    />
                </div>
            )}
            <PaymentMethodSection
                paymentMethod={paymentMethodType}
                paymentMethodList={paymentMethods}
                onRadioChange={handleMethodTypeChange}
                isPopupOpen={isPopupOpen}
            />
            <Divider />
            <FormSection
                ref={formRef}
                billingInformation={billingInformation}
                disable={isErrorAmount}
                onSubmit={onSubmit}
                setBillingInformation={onSetBillingInformation}
                paymentMethod={paymentMethodType}
                bankAccountInformation={bankAccountInformation}
                setBankAccountInformation={onSetBankAccountInformation}
                setNotes={onSetNotes}
                setNickname={onSetNickname}
                isRefreshLoading={isRefreshLoading}
                isPopupOpen={isPopupOpen}
                isSavePaymentMethodChecked={isSavePaymentMethodChecked}
                setIsSaveMethodChecked={onSetIsSaveMethodChecked}
                isB2CUserAccount={isB2CUserAccount}
                isSuccessfullyLoggedIn={isSuccessfullyLoggedIn}
            />
        </>
    );
}
