import {
    COMPANY_NAME_KEY,
    CONTACT_INFORMATION_KEY,
    CONTACT_INFO_DEFAULT,
} from '../../../helpers/Constants';
import { MESSAGE_ERROR_PAGE } from '../../../helpers/ErrorConstants';
import { TokenHelper } from '../../../helpers/TokenHelper';
import { FormatString } from '../../../helpers/Utilities';
import ErrorView from './ErrorView';

export default function InsightsView() {
    const contactInfo =
        sessionStorage.getItem(CONTACT_INFORMATION_KEY) || CONTACT_INFO_DEFAULT;
    const message = FormatString(MESSAGE_ERROR_PAGE, contactInfo);
    const companyName: string = sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';

    // eslint-disable-next-line no-console
    console.clear();
    TokenHelper.deleteSession();

    const redirect = (route: string) => {
        window.location.replace(
            window.location.protocol + '//' + window.location.host + '/' + route
        );
    };

    return (
        <>
            <ErrorView
                code="Error"
                description="Something went wrong..."
                buttonTitle="Return to Login"
                message={message}
                route={companyName}
                redirect={redirect}
            />
        </>
    );
}
