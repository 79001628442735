import { useState } from 'react';
import DataGrid from '../../../components/DataGrid/DataGrid';
import Box from '../../../components/box/Box';
import Select from '../../../components/Select/Select';
import Paper from '../../../components/paper/Paper';
import Typography from '../../../components/Typography/Typography';
import Checkbox from '../../../components/checkbox/Checkbox';
import Popover from '../../../components/popover/Popover';
import Button from '../../../components/Button/Button';
import {
    COPY_BUTTON_TEXT,
    HISTORY_DATE_RANGE_OPTIONS,
    INCLUDE_RETURNS_LABEL,
    INVOICES_POPOVER_TITLE,
    NO_PAYMENT_HISTORY_MESSAGE,
    PAGE_SIZE_OPTIONS,
    PAYMENT_CONF_NUMBER_TITLE,
} from '../../../helpers/Constants';
import { HISTORY_POPOVER_TYPES } from '../../../models/App.types';
import {
    Company,
    PagedResults,
    PaymentHistory,
    Wrapper,
} from '../../../models/App.interfaces';
import { getHistoryColumns } from '../../../helpers/TableColumns';
import { getStartDate } from '../../../helpers/Utilities';
import UsersActions from '../../../hooks/UsersActions';
import { usePaymentHistory } from '../context/HistoryContext';
import { errorActions } from '../../../helpers/ErrorActions';
import { useMsal } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import theme from '../../../Theme';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import { useTidalTheme } from '@workwave-tidal/styles';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import MetadataConstants from '../../../constants/MetadataConstants';
import Metadata from '../../../components/metadata/Metadata';

export default function HistoryView() {
    const [state, actions] = usePaymentHistory();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { instance } = useMsal();
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const tidal = useTidalTheme();
    const blue = tidal.palette.tidalColors.oceanBlue;

    const handleCompanySuccess = (data: Wrapper<Company>) => {
        actions.onSetCompanies(data);
        actions.onSetCompanyNumber(data.data[0].companyNumber);
    };

    const { isFetching: companiesFetching, isSuccess: isSuccessCompanies } =
        UsersActions.useGetCompaniesbyUser(
            (error) =>
                errorActions.defaultError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance
                ),
            handleCompanySuccess
        );

    const handleHistorySuccess = (
        data: Wrapper<PagedResults<PaymentHistory>>
    ) => {
        if (data !== undefined && data.data !== undefined) {
            actions.onSetPaymentHistory(data.data[0].results);
            actions.onUpdateHistoryPagination({
                rowCount: data.data[0].totalCount,
                totalPages: data.data[0].totalPages,
            });
        } else {
            actions.onSetPaymentHistory([]);
            actions.onUpdateHistoryPagination({
                rowCount: 0,
                totalPages: 0,
            });
        }
    };

    const { isFetching } = UsersActions.useGetPaymentHistory(
        state.companyNumber,
        state.historyParams,
        isSuccessCompanies && state.companyNumber > 0,
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            ),
        handleHistorySuccess
    );

    function CustomNoRowsOverlay() {
        return (
            <Typography
                variant="h5"
                text={NO_PAYMENT_HISTORY_MESSAGE}
                sx={{ textAlign: 'center', mt: 22 }}
                color="text.primary"
            />
        );
    }

    const handleCompanyChange = (event: any) => {
        actions.onSetCompanyNumber(parseInt(event.target.value));
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            pageNumber: 1,
        });
        actions.onSetPaymentHistory([]);
    };

    const handleReturnCheck = (event: any) => {
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            includeReturns: event.target.checked,
        });
    };

    const handlePageSizeChange = (event: any) => {
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            pageSize: event,
        });
    };

    const handleSortChange = (event: any) => {
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            orderBy: event[0].field,
            ascending: event[0].sort === 'asc' ? true : false,
        });
    };

    const handlePageChange = (event: any) => {
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            pageNumber: event + 1,
        });
    };

    const handleDateRangeChange = (event: SelectChangeEvent) => {
        actions.onUpdateDateValue(event.target.value);
        const startDate = getStartDate(event.target.value);
        actions.onUpdateHistoryParameters({
            ...state.historyParams,
            startDate: startDate.toUTCString(),
            endDate: new Date().toUTCString(),
        });
    };

    const handleCellClick = (
        params: GridCellParams,
        event: MuiEvent<React.MouseEvent<HTMLElement>>
    ) => {
        if (params.field === HISTORY_POPOVER_TYPES.reference) {
            actions.onUpdateConfirmationPopoverOpen(true);
            setAnchor(event.target as HTMLElement);
            actions.onUpdateConfirmationNumber(params.row.referenceNumber);
        } else if (
            params.field === HISTORY_POPOVER_TYPES.invoice &&
            params.row.appliedInvoices.length > 3
        ) {
            actions.onUpdateInvoicesPopoverOpen(true);
            setAnchor(event.target as HTMLElement);
            actions.onUpdateInvoicesList(params.row.appliedInvoices);
        }
    };

    const handlePopoverClose = () => {
        actions.onUpdateConfirmationPopoverOpen(false);
        actions.onUpdateInvoicesPopoverOpen(false);
        setAnchor(null);
    };

    const handlePopoverButton = () => {
        navigator.clipboard.writeText(state.confirmationNumber);
    };

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.HISTORY_TAB_TITLE}
                description={MetadataConstants.HISTORY_DESCRIPTION}
                noIndex
            />
            <Box
                sx={{
                    minWidth: '850px',
                    pl: 3,
                    pr: 3,
                }}
            >
                <Box sx={{ mb: 5, mt: 10, position: 'relative' }}>
                    <Select
                        native
                        sx={{
                            minWidth: '200px',
                            bgcolor: 'background.paper',
                            maxHeight: '56px',
                            maxWidth: '300px',
                            flex: '25%',
                        }}
                        label="Company"
                        labelId="company"
                        id="company"
                        onChange={handleCompanyChange}
                        disabled={
                            state.companies.length <= 1 || companiesFetching
                        }
                    >
                        {state.companies.map((company) => (
                            <option
                                key={company.companyNumber}
                                value={company.companyNumber}
                            >
                                {company.companyName}
                            </option>
                        ))}
                    </Select>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                        }}
                    >
                        <Checkbox
                            checked={state.historyParams.includeReturns}
                            onCheckboxChange={handleReturnCheck}
                            label={INCLUDE_RETURNS_LABEL}
                        />
                        <Select
                            sx={{
                                minWidth: '200px',
                                maxWidth: '200px',
                                bgcolor: 'background.paper',
                                mb: 4,
                                float: 'right',
                            }}
                            label="Show"
                            id="show"
                            value={state.dateValue}
                            onChange={handleDateRangeChange}
                            disabled={companiesFetching}
                            items={HISTORY_DATE_RANGE_OPTIONS}
                        />
                    </Box>
                </Box>
                <Paper sx={{ pr: 2 }}>
                    <Typography
                        variant="h5"
                        text={'Payment History'}
                        color={'otherText.primary'}
                        sx={{ p: 1, pt: 3 }}
                    />
                    <DataGrid
                        rows={state.paymentHistory}
                        columns={getHistoryColumns(blue[500])}
                        getRowId={(rows: { referenceNumber: any }) =>
                            rows.referenceNumber
                        }
                        sx={{
                            border: 0,
                            borderRadius: 0,
                            borderColor: 'divider',
                            minHeight:
                                state.paymentHistory.length > 0
                                    ? '100px'
                                    : '500px',
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: theme.palette.dark.main,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '& .principal-cell--cell': {
                                borderRight: '2px solid',
                                borderColor: 'divider',
                            },
                        }}
                        hideFooterSelectedRowCount
                        loading={companiesFetching || isFetching}
                        pageSize={state.historyParams.pageSize}
                        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                        onPageSizeChange={handlePageSizeChange}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'paymentDate', sort: 'desc' },
                                ],
                            },
                        }}
                        onSortModelChange={handleSortChange}
                        sortingOrder={['desc', 'asc']}
                        paginationMode={'server'}
                        rowCount={state.historyPagination.rowCount}
                        onPageChange={handlePageChange}
                        disableColumnMenu
                        page={state.historyParams.pageNumber - 1}
                        autoHeight={state.historyPagination.rowCount > 0}
                        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                        onCellClick={handleCellClick}
                    />
                </Paper>
            </Box>
            <Popover
                open={state.confirmationPopoverOpen}
                onClose={handlePopoverClose}
                anchorEl={anchor}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        minWidth: '350px',
                    },
                }}
            >
                <Box sx={{ pr: 3, pl: 3, pt: 2, pb: 2 }}>
                    <Typography
                        variant="h6"
                        text={PAYMENT_CONF_NUMBER_TITLE}
                        color={'otherText.primary'}
                        sx={{ fontWeight: 'bold', pb: 2 }}
                    />
                    <Typography
                        variant="body1"
                        text={state.confirmationNumber}
                        color={'otherText.primary'}
                    />
                </Box>
                <Button
                    variant="text"
                    onClick={handlePopoverButton}
                    title={COPY_BUTTON_TEXT}
                    size="small"
                    sx={{ mb: 2, mr: 2, float: 'right' }}
                />
            </Popover>
            <Popover
                open={state.invoicesPopoverOpen}
                onClose={handlePopoverClose}
                anchorEl={anchor}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        minWidth: '200px',
                        maxWidth: '500px',
                    },
                }}
            >
                <Box sx={{ pr: 3, pl: 3, pt: 2, pb: 2 }}>
                    <Typography
                        variant="h6"
                        text={INVOICES_POPOVER_TITLE}
                        color={'otherText.primary'}
                        sx={{ fontWeight: 'bold', pb: 2 }}
                    />
                    <Typography
                        variant="body1"
                        text={String(state.invoicesList).replaceAll(',', ', ')}
                        color={'otherText.primary'}
                    />
                </Box>
            </Popover>
        </>
    );
}
