import RedirectConstants from '../constants/RedirectConstants';
import {
    AuthInformation,
    GeneralInformation,
    PatchPaymentMethodBody,
} from '../models/App.interfaces';
import { PatchPaymentMethodPath } from '../models/App.types';
import {
    InvoiceInformationPayment,
    InvoiceToPay,
    Summary,
} from '../models/Invoice.interfaces';
import { HistoryDateProps, Item } from '../models/Props.types';
import {
    ACHTransactionPost,
    BankAccountInformation,
    BillingInformation,
    HostedTransactionPost,
    HostedTransactionPut,
    IFrameResult,
    ACHPaymentMethodPost,
    SavedPaymentMethod,
    CreditCardPaymentMethodPost,
    CreditCardMethodPut,
    IFrameMethodResult,
    CreditCardPaymentMethodReplace,
} from '../models/Transaction.interfaces';
import {
    COMPANY_NAME_KEY,
    CONTACT_INFO_DEFAULT,
    DOLLAR_FORMAT,
    IMAGE_HEADER,
    LAST_DIGITS_TO_SHOW,
    MASKED_DIGITS_DEFAULT,
    TAB_VALUES,
} from './Constants';
import { EncryptionHelper } from './EncryptionHelper';
import { TokenHelper } from './TokenHelper';

export function ToDollarFormat(value: number) {
    return '$' + value.toFixed(2).toString().replace(DOLLAR_FORMAT, ',');
}

export function FormatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
    }
    return str;
}

export function GetInitials(name: string) {
    return name
        .split(' ')
        .map((a) => a[0])
        .join('');
}

export function FormatFooter(string: string) {
    const footer = string.replace(/-/g, '\u2011');

    return footer;
}

export function RemoveItemFromList<T>(list: T[], item: T) {
    return list.filter(function (e) {
        return e !== item;
    });
}

export function CreateSummaryList(
    summary: Summary,
    allowPending: boolean
): Item[] {
    let items: Item[] = [
        { label: 'Open Invoices', value: summary.invoiceCount },
        {
            label: 'Total Due',
            value: ToDollarFormat(summary.remainingDueBalance),
        },
        ...(allowPending
            ? [
                  {
                      label: 'Pending Payments',
                      value: ToDollarFormat(summary.pendingPayments),
                  },
              ]
            : []),
        {
            label: 'Past Due',
            value: ToDollarFormat(summary.pastDueBalance),
        },
    ];

    return items;
}

export function CreateHostedTransaction(
    companyNumber: number,
    billingInformation: BillingInformation,
    invoices: InvoiceInformationPayment[],
    notes: string,
    nickname: string,
    isSavePaymentMethodChecked: boolean
): HostedTransactionPost {
    const invoicesToPay: InvoiceToPay[] = invoices.map((invoice) => {
        return { amount: invoice.amount, invoiceNumber: invoice.invoiceNumber };
    });
    const hostedTransaction: HostedTransactionPost = {
        callbackPage: 'result',
        companyNumber: companyNumber,
        invoicesToPay: invoicesToPay,
        billingInformation: billingInformation,
        notes,
        savePaymentMethod: isSavePaymentMethodChecked,
        friendlyName: isSavePaymentMethodChecked ? nickname : '',
    };

    return hostedTransaction;
}

export function CreateACHTransaction(
    companyNumber: number,
    billingInformation: BillingInformation,
    bankAccountInformation: BankAccountInformation,
    invoices: InvoiceInformationPayment[],
    notes: string,
    nickname: string,
    isSavePaymentMethodChecked: boolean
): ACHTransactionPost {
    const invoicesToPay: InvoiceToPay[] = invoices.map((invoice) => {
        return { amount: invoice.amount, invoiceNumber: invoice.invoiceNumber };
    });
    const publicKey = TokenHelper.getInitialData().publicKey;

    const hostedTransaction: ACHTransactionPost = {
        companyNumber: companyNumber,
        invoicesToPay: invoicesToPay,
        billingInformation: billingInformation,
        routingNumber: EncryptionHelper.encrypt(
            bankAccountInformation.routingNumber,
            publicKey
        ),
        accountNumber: EncryptionHelper.encrypt(
            bankAccountInformation.accountNumber,
            publicKey
        ),
        accountType: bankAccountInformation.accountType,
        notes,
        savePaymentMethod: isSavePaymentMethodChecked,
        friendlyName: isSavePaymentMethodChecked ? nickname : '',
    };

    return hostedTransaction;
}

export function CreatePutHostedTransaction(
    result: IFrameResult
): HostedTransactionPut {
    const hostedTransaction: HostedTransactionPut = {
        cardLastFourDigits: result.MaskedCardNumber ?? '',
        cardType: result.CardType ?? '',
        paymentProcessDateTimeUTC: result.CreateDate
            ? new Date(result.CreateDate).toISOString()
            : undefined,
        token: result.PaymentAccountToken,
    };

    return hostedTransaction;
}

export function CreateCreditCardMethodPut(
    result: IFrameMethodResult
): CreditCardMethodPut {
    const hostedTransaction: CreditCardMethodPut = {
        cardLastFourDigits: result.MaskedCardNumber ?? '',
        cardType: result.CardType ?? '',
        cardExpiration: result.CardExpiration ?? '',
        token: result.PaymentAccountToken ?? '',
        correlationId: result.TeamCorrelationId ?? '',
    };

    return hostedTransaction;
}

export function CreateUpdatePaymentMethod(
    path: PatchPaymentMethodPath,
    value: string | boolean
): PatchPaymentMethodBody[] {
    const body: PatchPaymentMethodBody[] = [
        {
            op: 'replace',
            path: path,
            value: value,
        },
    ];

    return body;
}

export function CreateAuthInformation(): AuthInformation {
    const companyName: string = sessionStorage.getItem(COMPANY_NAME_KEY) ?? '';
    const domain: string = window.location.hostname;
    const result: AuthInformation = {
        companyName: companyName,
        domainUrl: domain,
    };

    return result;
}

export function hideDigits(value: string) {
    if (value.length >= LAST_DIGITS_TO_SHOW) {
        return (
            '*'.repeat(MASKED_DIGITS_DEFAULT) +
            value.slice(-LAST_DIGITS_TO_SHOW)
        );
    }

    return '*'.repeat(value.length);
}

export function formatGeneralInformation(
    data: GeneralInformation
): GeneralInformation {
    const logo = data && data.logo ? IMAGE_HEADER + data.logo : '';
    const companyName = data && data.name !== null ? data.name : '';
    const contactInfo =
        data && data.contactInformation !== null
            ? data.contactInformation
            : CONTACT_INFO_DEFAULT;

    const information: GeneralInformation = {
        logo: logo,
        contactInformation: contactInfo,
        name: companyName,
    };
    return information;
}

export function setPathName(pathname: string) {
    let validTabs: string[] = TAB_VALUES.map((tabs) => tabs.value.toString());
    if (validTabs.includes(pathname)) {
        return pathname;
    } else {
        return false;
    }
}

export function getInitialHistoryDates(): HistoryDateProps {
    const today = new Date();

    const initialDates: HistoryDateProps = {
        today: today.toUTCString(),
        priorPeriod: new Date(
            new Date().setDate(today.getDate() - 90)
        ).toUTCString(),
    };

    return initialDates;
}

export function objectToQueryString(obj: any) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
}

export function getStartDate(value: string) {
    const today = new Date();
    switch (value) {
        case '90':
            const ninetyDays = new Date(
                new Date().setDate(today.getDate() - 90)
            );
            return ninetyDays;
        case '6':
            const sixMonths = new Date(
                new Date().setMonth(today.getMonth() - 6)
            );
            return sixMonths;
        case '1':
            const oneYear = new Date(
                new Date().setFullYear(today.getFullYear() - 1)
            );
            return oneYear;
        case '0':
            const allTime = new Date(0);
            return allTime;
        default:
            const defaultValue = new Date(
                new Date().setDate(today.getDate() - 90)
            );
            return defaultValue;
    }
}

export function CreateACHPaymentMethodPost(
    companyNumber: number,
    bankAccountInformation: BankAccountInformation,
    savedPaymentMethodDetails: SavedPaymentMethod,
    email: string
): ACHPaymentMethodPost {
    const publicKey = TokenHelper.getInitialData().publicKey;

    const achPaymentMethod: ACHPaymentMethodPost = {
        customerNumber: [],
        isDefault: savedPaymentMethodDetails.isPrimary,
        friendlyName: savedPaymentMethodDetails.nickname,
        companyNumber: companyNumber,
        billingInformation: {
            firstName: savedPaymentMethodDetails.firstName,
            lastName: savedPaymentMethodDetails.lastName,
            address: savedPaymentMethodDetails.address,
            city: savedPaymentMethodDetails.city,
            email: email,
            state: savedPaymentMethodDetails.state,
            postalCode: savedPaymentMethodDetails.postalCode,
        },
        routingNumber: EncryptionHelper.encrypt(
            bankAccountInformation.routingNumber,
            publicKey
        ),
        accountNumber: EncryptionHelper.encrypt(
            bankAccountInformation.accountNumber,
            publicKey
        ),
        accountType: bankAccountInformation.accountType,
    };

    return achPaymentMethod;
}

export function CreateCreditCardMethodPost(
    companyNumber: number,
    savedPaymentMethodDetails: SavedPaymentMethod,
    email: string
): CreditCardPaymentMethodPost {
    const achPaymentMethod: CreditCardPaymentMethodPost = {
        customerNumber: [],
        isDefault: savedPaymentMethodDetails.isPrimary,
        friendlyName: savedPaymentMethodDetails.nickname,
        companyNumber: companyNumber,
        billingInformation: {
            firstName: savedPaymentMethodDetails.firstName,
            lastName: savedPaymentMethodDetails.lastName,
            address: savedPaymentMethodDetails.address,
            city: savedPaymentMethodDetails.city,
            email: email,
            state: savedPaymentMethodDetails.state,
            postalCode: savedPaymentMethodDetails.postalCode,
        },
        callbackURL: RedirectConstants.METHOD_RESULT_PAGE,
    };

    return achPaymentMethod;
}

export function CreateRevertCreditCardMethod(
    billingInformation: BillingInformation,
    email: string
): CreditCardPaymentMethodReplace {
    billingInformation.email = email;
    const achPaymentMethod: CreditCardPaymentMethodReplace = {
        billingInformation: billingInformation,
        callbackURL: RedirectConstants.METHOD_RESULT_PAGE,
    };

    return achPaymentMethod;
}

export function ConvertEmailForPasswordChange(email: string) {
    return encodeURIComponent(email);
}
