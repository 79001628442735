import Routes from './routes/Routes';
import theme from './Theme';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApplicationDataProvider } from './context/ApplicationContext';
import ErrorBoundary from './ErrorBoundary';
import InsightsError from './pages/error/views/InsightsError';
import { TidalThemeProvider } from '@workwave-tidal/styles';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import ApiInterceptor from './api/ApiInterceptor';

const queryClient = new QueryClient();

function App({ instance }: { instance: IPublicClientApplication }) {
    return (
        <HelmetProvider>
            <ErrorBoundary onError={<InsightsError />}>
                <TidalThemeProvider theme={theme} disableCssBaseLine>
                    <MsalProvider instance={instance}>
                        <ApiInterceptor>
                            <QueryClientProvider client={queryClient}>
                                <ApplicationDataProvider>
                                    <SnackbarProvider
                                        maxSnack={3}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Routes />
                                    </SnackbarProvider>
                                </ApplicationDataProvider>
                            </QueryClientProvider>
                        </ApiInterceptor>
                    </MsalProvider>
                </TidalThemeProvider>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default App;
