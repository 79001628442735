import { MenuItem, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormSelectProps } from './FormSelect.types';

export default function FormSelect(props: FormSelectProps) {
    const { register } = useFormContext();
    const {
        value,
        label,
        items,
        size,
        error,
        sx,
        registerText,
        onChange,
        select,
        selectProps,
        helperText,
        disabled,
    } = props;
    return (
        <TextField
            sx={sx}
            size={size}
            error={error}
            value={value}
            select={select}
            SelectProps={selectProps}
            label={label}
            {...register(registerText, {
                onChange: (event) => onChange(event),
            })}
            helperText={helperText}
            disabled={disabled}
        >
            {items.map((item) => (
                <MenuItem key={item.key} value={item.name}>
                    {item.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
