import {
    Select as MUISelect,
    InputLabel,
    FormControl,
    MenuItem,
} from '@mui/material';
import { SelectProps } from './Select.types';

export default function Select(props: SelectProps) {
    const {
        sx,
        disabled,
        label,
        labelId,
        native,
        id,
        value,
        onChange,
        children,
        testid,
        defaultValue,
        items,
    } = props;

    if (items) {
        return (
            <FormControl sx={sx} disabled={disabled}>
                <InputLabel shrink id={labelId}>
                    {label}
                </InputLabel>
                <MUISelect
                    notched
                    native={native}
                    label={label}
                    labelId={labelId}
                    id={id}
                    value={value}
                    onChange={(event) => onChange(event)}
                    defaultValue={defaultValue}
                    inputProps={{
                        'data-testid': { testid },
                    }}
                >
                    {items.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                            {item.name}
                        </MenuItem>
                    ))}
                </MUISelect>
            </FormControl>
        );
    }
    return (
        <FormControl sx={sx} disabled={disabled}>
            <InputLabel shrink id={labelId}>
                {label}
            </InputLabel>
            <MUISelect
                notched
                native={native}
                label={label}
                labelId={labelId}
                id={id}
                value={value}
                onChange={(event) => onChange(event)}
                defaultValue={defaultValue}
                inputProps={{
                    'data-testid': { testid },
                }}
            >
                {children}
            </MUISelect>
        </FormControl>
    );
}
