import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '../../../components/box/Box';
import Typography from '../../../components/Typography/Typography';
import {
    TRANSACTION_CANCELLED,
    TRANSACTION_SUCCESS,
    ADD_SUCCESSFULLY_MESSAGE,
    ADD_METHOD_CANCELLED_MESSAGE,
} from '../../../helpers/Constants';
import { CreateCreditCardMethodPut } from '../../../helpers/Utilities';
import { useSnackbar } from 'notistack';
import { IFrameMethodResult } from '../../../models/Transaction.interfaces';
import { errorActions } from '../../../helpers/ErrorActions';
import { useMsal } from '@azure/msal-react';
import UsersActions from '../../../hooks/UsersActions';
import { EventStatus } from '../../../models/App.types';
import Metadata from '../../../components/metadata/Metadata';

export default function MethodResultView() {
    const [result, setResult] = useState<IFrameMethodResult>();
    const [isActionCalled, setIsActionCalled] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { instance } = useMsal();

    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );
    const status = searchParams.get('TransactionStatus');

    useEffect(() => {
        if (status === null) {
            navigate('/error');
        } else if (result === undefined) {
            const iFrameResult: IFrameMethodResult =
                Object.fromEntries(searchParams);
            setResult(iFrameResult);
        }
    }, [navigate, result, searchParams, status]);

    const deleteMethod = UsersActions.useDeleteCreditCardPaymentMethod(
        result?.TeamCorrelationId || '',
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            )
    );

    const putMethod = UsersActions.usePutCreditCardPaymentMethod((error) =>
        errorActions.defaultError(error, navigate, enqueueSnackbar, instance)
    );

    useEffect(() => {
        if (!isActionCalled && result !== undefined) {
            setIsActionCalled(true);
            if (status === EventStatus.Approved) {
                const methodRequest = CreateCreditCardMethodPut(result);
                putMethod.mutate({
                    body: methodRequest,
                    id: result?.TeamReferenceNumber ?? '',
                });
            } else {
                deleteMethod.mutate(result?.TeamReferenceNumber ?? '');
            }
        }
    }, [deleteMethod, isActionCalled, putMethod, result, status]);

    return (
        <>
            <Metadata robotsOnly />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ backgroundColor: 'background.default' }}
            >
                <Box sx={{ m: 'auto', maxWidth: '690px', pt: 4 }}>
                    <Typography
                        color={'text.primary'}
                        variant="h6"
                        text={
                            status === EventStatus.Approved
                                ? TRANSACTION_SUCCESS
                                : TRANSACTION_CANCELLED
                        }
                        sx={{ fontWeight: 'bold', mb: 2 }}
                    />
                    <Typography
                        color={'text.primary'}
                        variant="body2"
                        text={
                            status === EventStatus.Approved
                                ? ADD_SUCCESSFULLY_MESSAGE
                                : ADD_METHOD_CANCELLED_MESSAGE
                        }
                    />
                </Box>
            </Box>
        </>
    );
}
