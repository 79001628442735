import {
    Checkbox,
    TableCell,
    TableHead as MUITableHead,
    TableRow,
} from '@mui/material';
import { HeaderTableProps } from './TableHead.types';

export default function TableHead(props: HeaderTableProps) {
    const { cells, onCheckboxSelect, totalSelected, rowCount, disabled } =
        props;

    return (
        <MUITableHead>
            <TableRow>
                <TableCell padding="none">
                    <Checkbox
                        indeterminate={
                            totalSelected > 0 && totalSelected < rowCount
                        }
                        checked={rowCount > 0 && totalSelected === rowCount}
                        onChange={onCheckboxSelect}
                        disabled={disabled}
                    />
                </TableCell>
                {cells.map((cells) => (
                    <TableCell
                        variant="head"
                        key={cells.id}
                        align={cells.align ? cells.align : 'left'}
                        padding={cells.disablePadding ? 'none' : 'normal'}
                    >
                        {cells.label}
                    </TableCell>
                ))}
            </TableRow>
        </MUITableHead>
    );
}
