export enum ActionTypes {
    ListPaymentMethods = 'paymentMethods.list',
    UpdateIsPrimaryPopupOpen = 'paymentMethods.isPrimaryPopupOpen.update',
    UpdatePrimaryMethodId = 'paymentMethods.id.update',
    ListCompanies = 'paymentMethods.companies.list',
    UpdateCompanyNumber = 'paymentMethods.company.update',
    DeletePaymentMethod = 'paymentMethods.delete',
    SetIsCreditCardLoading = 'paymentMethods.cell.loading.creditCard',
    SetCreditCardTransactionSettings = 'paymentMethods.creditCard.transactionSettings.update',
    SetHasEventSourceFailed = 'paymentMethods.EventSource.failed.update',
    SetStreamEventStatus = 'paymentMethods.streamEvent.status.update',
    SetCreditCardModalReset = 'paymentMethods.creditCard.modal.reset',
}
