import { TextField as MUITextField } from '@mui/material';
import { TextFieldProps } from './TextField.types';

export default function TextField(props: TextFieldProps) {
    const {
        id,
        value,
        size,
        hiddenLabel,
        error,
        handleChange,
        inputComponent,
        inputProps,
        helperText,
        disabled,
    } = props;
    return (
        <MUITextField
            error={error}
            fullWidth
            hiddenLabel={hiddenLabel}
            value={value}
            size={size}
            helperText={helperText}
            onChange={(event) => handleChange(event, id)}
            InputProps={{
                inputComponent: inputComponent,
                inputProps: inputProps,
            }}
            disabled={disabled}
        />
    );
}
