import { api } from './ApiInterceptor';
import { Options, useGenericMutation } from './ReactQuery';

export function useDelete<T>(url: string, options?: Options<T>) {
    return useGenericMutation<T>(
        (id) => api.delete(`${url}/${id}`, options?.params),
        url,
        {
            ...options,
            config: { retry: false },
        }
    );
}
