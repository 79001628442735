import {
    ChangeEvent,
    useImperativeHandle,
    forwardRef,
    ForwardedRef,
} from 'react';
import { SelectChangeEvent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    formBankAccountInformationValidator,
    formBillingInfoValidator,
} from '../../../../helpers/Validators';
import Button from '../../../../components/Button/Button';
import { CreditCardFormProps, FormRef } from './FormSection.types';
import BillingInfoForm from '../billingInfoForm/BillingInfoForm';
import Grid from '../../../../components/Grid/Grid';
import GridItem from '../../../../components/Grid/GridItem';
import BankAccountForm from '../bankAccountForm/BankAccountForm';
import InputField from '../../../../components/InputField/InputField';
import Spinner from '../../../../components/Spinner/Spinner';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Typography from '../../../../components/Typography/Typography';
import Checkbox from '../../../../components/checkbox/Checkbox';
import { SAVE_METHOD_CHECKBOX_TEXT } from '../../../../helpers/Constants';
import { TokenHelper } from '../../../../helpers/TokenHelper';
import { PaymentMethodType } from '../../../../models/App.types';

function FormSection(props: CreditCardFormProps, ref: ForwardedRef<FormRef>) {
    const {
        paymentMethod,
        disable,
        onSubmit,
        billingInformation,
        setBillingInformation,
        bankAccountInformation,
        setBankAccountInformation,
        setNotes,
        isRefreshLoading,
        isPopupOpen,
        isSavePaymentMethodChecked,
        isB2CUserAccount,
        setIsSaveMethodChecked,
        setNickname,
        isSuccessfullyLoggedIn,
    } = props;

    useImperativeHandle(ref, () => ({
        cleanForm: () => {
            resetField('routingNumber');
            resetField('accountNumber');
            resetField('accountNumberConfirm');
            resetField('accountType');
        },
    }));

    const schema =
        paymentMethod === PaymentMethodType.ACH
            ? formBillingInfoValidator(isB2CUserAccount).concat(
                  formBankAccountInformationValidator
              )
            : formBillingInfoValidator(isB2CUserAccount);

    //initializing react-hook-form and setting yup as validation resolver
    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const {
        handleSubmit,
        resetField,
        formState: { errors },
    } = methods;

    const onModalSubmit = async () => {
        if (isB2CUserAccount) {
            setBillingInformation({
                ...billingInformation,
                email: TokenHelper.getUserInformation().emailAddress,
            });
        }
        onSubmit(billingInformation);
    };

    const handleStateSelectChange = (event: SelectChangeEvent) => {
        setBillingInformation({
            ...billingInformation,
            state: event.target.value,
        });
    };

    const handleAccounTypeSelectChange = (event: SelectChangeEvent) => {
        setBankAccountInformation({
            ...bankAccountInformation,
            accountType: event.target.value,
        });
    };

    const handleBillingInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setBillingInformation({
            ...billingInformation,
            [name]: value,
        });
    };

    const handleBankAccountInformationChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setBankAccountInformation({
            ...bankAccountInformation,
            [name]: value,
        });
    };

    const handleNotesInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNotes(value);
    };

    const handleNicknameInputChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        setNickname(value);
    };

    const handleSavePaymentMethodCheck = (
        event: ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        setIsSaveMethodChecked(event.target.checked);
    };

    return (
        <FormProvider {...methods}>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(onModalSubmit)}
                noValidate
            >
                <Grid spacing={2}>
                    <GridItem xs={12} md={6}>
                        <BillingInfoForm
                            billingInformation={billingInformation}
                            handleStateSelectChange={handleStateSelectChange}
                            errors={errors}
                            handleChange={handleBillingInfoChange}
                            isPopupOpen={isPopupOpen}
                            isB2CUserAccount={
                                isB2CUserAccount && isSuccessfullyLoggedIn
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <>
                            {paymentMethod === PaymentMethodType.ACH && (
                                <BankAccountForm
                                    bankAccountInformation={
                                        bankAccountInformation
                                    }
                                    handleAccounTypeSelectChange={
                                        handleAccounTypeSelectChange
                                    }
                                    errors={errors}
                                    handleChange={
                                        handleBankAccountInformationChange
                                    }
                                    isPopupOpen={isPopupOpen}
                                />
                            )}
                            <InputField
                                label="Notes (optional)"
                                size="small"
                                textFieldSx={{
                                    width: '276px',
                                    mt:
                                        paymentMethod === PaymentMethodType.ACH
                                            ? '18px'
                                            : '46px',
                                }}
                                error={!!errors.notes}
                                helperText={
                                    errors?.notes &&
                                    errors?.notes?.message?.toString()
                                }
                                inputProps={{ maxLength: 256 }}
                                registerText="notes"
                                handleChange={handleNotesInputChange}
                                type={'text'}
                                multiline
                                rows={6}
                                disabled={isPopupOpen}
                            />
                        </>
                    </GridItem>
                    {isSuccessfullyLoggedIn && (
                        <GridItem xs={6} md={6}>
                            <>
                                <Typography
                                    variant="h6"
                                    sx={{ maxWidth: '500px', mt: '30px' }}
                                    text={'Save Payment Method'}
                                    color={'otherText.primary'}
                                />
                                <Checkbox
                                    checked={isSavePaymentMethodChecked}
                                    onCheckboxChange={
                                        handleSavePaymentMethodCheck
                                    }
                                    label={SAVE_METHOD_CHECKBOX_TEXT}
                                    sx={{ ml: '8px' }}
                                />
                                {isSavePaymentMethodChecked && (
                                    <InputField
                                        label="Nickname"
                                        size="small"
                                        textFieldSx={{
                                            width: '276px',
                                            mt: '16px',
                                        }}
                                        inputProps={{
                                            maxLength: 30,
                                        }}
                                        registerText="nickname"
                                        handleChange={handleNicknameInputChange}
                                        type={'text'}
                                        disabled={isPopupOpen}
                                    />
                                )}
                            </>
                        </GridItem>
                    )}
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: '40px', mb: '49px' }}
                    title="Proceed to payment"
                    disabled={disable || isRefreshLoading || isPopupOpen}
                    startIcon={
                        isRefreshLoading && (
                            <Spinner color="inherit" size={'20px'} />
                        )
                    }
                />
            </form>
        </FormProvider>
    );
}

export default forwardRef<FormRef, CreditCardFormProps>(FormSection);
