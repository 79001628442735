export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key;
          }
        : {
              type: Key;
              payload: M[Key];
          };
};

export type ErrorPage = {
    code: string;
    description: string;
    message?: string;
    buttonTitle?: string;
    route?: string;
    redirect?: (route: string) => void;
};

export enum AmountErrorType {
    NONE,
    EMPTY,
    EXCEED,
    EXCEED_PENDING,
}

export enum PaymentMethodType {
    ACH = 'ACH',
    CreditCard = 'CreditCard',
}

export type B2CConstants = {
    authorityPath: string;
    authoritySignIn: string;
    authoritySignUp: string;
    authorityChangePassword: string;
    scopes: string[];
    keyToken: string;
};

export enum PatchPaymentMethodPath {
    friendlyName = '/friendlyname',
    primary = '/isDefault',
    firstName = '/billingInformation/firstName',
    lastName = '/billingInformation/lastName',
    address = '/billingInformation/address',
    city = '/billingInformation/city',
    state = '/billingInformation/state',
    zipCode = '/billingInformation/postalCode',
}

export type PaymentHistoryFields =
    | 'paymentDate'
    | 'accountType'
    | 'referenceNumber'
    | 'customerName'
    | 'currentStatus';

export enum HISTORY_POPOVER_TYPES {
    reference = 'referenceNumber',
    invoice = 'appliedInvoices',
}

export enum EventStatus {
    Deleted = 'Deleted',
    Approved = 'Approved',
    TimedOut = 'TimedOut',
}

export enum HostedTransactionType {
    Payment = 'Payment',
    PaymentMethod = 'PaymentMethod',
}
