import { PaymentMethodType } from '../../../models/App.types';
import { ActionTypes } from './ActionTypes';
import { AddMethodActions, AddMethodData } from './AddMethodContext.types';

export const State: AddMethodData = {
    paymentMethodType: PaymentMethodType.ACH,
    savedPaymentMethodDetails: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        nickname: '',
        isPrimary: false,
    },
    bankAccountInformation: {
        routingNumber: '',
        accountNumber: '',
        accountType: '',
    },
    companies: [],
    companyNumber: 0,
    isCreditCardModalOpen: false,
    isCreditCardLoading: false,
    creditCardTransactionSettings: {
        hostedTransactionId: '',
        hostedTransactionUrl: '',
    },
    hasEventSourceFailed: false,
};

export function Reducer(
    draft: AddMethodData,
    action: AddMethodActions
): AddMethodData {
    const { type } = action;
    switch (type) {
        case ActionTypes.SetPaymentMethodType:
            draft.paymentMethodType = action.payload;
            break;
        case ActionTypes.SetPaymentMethodDetails:
            draft.savedPaymentMethodDetails = action.payload;
            break;
        case ActionTypes.SetCompanies:
            draft.companies = action.payload;
            break;
        case ActionTypes.SetCompanyNumber:
            draft.companyNumber = action.payload;
            break;
        case ActionTypes.SetBankAccountInformation:
            draft.bankAccountInformation = action.payload;
            break;
        case ActionTypes.SetIsCreditCardModalOpen:
            draft.isCreditCardModalOpen = action.payload;
            draft.isCreditCardLoading = action.payload;
            break;
        case ActionTypes.SetCreditCardTransactionSettings:
            draft.creditCardTransactionSettings = action.payload;
            draft.isCreditCardLoading = false;
            break;
        case ActionTypes.SetHasEventSourceFailed:
            draft.hasEventSourceFailed = action.payload;
            break;
        case ActionTypes.SetStreamEventStatus:
            draft.streamEventStatus = action.payload;
            break;
        case ActionTypes.SetCreditCardModalReset:
            draft.isCreditCardModalOpen = false;
            draft.isCreditCardLoading = false;
            draft.streamEventStatus = undefined;
            draft.creditCardTransactionSettings = {
                hostedTransactionId: '',
                hostedTransactionUrl: '',
            };
            draft.hasEventSourceFailed = false;
            break;
    }

    return draft;
}
