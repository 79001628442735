import {
    RadioGroup as MUIRadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { RadioGroupProps } from './RadioGroup.types';

export default function RadioGroup(props: RadioGroupProps) {
    const { value, items, onRadioChange, disabled, sx } = props;
    return (
        <MUIRadioGroup value={value} onChange={onRadioChange} sx={sx}>
            {items.map((item) => (
                <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    disabled={disabled}
                />
            ))}
        </MUIRadioGroup>
    );
}
