import styled from '@emotion/styled';

export const StyledFooterBox = styled.div`
    height: 100px;
    position: relative;
    margin: auto;
`;

export const StyledFooter = styled.div`
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const StyledLogo = styled.img`
    max-height: 40px;
`;

export const StyledMain = styled.div`
    min-height: calc(100vh - 164px);
`;
