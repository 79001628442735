import React from 'react';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import GridItem from '../../../components/Grid/GridItem';
import { TransactionData } from '../../../models/Transaction.interfaces';
import { SummaryHelper } from '../../../helpers/SummaryHelper';
import { useApplication } from '../../../context/ApplicationContext';
import { StyledDivider } from '../views/styling';
import { DOLLAR_FORMAT } from '../../../helpers/Constants';
import { PaymentMethodType } from '../../../models/App.types';

export default function Receipt(props: TransactionData) {
    const [companyState] = useApplication();
    const Company = companyState.Company;

    const paymentMethod = SummaryHelper.formatPaymentMethod(
        props.type,
        props.paymentInformation
    );

    const totalAmount = SummaryHelper.formatTotalAmount(props.amount);

    const paymentDate = SummaryHelper.formatPaymentDate(
        props.paymentInformation.paymentDate,
        props.type === PaymentMethodType.ACH || props.isSavedMethod
    );

    const headerDate = SummaryHelper.formatHeaderDate(paymentDate);

    const processedDate = SummaryHelper.formatProcessedDate(paymentDate);

    return (
        <>
            <Typography
                variant="body1"
                text={`Receipt from ${Company.name}`}
                sx={{ fontWeight: 'bold', pb: 1 }}
                color="text.primary"
            />
            <Typography variant={'h5'} text={totalAmount} sx={{ pb: 1 }} />
            <Typography
                variant="body1"
                text={'Paid ' + headerDate}
                sx={{ fontWeight: 'bold', pb: 7 }}
                color="text.primary"
            />
            <Grid
                spacing={2}
                columns={30}
                justify="space-between"
                rowSpacing={0.5}
            >
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text="Reference Number"
                        sx={{ pb: 1 }}
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={20}>
                    <Typography
                        variant="body1"
                        text={props.paymentInformation.referenceNumber}
                        align="right"
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text="Payment Method"
                        sx={{ pb: 1 }}
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={20}>
                    <Typography
                        variant="body1"
                        text={paymentMethod}
                        align="right"
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text="Submitted"
                        sx={{ pb: 1 }}
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={20}>
                    <Typography
                        variant="body1"
                        text={processedDate}
                        align="right"
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text="Customer Account"
                        sx={{ pb: 1 }}
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={20}>
                    <Typography
                        variant="body1"
                        text={props.customerAccount}
                        align="right"
                        sx={{ pb: 4 }}
                        color="text.primary"
                    />
                </GridItem>
            </Grid>
            <StyledDivider />
            {!SummaryHelper.isNullOrWhitespace(props.notes) && (
                <>
                    <Typography
                        variant="body1"
                        text="Notes:"
                        sx={{ pb: 2, pt: 1, fontWeight: 'bold' }}
                        color="text.primary"
                    />
                    <Typography
                        variant="body1"
                        text={props.notes}
                        align="left"
                        sx={{ pb: 4 }}
                        color="text.primary"
                    />
                </>
            )}
            <Typography
                variant="body1"
                text={
                    props.invoices.length === 1
                        ? 'Invoice Paid'
                        : 'Invoices Paid'
                }
                sx={{ pb: 2, pt: 1, fontWeight: 'bold' }}
                color="text.primary"
            />
            <Grid
                spacing={2}
                columns={20}
                justify="space-between"
                rowSpacing={0.5}
            >
                {props.invoices.map((invoice) => (
                    <React.Fragment key={invoice.invoiceNumber}>
                        <GridItem xs={10}>
                            <Typography
                                variant="body1"
                                text={invoice.invoiceNumber}
                                sx={{ pb: 1 }}
                                color="text.primary"
                            />
                        </GridItem>
                        <GridItem xs={10}>
                            <Typography
                                variant="body1"
                                text={
                                    '$' +
                                    invoice.amount
                                        .toFixed(2)
                                        .replace(DOLLAR_FORMAT, ',')
                                }
                                align="right"
                                sx={{ pb: 1 }}
                                color="text.primary"
                            />
                        </GridItem>
                    </React.Fragment>
                ))}
            </Grid>
            <Grid
                spacing={2}
                columns={20}
                justify="space-between"
                rowSpacing={0.5}
            >
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text="Total"
                        sx={{ pt: 4, fontWeight: 'bold' }}
                        color="text.primary"
                    />
                </GridItem>
                <GridItem xs={10}>
                    <Typography
                        variant="body1"
                        text={totalAmount}
                        sx={{ pt: 4, pb: 4, fontWeight: 'bold' }}
                        align="right"
                        color="text.primary"
                    />
                </GridItem>
            </Grid>
        </>
    );
}
