import { useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import Typography from '../../../../components/Typography/Typography';
import Button from '../../../../components/Button/Button';
import Box from '../../../../components/box/Box';
import { PAY_METHODS_COLUMNS } from '../../../../helpers/TableColumns';
import AddNoteModal from '../../components/addNoteModal/AddNoteModal';
import { usePayments } from '../../context/PaymentsContext';
import theme from '../../../../Theme';
import { MethodTableViewProps } from './MethodTableView.types';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

export default function MethodTableView(props: MethodTableViewProps) {
    const { savedPaymentMethods, onAddMethodClick } = props;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [state, actions] = usePayments();

    const { notes } = state;

    return (
        <>
            <Typography
                variant="h5"
                text={'Payment Method'}
                color={'otherText.primary'}
            />
            <DataGrid
                rows={savedPaymentMethods}
                columns={PAY_METHODS_COLUMNS}
                disableColumnMenu
                hideFooterSelectedRowCount
                hideFooter
                sx={{
                    border: 0,
                    borderRadius: 0,
                    borderColor: 'divider',
                    minHeight: '100px',
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: theme.palette.dark.main,
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'isDefault', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            isDefault: false,
                        },
                    },
                }}
                autoHeight={savedPaymentMethods.length > 0}
            />
            <Box sx={{ pb: 4, mt: 2, position: 'relative' }}>
                <Button
                    variant="contained"
                    title="Add Card/Account"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    sx={{ mt: 2 }}
                    onClick={onAddMethodClick}
                />
                <Box sx={{ position: 'absolute', right: '0px', top: '0px' }}>
                    <Button
                        title={notes === '' ? 'Add note' : 'Edit note'}
                        variant="outlined"
                        sx={{ mb: 2, mt: 2 }}
                        startIcon={
                            notes === '' ? (
                                <AddCircleOutlineOutlinedIcon />
                            ) : (
                                <EditIcon />
                            )
                        }
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    />
                </Box>
            </Box>
            <AddNoteModal
                openNotesModal={openModal}
                setOpenNotesModal={setOpenModal}
                note={notes}
                setNotes={actions.onSetNotes}
            />
        </>
    );
}
