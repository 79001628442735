import { forwardRef } from 'react';
import NumberFormat, {
    InputAttributes,
    NumberFormatValues,
} from 'react-number-format';

interface CustomProps {
    onChange: (event: {
        target: { name: string; value: number | undefined };
    }) => void;
    isAllowed: (value: NumberFormatValues) => boolean;
    name: string;
}

export const InvoiceFormatCustom: any = forwardRef<
    NumberFormat<InputAttributes>,
    CustomProps
>(function InvoiceFormatCustom(props, ref) {
    const { onChange, isAllowed, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            allowNegative={false}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={true}
            isAllowed={isAllowed}
        />
    );
});

interface NumberCustomProps {
    onChange: (event: {
        target: { name: string; value: string | undefined };
    }) => void;
    name: string;
    format: string | undefined;
    limit: number;
}

export const NumberFormatCustom: any = forwardRef<
    NumberFormat<InputAttributes>,
    NumberCustomProps
>(function NumberFormatCustom(props, ref) {
    const { onChange, format, limit, ...other } = props;
    const isAllowed = ({ value }: NumberFormatValues) => value.length <= limit;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={format}
            allowNegative={false}
            isAllowed={isAllowed}
            allowLeadingZeros
        />
    );
});

interface AmountProps {
    text: string | number | null | undefined;
}

export function AmountInvoicesFormat(props: AmountProps) {
    return (
        <NumberFormat
            value={props.text}
            displayType="text"
            thousandSeparator
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
}
