import {
    B2C_APPLICATION_ID,
    B2C_READ_PERMISSION,
    B2C_REDIRECT_URL,
    B2C_TENANT_NAME,
    B2C_USERFLOW_SIGNIN,
    B2C_USERFLOW_SIGNUP,
    B2C_USERFLOW_CHANGEPASSWORD,
    B2C_WRITE_PERMISSION,
} from '../config';
import { B2CConstants } from '../models/App.types';

/* eslint-disable max-len */
const AUTHORITY_PATH: string = `https://${B2C_TENANT_NAME}.b2clogin.com/${B2C_TENANT_NAME}.onmicrosoft.com`;

export const B2C_CONSTANTS: B2CConstants = {
    authorityPath: AUTHORITY_PATH,
    authoritySignIn: `${AUTHORITY_PATH}/${B2C_USERFLOW_SIGNIN}`,
    authoritySignUp: `${AUTHORITY_PATH}/${B2C_USERFLOW_SIGNUP}`,
    authorityChangePassword: `${AUTHORITY_PATH}/${B2C_USERFLOW_CHANGEPASSWORD}`,
    scopes: [B2C_READ_PERMISSION, B2C_WRITE_PERMISSION],
    keyToken: `msal.token.keys.${B2C_APPLICATION_ID}`, // Key name to get token info from session Storage b2c generated
};

export const b2CConfig = {
    auth: {
        clientId: B2C_APPLICATION_ID, // (Application ID) This is the ONLY mandatory field that you need to supply.
        knownAuthorities: [`${B2C_TENANT_NAME}.b2clogin.com`], // Mark your B2C tenant's domain as trusted.
        redirectUri: B2C_REDIRECT_URL + 'error/error/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
        postLogoutRedirectUri: B2C_REDIRECT_URL + 'error/error/',
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
};
