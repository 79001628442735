import styled from '@emotion/styled';

export const StyledSelected = styled.div`
    background-color: #e7f4fb;
    border-radius: 5px 5px 0px 0px;
    font-size: 16px;
    width: 99.85%;
    height: 60px;
    margin-bottom: -5px;
    border-top: #e1e1e1 1px solid;
    border-left: #e1e1e1 1px solid;
    border-right: #e1e1e1 1px solid;
    border-radius: 5px;
`;

export const StyledEmpty = styled.div`
    background-color: #ffffff;
    border-radius: 5px 5px 0px 0px;
    font-size: 23px;
    width: 99.85%;
    height: 60px;
    margin-bottom: -5px;
    border-top: #e1e1e1 1px solid;
    border-left: #e1e1e1 1px solid;
    border-right: #e1e1e1 1px solid;
    border-radius: 5px;
`;

export const StyledHeaderText = styled.div`
    padding-left: 15px;
    padding-top: 15px;
`;
