import { useState } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { LOGOUT_TIME_MINUTES } from '../config';

export default function useIdleTimeout(
    onIdle: (event?: Event, idleTimer?: IIdleTimer) => void
) {
    const idleTimeout = 1000 * 60 * LOGOUT_TIME_MINUTES;

    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true);
        onIdle();
    };

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle: handleIdle,
        debounce: 500,
    });

    return {
        isIdle,
        setIdle,
        idleTimer,
    };
}
