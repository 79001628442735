export enum ActionsTypes {
    AddCompanyData = 'application.company.information',
    AddUserData = 'application.user.information',
    UpdateIsPopupOpen = 'application.isPopupOpen.update',
    UpdateIsSuccessfulSignup = 'application.isSuccessfulSignup.update',
    UpdateIsUserSignedIn = 'application.isUserSignedIn.update',
    UpdateCompanyData = 'application.company.information.update',
    UpdateUserData = 'application.user.information.update',
    UpdateOriginalAccount = 'application.user.originalAccount.update',
    Error = 'application.error',
}
