import { useRef, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import { PAYMENT_METHOD_TYPES } from '../../../helpers/Constants';
import UsersActions from '../../../hooks/UsersActions';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMsal } from '@azure/msal-react';
import { useAddMethod } from '../context/AddMethodContext';
import FormSection from '../components/formSection/FormSection';
import AchDetailsModal from '../components/achDetailsModal/AchDetailsModal';
// eslint-disable-next-line max-len
import PaymentMethodSection from '../../Payments/components/paymentMethodSection/PaymentMethodSection';
import { FormRef } from '../../Payments/components/formSection/FormSection.types';
import Box from '../../../components/box/Box';
import Select from '../../../components/Select/Select';
import { Company, Wrapper } from '../../../models/App.interfaces';
import { StyledPaperTop } from './styling';
import { errorActions } from '../../../helpers/ErrorActions';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import {
    EventStatus,
    HostedTransactionType,
    PaymentMethodType,
} from '../../../models/App.types';
import { useApplication } from '../../../context/ApplicationContext';
import { CreditCardPaymentMethodPost } from '../../../models/Transaction.interfaces';
import { CreateCreditCardMethodPost } from '../../../helpers/Utilities';
import PaymentActions from '../../../hooks/PaymentsActions';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import MetadataConstants from '../../../constants/MetadataConstants';
import ScreenConstants from '../../../constants/ScreenConstants';
import Metadata from '../../../components/metadata/Metadata';
import CreditCardMethodModal from '../../../components/creditCardMethodModal/CreditCardMethodModal';
import RedirectConstants from '../../../constants/RedirectConstants';

export default function AddMethodView() {
    const [state, actions] = useAddMethod();
    const [applicationState] = useApplication();
    const formRef = useRef<FormRef>(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { instance } = useMsal();
    const [openAchDetailsModal, setOpenAchDetailsModal] =
        useState<boolean>(false);

    const { Company } = applicationState;
    const {
        companyNumber,
        savedPaymentMethodDetails,
        bankAccountInformation,
        isCreditCardModalOpen,
        isCreditCardLoading,
        hasEventSourceFailed,
        creditCardTransactionSettings,
        streamEventStatus,
        paymentMethodType,
        companies,
    } = state;

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const handleCompanySuccess = (data: Wrapper<Company>) => {
        actions.onSetCompanies(data);
        actions.onSetCompanyNumber(data.data[0].companyNumber);
    };

    const { isFetching: companiesFetching } =
        UsersActions.useGetCompaniesbyUser(
            (error) =>
                errorActions.defaultError(
                    error,
                    navigate,
                    enqueueSnackbar,
                    instance
                ),
            handleCompanySuccess
        );

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueType: string = (event.target as HTMLInputElement).value;
        actions.onSetPaymentMethodType(
            PaymentMethodType[valueType as keyof typeof PaymentMethodType]
        );
    };

    // POST CC payment method
    const postCCPaymentMethod = UsersActions.usePostCreditCardPaymentMethod(
        (error) =>
            errorActions.creditCardMethodError(
                error,
                navigate,
                enqueueSnackbar,
                instance,
                actions.onSetIsCreditCardModalOpen,
                Company.contactInformation
            )
    );

    //initialize event stream; when first message is received, closes stream
    const setupEventStream = (hostedTransactionId: string) => {
        const eventSource = PaymentActions.setEventSource(
            hostedTransactionId,
            HostedTransactionType.PaymentMethod
        );
        eventSource.addEventListener('message', handleEvent);
        eventSource.onmessage = () => {
            eventSource.close();
        };
        eventSource.onerror = () => {
            actions.onSetHasEventSourceFailed(true);
            eventSource.close();
        };
    };

    const handleEvent = (event: any) => {
        const parsedData = JSON.parse(event.data);
        const status = parsedData.status;
        actions.onSetStreamEventStatus(
            EventStatus[status as keyof typeof EventStatus]
        );

        if (status === EventStatus.TimedOut) {
            actions.onCreditCardModalReset();
        }
    };

    const onSubmitCreditCard = async () => {
        actions.onSetIsCreditCardModalOpen(true);
        const creditCardMethod: CreditCardPaymentMethodPost =
            CreateCreditCardMethodPost(
                companyNumber,
                savedPaymentMethodDetails,
                TokenHelper.getUserInformation().emailAddress
            );

        const response = await postCCPaymentMethod.mutateAsync(
            creditCardMethod
        );
        actions.onSetCreditCardTransactionSettings(response.data);
        setupEventStream(response.data.hostedTransactionId);
    };

    const onSubmit = () => {
        if (paymentMethodType === PaymentMethodType.ACH) {
            setOpenAchDetailsModal(true);
        } else {
            onSubmitCreditCard();
        }
    };

    const handleCompanyChange = (event: any) => {
        actions.onSetCompanyNumber(parseInt(event.target.value));
    };

    const handleCancel = () => {
        navigate('/' + RedirectConstants.PAYMENT_METHODS_PAGE);
    };

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.ADD_METHOD_TAB_TITLE}
                description={MetadataConstants.ADD_METHOD_DESCRIPTION}
                noIndex
            />
            <Box
                sx={{
                    width: '900px',
                    pt: '70px',
                    pb: '35px',
                    margin: 'auto',
                }}
            >
                <Box>
                    <StyledPaperTop>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    color={'otherText.primary'}
                                >
                                    {ScreenConstants.ADD_PAYMENT_METHOD_LABEL}
                                </Typography>
                                <PaymentMethodSection
                                    paymentMethod={paymentMethodType}
                                    paymentMethodList={PAYMENT_METHOD_TYPES}
                                    onRadioChange={handleRadioChange}
                                    isAddPayment
                                />
                            </Box>
                            <Box>
                                <Select
                                    native
                                    sx={{
                                        width: '300px',
                                        bgcolor: 'background.paper',
                                        mb: 4,
                                    }}
                                    label="Company"
                                    labelId="company"
                                    id="company"
                                    value={String(companyNumber) || ''}
                                    onChange={handleCompanyChange}
                                    disabled={companies.length <= 1}
                                >
                                    {companies.map((company) => (
                                        <option
                                            key={company.companyNumber}
                                            value={company.companyNumber}
                                        >
                                            {company.companyName}
                                        </option>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    </StyledPaperTop>
                    <FormSection
                        ref={formRef}
                        savedPaymentMethodDetails={savedPaymentMethodDetails}
                        onSubmit={onSubmit}
                        setPaymentMethodDetails={
                            actions.onSetPaymentMethodDetails
                        }
                        paymentMethodType={paymentMethodType}
                        setBankAccountInformation={
                            actions.onSetBankAccountInformation
                        }
                        bankAccountInformation={bankAccountInformation}
                        onCancel={handleCancel}
                        disabled={companiesFetching}
                    />
                </Box>
                <AchDetailsModal
                    isOpenAchDetailsModal={openAchDetailsModal}
                    setIsOpenAchDetailsModal={setOpenAchDetailsModal}
                    nickname={savedPaymentMethodDetails.nickname}
                    bankAccountInformation={bankAccountInformation}
                    savedPaymentMethodDetails={savedPaymentMethodDetails}
                    companyNumber={companyNumber}
                    navigate={navigate}
                />
                <CreditCardMethodModal
                    isCreditCardModalOpen={isCreditCardModalOpen}
                    isCreditCardLoading={isCreditCardLoading}
                    hasEventSourceFailed={hasEventSourceFailed}
                    creditCardTransactionSettings={
                        creditCardTransactionSettings
                    }
                    streamEventStatus={streamEventStatus}
                    onCreditCardModalReset={actions.onCreditCardModalReset}
                    onSuccessClick={handleCancel}
                />
            </Box>
        </>
    );
}
