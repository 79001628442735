import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Component, ErrorInfo, ReactNode } from 'react';
import { applicationInsights } from './Insights';

interface Props {
    children?: ReactNode;
    onError?: JSX.Element;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false,
    };

    static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        applicationInsights.trackException(error, errorInfo);
    }

    componentDidMount() {
        window.onerror = (message, source, lineno, colno, error) => {
            this.setState({ hasError: true });
            applicationInsights.trackException({
                error: error,
                severityLevel: SeverityLevel.Error,
            });
        };
    }

    render() {
        if (this.state.hasError) {
            return this.props.onError;
        }

        return this.props.children;
    }
}
