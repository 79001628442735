import { ActionTypes } from './ActionTypes';
import { MethodsActions, MethodsData } from './MethodsContext.types';

export const State: MethodsData = {
    paymentMethods: [],
    isPrimaryPopupOpen: false,
    primaryMethodId: 0,
    companies: [],
    companyNumber: 0,
    isCreditCardLoading: false,
    creditCardTransactionSettings: {
        hostedTransactionId: '',
        hostedTransactionUrl: '',
    },
    hasEventSourceFailed: false,
};

export function Reducer(
    draft: MethodsData,
    action: MethodsActions
): MethodsData {
    const { type } = action;
    switch (type) {
        case ActionTypes.ListPaymentMethods:
            draft.paymentMethods = action.payload;
            break;
        case ActionTypes.DeletePaymentMethod:
            draft.paymentMethods = draft.paymentMethods.filter(
                (paymentMethod) =>
                    paymentMethod.id.toString() !== action.payload
            );
            break;
        case ActionTypes.UpdateIsPrimaryPopupOpen:
            draft.isPrimaryPopupOpen = action.payload;
            break;
        case ActionTypes.UpdatePrimaryMethodId:
            draft.primaryMethodId = action.payload;
            break;
        case ActionTypes.ListCompanies:
            draft.companies = action.payload;
            break;
        case ActionTypes.UpdateCompanyNumber:
            draft.companyNumber = action.payload;
            break;
        case ActionTypes.SetIsCreditCardLoading:
            draft.isCreditCardLoading = action.payload;
            break;
        case ActionTypes.SetCreditCardTransactionSettings:
            draft.creditCardTransactionSettings = action.payload;
            draft.isCreditCardLoading = false;
            break;
        case ActionTypes.SetHasEventSourceFailed:
            draft.hasEventSourceFailed = action.payload;
            break;
        case ActionTypes.SetStreamEventStatus:
            draft.streamEventStatus = action.payload;
            break;
        case ActionTypes.SetCreditCardModalReset:
            draft.isCreditCardLoading = false;
            draft.streamEventStatus = undefined;
            draft.creditCardTransactionSettings = {
                hostedTransactionId: '',
                hostedTransactionUrl: '',
            };
            draft.hasEventSourceFailed = false;
            break;
    }

    return draft;
}
