import styled from '@emotion/styled';

export const StyledModal = styled.div`
    @media (min-width: 600px) {
        min-height: 350px;
    }
    max-width: 1000px;
    min-height: 575px;
`;

export const StyledFrame = styled.iframe`
    @media (min-width: 600px) {
        min-height: 280px;
    }
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    border: none;
`;

export const StyledSpinner = styled.div`
    margin: 0;
    margin-left: -50px;
    margin-top: -50px;
    left: 50%;
    top: 50%;
    position: absolute;
`;

export const StyledExit = styled.div`
    right: -11px;
    top: -11px;
    position: absolute;
`;
