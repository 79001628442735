import NavbarView from '../../views/navbarView/NavbarView';
import { CustomerDataProvider } from './context/CustomersContext';
import CustomersView from './views/CustomersView';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

export function Customers() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <CustomerDataProvider>
                    <CustomersView />
                </CustomerDataProvider>
            </StyledMain>
            <LogoFooter />
        </>
    );
}
