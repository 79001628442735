import { useState, useEffect } from 'react';
import Box from '../../../components/box/Box';
import Button from '../../../components/Button/Button';
import Typography from '../../../components/Typography/Typography';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { EmailSignupResponse } from '../../../models/App.interfaces';
import {
    REGISTRATION_PROCESS_FAILURE,
    REGISTRATION_FAILURE,
    REGISTRATION_SUCCESS_TITLE_TEXT,
    PROCEED_TO_PORTAL_PAYMENT,
} from '../../../helpers/Constants';
import Metadata from '../../../components/metadata/Metadata';
import MetadataConstants from '../../../constants/MetadataConstants';

export default function EmailSuccessView() {
    const state: EmailSignupResponse = useLocation().state.hashValue;
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(true);

    useEffect(() => {
        if (
            (state.companyName === '' || state.idToken === '') &&
            state.error !== '' &&
            state.errorDescription !== ''
        ) {
            setSuccess(false);
        }
    }, []);

    const handleClick = () => {
        setLoading(true);
        location.pathname = state.companyName + '/login';
    };

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.EMAIL_RESPONSE_TAB_TITLE}
                description={MetadataConstants.EMAIL_RESPONSE_DESCRIPTION}
                noIndex
            />
            <Box sx={{ mt: 10, mr: 20, ml: 20 }}>
                <Typography
                    variant="h5"
                    color="otherText.primary"
                    sx={{ pb: 2, pt: 1 }}
                >
                    {success
                        ? REGISTRATION_SUCCESS_TITLE_TEXT
                        : REGISTRATION_FAILURE}
                </Typography>
                <div>
                    {success ? (
                        <Typography variant="body1">
                            Account successfully created, you are now logged in.
                            Click
                            <i> Sign in</i> next time you visit the portal to
                            access your account, use saved payment methods, view
                            your payment history, or make quicker transactions.
                        </Typography>
                    ) : (
                        <Typography variant="body1">
                            {REGISTRATION_PROCESS_FAILURE}
                        </Typography>
                    )}
                </div>
                <Button
                    variant="contained"
                    title={PROCEED_TO_PORTAL_PAYMENT}
                    onClick={handleClick}
                    sx={{
                        mt: 5,
                    }}
                    startIcon={
                        loading && (
                            <Spinner
                                color="inherit"
                                size={'30px'}
                                sx={{ ml: '8px' }}
                            />
                        )
                    }
                />
            </Box>
        </>
    );
}
