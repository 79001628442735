export enum ActionTypes {
    Summary = 'invoices.summary',
    CompanyNumber = 'invoices.companyNumber',
    Companies = 'invoices.selectList',
    Rows = 'invoices.rows',
    PageSize = 'invoices.pageSize',
    TotalPages = 'invoices.totalPages',
    RowCount = 'invoices.rowCount',
    CurrentPage = 'invoices.currentPage',
    SelectedIDs = 'invoices.selectedIDs',
    SelectedRows = 'invoices.selectedRows',
    SelectedCount = 'invoices.selectedCount',
    SelectedDollars = 'invoices.selectedDollars',
    CurrentSelected = 'invoices.currentSelected',
    OrderBy = 'invoices.orderBy',
    Ascending = 'invoices.ascending',
    CustomerName = 'invoices.customerName',
    UpdateCustomerName = 'invoices.udpateCustomerName',
    HeaderText = 'invoices.headerText',
    ResetGrid = 'invoices.resetGrid',
}
