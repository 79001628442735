import { ActionTypes } from './ActionTypes';
import { InvoiceActions, InvoiceData } from './InvoicesContext.types';

export const State: InvoiceData = {
    Summary: {
        invoiceCount: 0,
        remainingDueBalance: 0,
        pastDueBalance: 0,
        pendingPayments: 0,
    },
    CompanyNumber: 0,
    Companies: [],
    Rows: [],
    PageSize: 10,
    TotalPages: 0,
    RowCount: 0,
    CurrentPage: 1,
    SelectedIDs: [],
    SelectedRows: [
        {
            invoiceNumber: 0,
            date: '',
            pastDueDate: '',
            originalAmount: 0,
            remainingDue: 0,
            serviceLocationName: '',
            pendingAmount: 0,
        },
    ],
    SelectedCount: 0,
    SelectedDollars: 0,
    CurrentSelected: [
        {
            invoiceNumber: 0,
            date: '',
            pastDueDate: '',
            originalAmount: 0,
            remainingDue: 0,
            serviceLocationName: '',
            pendingAmount: 0,
        },
    ],
    OrderBy: 'pastDueDate',
    Ascending: true,
    CustomerName: '',
    HeaderText: '',
    allowPending: false,
};

export function Reducer(
    draft: InvoiceData,
    action: InvoiceActions
): InvoiceData {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.Summary:
            draft.Summary = payload;
            draft.allowPending = draft.Summary.pendingPayments > 0;
            break;
        case ActionTypes.CompanyNumber:
            draft.CompanyNumber = payload;
            break;
        case ActionTypes.Companies:
            draft.Companies = payload;
            break;
        case ActionTypes.Rows:
            draft.Rows = payload;
            break;
        case ActionTypes.PageSize:
            draft.PageSize = payload;
            break;
        case ActionTypes.TotalPages:
            draft.TotalPages = payload;
            break;
        case ActionTypes.RowCount:
            draft.RowCount = payload;
            break;
        case ActionTypes.CurrentPage:
            draft.CurrentPage = payload;
            break;
        case ActionTypes.SelectedIDs:
            draft.SelectedIDs = payload;
            break;
        case ActionTypes.SelectedRows:
            draft.SelectedRows = payload;
            break;
        case ActionTypes.SelectedCount:
            draft.SelectedCount = payload;
            break;
        case ActionTypes.SelectedDollars:
            draft.SelectedDollars = payload;
            break;
        case ActionTypes.CurrentSelected:
            draft.CurrentSelected = payload;
            break;
        case ActionTypes.OrderBy:
            draft.OrderBy = payload;
            break;
        case ActionTypes.Ascending:
            draft.Ascending = payload;
            break;
        case ActionTypes.CustomerName:
            draft.CustomerName = payload;
            break;
        case ActionTypes.UpdateCustomerName:
            draft.CustomerName = payload;
            break;
        case ActionTypes.HeaderText:
            draft.HeaderText = payload;
            break;
    }

    return draft;
}
