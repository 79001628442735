import { ConfirmationCellProps } from './ConfirmationCell.types';

export default function ConfirmationCell(props: ConfirmationCellProps) {
    const { params, textColor } = props;
    const { row } = params;
    const confirmationNumber = `${row.referenceNumber.substr(0, 5)}...`;

    return (
        <>
            <span style={{ color: textColor, fontWeight: 'bold' }}>
                {confirmationNumber}
            </span>
        </>
    );
}
