import { useState } from 'react';
import InputField from '../../../../components/InputField/InputField';
import FormSelect from '../../../../components/formSelect/FormSelect';
import Typography from '../../../../components/Typography/Typography';
import Layout from '../../../../components/layout/Layout';
import { BankAccountFormProps, MaskStatus } from './BankAccountForm.types';
import { ACCOUNT_TYPES } from '../../../../helpers/Constants';
import { NumberFormatCustom } from '../../../../helpers/FormatNumbers';

export default function BankAccountForm(props: BankAccountFormProps) {
    const {
        bankAccountInformation,
        errors,
        handleChange,
        handleAccounTypeSelectChange,
        isPopupOpen,
    } = props;

    const [maskStatus, setMaskStatus] = useState<MaskStatus>({
        isRoutingNumberMasked: true,
        isAccountNumberMasked: true,
        isAccountNumberConfirmMasked: true,
    });
    const {
        isRoutingNumberMasked,
        isAccountNumberMasked,
        isAccountNumberConfirmMasked,
    } = maskStatus;

    return (
        <>
            <Typography
                variant="h5"
                text={'Bank Account Info'}
                color={'otherText.primary'}
            />
            <Layout
                alignItems="left"
                direction="column"
                topPadding={2}
                spacing={2}
            >
                <InputField
                    label="Routing Number"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.routingNumber}
                    helperText={
                        errors.routingNumber && errors?.routingNumber.message
                    }
                    registerText="routingNumber"
                    handleChange={handleChange}
                    type={'text'}
                    password={{
                        isValueMasked: isRoutingNumberMasked,
                        onClick: () =>
                            setMaskStatus({
                                ...maskStatus,
                                isRoutingNumberMasked: !isRoutingNumberMasked,
                            }),
                    }}
                    inputProps={{
                        limit: 9,
                    }}
                    inputComponent={NumberFormatCustom}
                    disabled={isPopupOpen}
                />
                <InputField
                    label="Account Number"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.accountNumber}
                    helperText={
                        errors.accountNumber && errors?.accountNumber.message
                    }
                    registerText="accountNumber"
                    handleChange={handleChange}
                    type={'text'}
                    inputProps={{
                        limit: 17,
                    }}
                    inputComponent={NumberFormatCustom}
                    password={{
                        isValueMasked: isAccountNumberMasked,
                        onClick: () =>
                            setMaskStatus({
                                ...maskStatus,
                                isAccountNumberMasked: !isAccountNumberMasked,
                            }),
                    }}
                    disabled={isPopupOpen}
                />
                <InputField
                    label="Confirm Account Number"
                    size="small"
                    textFieldSx={{ width: '276px' }}
                    error={!!errors.accountNumberConfirm}
                    helperText={
                        errors.accountNumberConfirm &&
                        errors?.accountNumberConfirm.message
                    }
                    registerText="accountNumberConfirm"
                    handleChange={handleChange}
                    type={'text'}
                    inputProps={{
                        limit: 17,
                    }}
                    inputComponent={NumberFormatCustom}
                    password={{
                        isValueMasked: isAccountNumberConfirmMasked,
                        onClick: () =>
                            setMaskStatus({
                                ...maskStatus,
                                isAccountNumberConfirmMasked:
                                    !isAccountNumberConfirmMasked,
                            }),
                    }}
                    disabled={isPopupOpen}
                />
                <FormSelect
                    label="Account Type"
                    registerText="accountType"
                    items={ACCOUNT_TYPES}
                    value={bankAccountInformation.accountType}
                    onChange={handleAccounTypeSelectChange}
                    size="small"
                    error={!!errors.accountType}
                    helperText={
                        errors.accountType && errors?.accountType.message
                    }
                    sx={{ width: '276px' }}
                    select
                    selectProps={{
                        MenuProps: {
                            PaperProps: { sx: { maxHeight: '200px' } },
                        },
                    }}
                    disabled={isPopupOpen}
                />
            </Layout>
        </>
    );
}
