import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import Footer from '../../../components/Footer/TextFooter/Footer';
import { useApplication } from '../../../context/ApplicationContext';
import { printReceipt } from '../../../helpers/PrintSettings';
import Print from '@mui/icons-material/Print';
import Download from '@mui/icons-material/Download';
import ArrowBack from '@mui/icons-material/ArrowBack';
import IosShareIcon from '@mui/icons-material/IosShare';
import { StyledBox, StyledMain, StyledHeader, StyledReceipt } from './styling';
import Receipt from '../components/Receipt';
import ReactToPrint from 'react-to-print';
import { useNavigate, useLocation } from 'react-router-dom';
import { PRINT_MARGINS } from '../../../helpers/Constants';
import { TransactionData } from '../../../models/Transaction.interfaces';
import { SummaryHelper } from '../../../helpers/SummaryHelper';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import { useSnackbar } from 'notistack';
import { useMsal } from '@azure/msal-react';
import { PaymentMethodType } from '../../../models/App.types';
import Box from '../../../components/box/Box';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import MetadataConstants from '../../../constants/MetadataConstants';
import Metadata from '../../../components/metadata/Metadata';
import { FormatString } from '../../../helpers/Utilities';

export interface Data {
    transactionData: TransactionData;
}

export default function SummaryView() {
    const state = useLocation().state as Data;
    const [applicationState] = useApplication();
    const { Company, isPopupOpen } = applicationState;
    const { instance } = useMsal();

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const getDate = () => {
        const date = new Date(
            state.transactionData.paymentInformation.paymentDate +
                (state.transactionData.type === PaymentMethodType.ACH ||
                state.transactionData.isSavedMethod
                    ? ''
                    : ' UTC')
        );
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const saveDate = `${
            month + 1
        }${day}${year}_${hours}${minutes}${seconds}`;
        return saveDate;
    };

    getDate();

    const formattedEmail = SummaryHelper.formatEmail(
        state.transactionData,
        Company.name,
        Company.contactInformation
    );

    let componentToPrint: HTMLDivElement | null;

    //download settings
    const handleDownload = () => {
        if (componentToPrint !== null) {
            const date = getDate();
            const filename = `${state.transactionData.customerAccount}_${date}.pdf`;
            printReceipt(componentToPrint, filename);
        }
    };

    const handleShare = () => {
        // eslint-disable-next-line max-len
        window.open(
            `mailto:?subject=${formattedEmail.subject}&body=${formattedEmail.body}`
        );
    };

    const handleReturn = () => {
        navigate('/invoices');
    };

    const metaDescription = FormatString(
        MetadataConstants.SUMMARY_DESCRIPTION,
        Company.name
    );

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.SUMMARY_TAB_TITLE}
                description={metaDescription}
                noIndex
            />
            <Box sx={{ minWidth: '900px' }}>
                <StyledBox>
                    <Typography
                        variant="body1"
                        // eslint-disable-next-line max-len
                        text={`Success! This has also been emailed to ${state.transactionData.email}`}
                        align="center"
                        sx={{ fontWeight: 'bold', pb: 4 }}
                        color="text.primary"
                    />
                    <StyledMain>
                        <StyledHeader>
                            <Button
                                title="Download"
                                variant="text"
                                sx={{ mb: 4, mr: 2, fontWeight: 'bold' }}
                                onClick={handleDownload}
                                startIcon={<Download />}
                                color="dark"
                                disabled={isPopupOpen}
                            />
                            <ReactToPrint
                                trigger={() => (
                                    <Button
                                        title="Print"
                                        variant="text"
                                        sx={{
                                            mb: 4,
                                            mr: 2,
                                            fontWeight: 'bold',
                                        }}
                                        color="dark"
                                        startIcon={<Print />}
                                        disabled={isPopupOpen}
                                    />
                                )}
                                content={() => componentToPrint}
                            />
                            <Button
                                title="Share"
                                variant="text"
                                sx={{ mb: 4, mr: 2, fontWeight: 'bold' }}
                                onClick={handleShare}
                                startIcon={<IosShareIcon />}
                                color="dark"
                                disabled={isPopupOpen}
                            />
                        </StyledHeader>
                        <StyledReceipt
                            id="receipt"
                            ref={(receipt) => (componentToPrint = receipt)}
                        >
                            <Receipt {...state.transactionData} />
                            <style>{PRINT_MARGINS}</style>
                        </StyledReceipt>
                    </StyledMain>
                    <Button
                        variant="text"
                        title="Return to Invoices"
                        startIcon={<ArrowBack />}
                        sx={{
                            mt: 4,
                            mb: 4,
                            fontWeight: 'bold',
                        }}
                        color="dark"
                        onClick={handleReturn}
                        disabled={isPopupOpen}
                    />
                    <Footer />
                </StyledBox>
            </Box>
        </>
    );
}
