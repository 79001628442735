import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button/Button';
import Box from '../../../../components/box/Box';
import Typography from '../../../../components/Typography/Typography';
import { useCustomers } from '../../context/CustomersContext';
import { useNavigate } from 'react-router-dom';
import { CustomerSuccessModalProps } from './CustomerSuccessModal.types';
import { TokenData } from '../../../../models/App.interfaces';
import { TokenHelper } from '../../../../helpers/TokenHelper';

export default function CustomerSuccessModal(props: CustomerSuccessModalProps) {
    const { customerNumber, setCustomerNumber } = props;
    const [state, customersActions] = useCustomers();
    const navigate = useNavigate();

    const handleClose = () => {
        customersActions.onUpdateIsCustomerSuccessOpen(false);
    };

    const handleProceed = () => {
        customersActions.onUpdateIsCustomerSuccessOpen(false);
        let tokenData: TokenData = TokenHelper.getInitialData();
        const customer = state.customers.find(
            (customer) => customer.customerNumber === customerNumber
        );

        setCustomerNumber('');
        tokenData.customerName = customer?.customerName || '';
        tokenData.customerNumber = customer?.customerNumber || '';
        tokenData.allowCreditCard = customer?.allowCreditCard || false;
        tokenData.allowACH = customer?.allowACH || false;
        TokenHelper.saveInitialData(tokenData);
        navigate('/invoices');
    };

    return (
        <>
            <Modal open={state.isCustomerSuccessOpen} maxWidth="xs" fullWidth>
                <Box>
                    <Typography variant="h6" color="otherText.primary">
                        <b>Customer Successfully Added!</b>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mt: 4 }}
                        color="otherText.primary"
                    >
                        You can now view and pay invoices for this customer,
                        which can be accessed via the <i>Customer Select</i>{' '}
                        page.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '20%', mt: 4 }}>
                        <Button
                            title="Close"
                            variant="outlined"
                            sx={{ maxWidth: '25%' }}
                            fullWidth
                            onClick={handleClose}
                        />
                        <Button
                            title="Proceed to Invoices"
                            variant="contained"
                            sx={{ maxWidth: '55%' }}
                            fullWidth
                            onClick={handleProceed}
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
