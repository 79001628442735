export enum ActionTypes {
    AddInvoices = 'payments.invoices.add',
    AddCompanyNumber = 'payments.companyNumber.add',
    DeleteSelectedInvoices = 'payments.selected.delete',
    SetAmount = 'payments.amount.update',
    SetPaymentMethodType = 'payments.methodType.update',
    SetBillingInformation = 'payments.billingInformation.update',
    SetModalOpen = 'payments.modal.update',
    SetTransactionSettings = 'payments.transactionSettings.update',
    SetCancelled = 'payments.cancelled.update',
    SetPaymentInformation = 'payments.paymentInformation.update',
    SetPaymentMethodList = 'payments.method.list.update',
    SetBankAccountInformation = 'payments.bankAccountInformation.update',
    SetIsACHModalOpen = 'payments.ACH.modal.update',
    SetNotes = 'payments.notes.update',
    SetAllSelected = 'payments.invoices.selected',
    SetInvoiceSelected = 'payments.invoices.selected.update',
    SetInvoiceAmount = 'payments.invoices.amount.update',
    SetIsSaveMethodChecked = 'payments.paymentMethods.checked',
    SetNickname = 'payments.nickname.update',
    ListSavedPaymentMethods = 'payments.paymentMethods.list',
    SetSelectedMethod = 'payments.paymentMethods.selected',
    SetAddingMethod = 'payments.isAddingMethod.update',
}
