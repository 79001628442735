import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ToastFunction } from '../models/App.interfaces';
import { CONTACT_INFORMATION_KEY, CONTACT_INFO_DEFAULT } from './Constants';
import {
    INTERNAL_SERVER_ERROR_KEY,
    INTERNAL_SERVER_ERROR_LOGIN_KEY,
    MESSAGE_ERROR_PAGE,
} from './ErrorConstants';
import { TokenHelper } from './TokenHelper';
import { FormatString } from './Utilities';
import ErrorMessageConstants from '../constants/ErrorMessageConstants';
import RedirectConstants from '../constants/RedirectConstants';

function getDefaultError(
    error: AxiosError,
    navigate: NavigateFunction,
    toast: ToastFunction,
    instance: IPublicClientApplication
) {
    const contactInfo =
        sessionStorage.getItem(CONTACT_INFORMATION_KEY) ?? CONTACT_INFO_DEFAULT;
    switch (error.response?.status) {
        case 401:
            toast(ErrorMessageConstants.SESSION_EXPIRED, { variant: 'error' });
            TokenHelper.logout(instance, navigate);
            break;
        case 403:
            toast(
                FormatString(
                    ErrorMessageConstants.CONTACT_FOR_PAYMENT,
                    contactInfo ?? CONTACT_INFO_DEFAULT
                )
            );
            TokenHelper.logout(instance, navigate);
            break;
        case 400:
            let message = ErrorMessageConstants.INVALID_DATA;
            if (
                error.response.data.errors !== null &&
                error.response.data.errors !== undefined &&
                Array.isArray(error.response.data.errors)
            ) {
                message = error.response?.data?.errors[0].errorMessage;
            }
            toast(message, { variant: 'warning' });
            break;
        case 500:
            navigate('/error', {
                state: {
                    error: INTERNAL_SERVER_ERROR_LOGIN_KEY,
                    message: FormatString(MESSAGE_ERROR_PAGE, contactInfo),
                },
            });
            break;
        default:
            toast(ErrorMessageConstants.CONTACT_VENDOR, {
                variant: 'error',
            });
            break;
    }
}

function getSignInError(
    navigate: NavigateFunction,
    toast: ToastFunction,
    instance: IPublicClientApplication
) {
    toast(ErrorMessageConstants.UNABLE_TO_SIGN_IN, { variant: 'error' });
    TokenHelper.logout(instance, navigate, true);
}

function getTokenMessageError(error: AxiosError, contactInfo?: string) {
    if (error.response?.status === 401) {
        return ErrorMessageConstants.INVALID_ACCOUNT;
    } else if (error.response?.status === 403) {
        return FormatString(
            ErrorMessageConstants.CONTACT_FOR_PAYMENT,
            contactInfo ?? CONTACT_INFO_DEFAULT
        );
    } else if (error.response?.status === 429) {
        return ErrorMessageConstants.TOO_MANY_LOGIN;
    }

    return ErrorMessageConstants.TRY_AGAIN;
}

function getInformationError(error: AxiosError, navigate: NavigateFunction) {
    if (error.response?.status === 500) {
        navigate('/error', {
            state: {
                error: INTERNAL_SERVER_ERROR_KEY,
                message: FormatString(MESSAGE_ERROR_PAGE, CONTACT_INFO_DEFAULT),
            },
        });
    }
}

function getCustomerValidationError(error: AxiosError, contactInfo?: string) {
    if (error.response?.status === 400) {
        return ErrorMessageConstants.INVALID_ACCOUNT;
    } else if (error.response?.status === 403) {
        return FormatString(
            ErrorMessageConstants.CONTACT_FOR_ASSISTANCE,
            contactInfo ?? CONTACT_INFO_DEFAULT
        );
    }

    return ErrorMessageConstants.TRY_AGAIN;
}

function getPostCreditCardMethod(
    error: AxiosError,
    navigate: NavigateFunction,
    toast: ToastFunction,
    instance: IPublicClientApplication,
    setIsOpenModal: (payload: boolean) => void,
    contactInfo?: string
) {
    switch (error.response?.status) {
        case 400:
            toast(ErrorMessageConstants.INVALID_DATA, { variant: 'warning' });
            setIsOpenModal(false);
            break;
        case 500:
            toast(ErrorMessageConstants.SERVER_ERROR, {
                variant: 'error',
            });
            setIsOpenModal(false);
            break;
        case 403:
            toast(
                FormatString(
                    ErrorMessageConstants.CONTACT_FOR_ASSISTANCE,
                    contactInfo ?? CONTACT_INFO_DEFAULT
                ),
                {
                    variant: 'error',
                }
            );
            TokenHelper.logout(instance, navigate);
            break;
        case 401:
            toast(ErrorMessageConstants.SESSION_EXPIRED, {
                variant: 'error',
            });
            TokenHelper.logout(instance, navigate);
            break;
        default:
            toast(ErrorMessageConstants.CONTACT_VENDOR, {
                variant: 'error',
            });
            setIsOpenModal(false);
            break;
    }
}

function getCreatePaymentError(
    error: AxiosError,
    navigate: NavigateFunction,
    toast: ToastFunction,
    instance: IPublicClientApplication,
    onActionCall: () => void
) {
    const contactInfo =
        sessionStorage.getItem(CONTACT_INFORMATION_KEY) ?? CONTACT_INFO_DEFAULT;

    switch (error.response?.status) {
        case 400:
            toast(ErrorMessageConstants.PROCESSING_PAYMENT, {
                variant: 'error',
            });
            navigate('/' + RedirectConstants.INVOICES_PAGE);
            break;
        case 500:
            toast(ErrorMessageConstants.SERVER_ERROR, {
                variant: 'error',
            });
            onActionCall();
            break;
        case 403:
            toast(
                FormatString(
                    ErrorMessageConstants.CONTACT_FOR_PAYMENT,
                    contactInfo ?? CONTACT_INFO_DEFAULT
                ),
                {
                    variant: 'error',
                }
            );
            TokenHelper.logout(instance, navigate);
            break;
        case 401:
            toast(
                FormatString(
                    ErrorMessageConstants.CONTACT_FOR_PAYMENT,
                    contactInfo ?? CONTACT_INFO_DEFAULT
                ),
                {
                    variant: 'error',
                }
            );
            onActionCall();
            break;
        default:
            toast(ErrorMessageConstants.CONTACT_VENDOR, {
                variant: 'error',
            });
            onActionCall();
            break;
    }
}

export const errorActions = {
    defaultError: (
        error: AxiosError,
        navigate: NavigateFunction,
        toast: ToastFunction,
        instance: IPublicClientApplication
    ) => getDefaultError(error, navigate, toast, instance),
    tokenError: (error: AxiosError, contactInfo?: string) =>
        getTokenMessageError(error, contactInfo),
    informationError: (error: AxiosError, navigate: NavigateFunction) =>
        getInformationError(error, navigate),
    customerValiationError: (error: AxiosError, contactInfo?: string) =>
        getCustomerValidationError(error, contactInfo),
    creditCardMethodError: (
        error: AxiosError,
        navigate: NavigateFunction,
        toast: ToastFunction,
        instance: IPublicClientApplication,
        setIsOpenModal: (payload: boolean) => void,
        contactInfo?: string
    ) =>
        getPostCreditCardMethod(
            error,
            navigate,
            toast,
            instance,
            setIsOpenModal,
            contactInfo
        ),
    signInError: (
        navigate: NavigateFunction,
        toast: ToastFunction,
        instance: IPublicClientApplication
    ) => getSignInError(navigate, toast, instance),
    paymentError: (
        error: AxiosError,
        navigate: NavigateFunction,
        toast: ToastFunction,
        instance: IPublicClientApplication,
        onActionCall: () => void
    ) => getCreatePaymentError(error, navigate, toast, instance, onActionCall),
};
