import { useEffect, useState } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import theme from '../../../Theme';
import { useApplication } from '../../../context/ApplicationContext';
import { useInvoices } from '../context/InvoicesContext';
import {
    CONTACT_INFO_DEFAULT,
    DOLLAR_FORMAT,
    PAGE_SIZE_OPTIONS,
} from '../../../helpers/Constants';
import { TokenData, Wrapper, Company } from '../../../models/App.interfaces';
import Typography from '../../../components/Typography/Typography';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid';
import Footer from '../../../components/Footer/TextFooter/Footer';
import { useNavigate } from 'react-router-dom';
import { StyledBox, StyledButton, StyledSelect, StyledGrid } from './styling';
import HeaderGrid from '../../../components/headerGrid/HeaderGrid';
import { INVOICE_COLUMNS } from '../../../helpers/TableColumns';
import { useSnackbar } from 'notistack';
import setAnalyticsArgs from '../../../helpers/AnalyticsArgs';
import { AxiosError } from 'axios';
import CardList from '../components/CardList/CardList';
import { CreateSummaryList, FormatString } from '../../../helpers/Utilities';
import { InvoiceInformation } from '../../../models/Invoice.interfaces';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import Spinner from '../../../components/Spinner/Spinner';
import { useMsal } from '@azure/msal-react';
import AccountActions from '../../../hooks/AccountsActions';
import { errorActions } from '../../../helpers/ErrorActions';
import CustomersActions from '../../../hooks/CustomersActions';
import CompaniesActions from '../../../hooks/CompaniesActions';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import MetadataConstants from '../../../constants/MetadataConstants';
import Metadata from '../../../components/metadata/Metadata';

export default function InvoicesView() {
    const [applicationState] = useApplication();
    const { Company, isPopupOpen } = applicationState;
    const [state, actions] = useInvoices();
    const { Summary, CompanyNumber, allowPending } = state;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [invoiceError, setInvoiceError] = useState<boolean>(false);
    const [companyError, setCompanyError] = useState<boolean>(false);
    const [isRefreshLoading, setIsRefreshLoading] = useState<boolean>(false);
    const [invoicesLoading, setInvoicesLoading] = useState<boolean>(true);
    const [height, setHeight] = useState<string>('500px');
    const [allSelected, setAllSelected] = useState<Array<InvoiceInformation>>(
        []
    );
    const { instance } = useMsal();

    useEffect(() => {
        setAnalyticsArgs(Company.name);
    }, []);

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    //get values from context or Session Storage
    const customerNumber = TokenHelper.getInitialData().customerNumber;
    const customerNumberDisplay = '#' + customerNumber;

    // GET summary
    AccountActions.useSummary(
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            ),
        actions.onSetSummary
    );

    // GET companies API section
    const handleSuccessCompanies = (companies: Wrapper<Company>) => {
        actions.onSetCompanies(companies, CompanyNumber);
    };

    const handleInvoiceErrors = () => {
        enqueueSnackbar(ErrorMessageConstants.SERVER_ERROR, {
            variant: 'error',
        });
        setInvoiceError(true);
        setInvoicesLoading(false);
    };

    //get list of companies and set selectCompanyList
    const { isLoading: companyLoading } = CustomersActions.useGetCompanies(
        handleSuccessCompanies,
        handleInvoiceErrors
    );

    // GET validate company section
    const handleMerchantError = (error: AxiosError) => {
        actions.onSetRowCount(0);
        actions.onSetTotalPages(0);
        actions.onResetGrid();
        setHeight('500px');
        setAllSelected([]);
        setInvoicesLoading(false);
        if (error.response?.status === 401) {
            setCompanyError(true);
            enqueueSnackbar(
                'Please contact ' +
                    (Company.contactInformation ?? CONTACT_INFO_DEFAULT) +
                    ' to submit payment.',
                {
                    variant: 'error',
                }
            );
        } else {
            setInvoiceError(true);
            enqueueSnackbar(ErrorMessageConstants.SERVER_ERROR, {
                variant: 'error',
            });
        }
        setIsRefreshLoading(false);
    };

    const handleMerchantSuccess = () => {
        setIsRefreshLoading(false);
        if (isSuccessRefreshToken) {
            navigate('/payinvoices', {
                state: {
                    SelectedRows: state.CurrentSelected,
                    CompanyNumber: CompanyNumber,
                },
            });
        }
    };

    const { isSuccess: companyValid, refetch: merchantRefetch } =
        CompaniesActions.useValidateCompany(
            CompanyNumber,
            CompanyNumber !== 0 && CompanyNumber !== undefined,
            handleMerchantError,
            handleMerchantSuccess
        );

    // GET Invoices section
    //set grid rows with outstanding invoices
    const handleInvoiceSuccess = (data: any) => {
        setInvoicesLoading(false);
        if (data !== undefined && data.data !== undefined) {
            actions.onSetRows(data.data[0].results);
            actions.onSetRowCount(data.data[0].totalCount);
            actions.onSetTotalPages(data.data[0].totalPages);
            setHeight('auto');
        } else {
            actions.onSetRows([]);
            actions.onSetRowCount(0);
            actions.onSetTotalPages(0);
            setHeight('500px');
        }
    };

    const { isLoading: isInvoicesLoading } = AccountActions.useGetInvoices(
        CompanyNumber,
        state.PageSize,
        state.CurrentPage,
        state.OrderBy,
        state.Ascending,
        companyValid,
        handleInvoiceErrors,
        handleInvoiceSuccess
    );

    // GET refresh token section
    const handleRefreshTokenSuccess = (dataToken: Wrapper<TokenData>) => {
        merchantRefetch();
    };

    const handleRefreshTokenError = (error: AxiosError) => {
        setIsRefreshLoading(false);
        if (!error.response?.status) {
            enqueueSnackbar(ErrorMessageConstants.CONTACT_VENDOR, {
                variant: 'error',
            });
        } else if (error.response?.status === 401) {
            enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
                variant: 'error',
            });
            TokenHelper.logout(instance, navigate);
        }
    };

    // refetch to call Get refresh token
    const { refetch: refetchToken, isSuccess: isSuccessRefreshToken } =
        CustomersActions.useFetchRefreshToken(
            handleRefreshTokenSuccess,
            handleRefreshTokenError
        );

    //custom overlay for no results
    function CustomNoRowsOverlay() {
        let message: string;
        if (invoiceError === true) {
            message = 'Error loading invoices, refresh the page to try again.';
        } else if (companyError === true) {
            // eslint-disable-next-line max-len
            message = `Internal company credential error. Please contact ${Company.contactInformation} to submit a payment.`;
        } else {
            message = 'No Outstanding Invoices for this Account.';
        }

        return (
            <Typography
                variant="h5"
                text={message}
                sx={{ textAlign: 'center', mt: 22 }}
                color="text.primary"
            />
        );
    }

    //reset selected rows on company change
    const handleCompanyChange = (event: any) => {
        actions.onSetCompanyNumber(event.target.value);
        setAllSelected([]);
        actions.onResetGrid();
        setInvoicesLoading(true);
    };

    const handlePageSizeChange = (event: any) => {
        actions.onSetPageSize(event);
    };

    const handlePageChange = (page: any) => {
        actions.onSetCurrentPage(page + 1);
    };

    const handleSelectInvoice = (event: any) => {
        const selectedIds: number[] = Array.from(new Set(event));
        actions.onSetSelectedIDs(selectedIds);
        const filteredRows = state.Rows.filter((invoice: any) =>
            selectedIds.includes(invoice.invoiceNumber)
        );
        actions.onSetSelectedRows(filteredRows);
        setAllSelected((previousSelected: any) => [
            ...previousSelected,
            ...filteredRows,
        ]);
        actions.onSetSelectedCount(selectedIds.length);
        actions.onSetHeaderText(selectedIds.length);
    };

    useEffect(() => {
        let finalInvoiceList: InvoiceInformation[] = [];
        for (let i = 0; i < state.SelectedIDs.length; i++) {
            const match: InvoiceInformation | undefined = allSelected.find(
                (invoice) => invoice.invoiceNumber === state.SelectedIDs[i]
            );

            if (match !== undefined) {
                finalInvoiceList.push(match);
            }
        }
        actions.onSetCurrentSelected(finalInvoiceList);
        calculateTotalDollars(finalInvoiceList);
    }, [allSelected]);

    const calculateTotalDollars = (finalInvoiceList: InvoiceInformation[]) => {
        let totalDollars = 0;
        for (let i = 0; i < finalInvoiceList.length; i++) {
            totalDollars = totalDollars + finalInvoiceList[i].remainingDue;
        }
        actions.onSetSelectedDollars(totalDollars);
    };

    //redirect to payments with selectedRows state
    const handleButtonClick = () => {
        setIsRefreshLoading(true);

        const accounts: AccountInfo[] = instance.getAllAccounts();
        if (accounts.length > 0) {
            navigate('/payinvoices', {
                state: {
                    SelectedRows: state.CurrentSelected,
                    CompanyNumber: CompanyNumber,
                },
            });
        } else {
            refetchToken();
        }
    };

    const metaDescription = FormatString(
        MetadataConstants.INVOICES_DESCRIPTION,
        Company.name
    );

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.INVOICES_TAB_TITLE}
                description={metaDescription}
                noIndex
            />
            <StyledBox>
                <Typography
                    variant="h5"
                    sx={{ maxWidth: '500px' }}
                    text={state.CustomerName}
                    color={'text.primary'}
                />
                <Typography
                    variant="body1"
                    text={customerNumberDisplay}
                    color={'text.primary'}
                />
                <CardList items={CreateSummaryList(Summary, allowPending)} />
                <StyledSelect>
                    <Select
                        native
                        sx={{
                            minWidth: '200px',
                            bgcolor: 'background.paper',
                        }}
                        label="Company"
                        labelId="company"
                        id="company"
                        value={String(CompanyNumber) || ''}
                        onChange={handleCompanyChange}
                        disabled={
                            state.Companies.length <= 1 ||
                            isInvoicesLoading ||
                            isPopupOpen
                        }
                    >
                        {state.Companies.map((company) => (
                            <option
                                key={company.companyNumber}
                                value={company.companyNumber}
                            >
                                {company.companyName}
                            </option>
                        ))}
                    </Select>
                    <StyledButton>
                        <Button
                            type="submit"
                            variant="contained"
                            title={'Pay Invoices'}
                            disabled={
                                state.SelectedCount === 0 ||
                                isRefreshLoading ||
                                isPopupOpen
                            }
                            onClick={handleButtonClick}
                            startIcon={
                                isRefreshLoading && (
                                    <Spinner color="inherit" size={'20px'} />
                                )
                            }
                        />
                    </StyledButton>
                </StyledSelect>
                <StyledGrid disable={isPopupOpen}>
                    <HeaderGrid
                        isSelectedHeader={state.SelectedIDs.length >= 1}
                        headerText={
                            state.SelectedIDs.length >= 1
                                ? state.SelectedCount +
                                  state.HeaderText +
                                  state.SelectedDollars.toFixed(2).replace(
                                      DOLLAR_FORMAT,
                                      ','
                                  )
                                : 'Open Invoices'
                        }
                    />
                    <DataGrid
                        rows={state.Rows}
                        columns={
                            state.allowPending
                                ? INVOICE_COLUMNS
                                : INVOICE_COLUMNS.filter(
                                      (item) => item.field !== 'pendingAmount'
                                  )
                        }
                        loading={
                            isInvoicesLoading ||
                            companyLoading ||
                            invoicesLoading
                        }
                        checkboxSelection
                        sx={{
                            mr: -0.1,
                            borderTop: 0,
                            borderRadius: 0,
                            borderColor: 'divider',
                            bgcolor: 'background.paper',
                            height: { height },
                            '.disabled': {
                                bgcolor: theme.palette.action.hover,
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: theme.palette.dark.main,
                            },
                        }}
                        getRowId={(rows: { invoiceNumber: any }) =>
                            rows.invoiceNumber
                        }
                        pageSize={state.PageSize}
                        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                        onPageSizeChange={handlePageSizeChange}
                        onSelectionModelChange={handleSelectInvoice}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'pastDueDate', sort: 'asc' },
                                ],
                            },
                        }}
                        onSortModelChange={(event: any) => {
                            actions.onSetOrderBy(event[0].field);
                            actions.onSetAscending(event[0].sort === 'asc');
                        }}
                        sortingOrder={['desc', 'asc']}
                        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                        paginationMode={'server'}
                        rowCount={state.RowCount}
                        onPageChange={handlePageChange}
                        disableColumnMenu
                        keepNonExistentRowsSelected
                        selectionModel={state.SelectedIDs}
                        hideFooterSelectedRowCount
                        autoHeight={state.RowCount > 0}
                        isRowSelectable={(row: GridRowParams) => {
                            return (
                                row.row.remainingDue - row.row.pendingAmount > 0
                            );
                        }}
                        getRowClassName={(row: GridRowClassNameParams) => {
                            return row.row.remainingDue -
                                row.row.pendingAmount >
                                0
                                ? ''
                                : 'disabled';
                        }}
                        page={state.CurrentPage - 1}
                    />
                </StyledGrid>
                <Footer />
            </StyledBox>
        </>
    );
}
