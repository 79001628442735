import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export async function printReceipt(
    component: HTMLDivElement,
    filename: string
) {
    const canvas = await html2canvas(component, {
        scale: 2,
    });
    const margin = [0.5, 0.5];

    const imgWidth = 8.5;
    const imgHeight = 10.9;

    const innerPageWidth = imgWidth - margin[0] * 2;
    const innerPageHeight = imgHeight - margin[1] * 2;

    const pxFullHeight = canvas.height;
    const pxPageHeight = Math.floor(canvas.width * (imgHeight / imgWidth));
    const pages = Math.ceil(pxFullHeight / pxPageHeight);

    let pageHeight = innerPageHeight;

    let pageCanvas = document.createElement('canvas');
    let pageCtx = pageCanvas.getContext('2d');
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    const pdf = new jsPDF('p', 'in', [8.5, 11]);

    for (let i = 0; i < pages; i++) {
        if (pageCtx !== null) {
            if (i === pages - 1 && pxFullHeight % pxPageHeight !== 0) {
                pageCanvas.height = pxFullHeight % pxPageHeight;
                pageHeight =
                    (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }

            let w = pageCanvas.width;
            let h = pageCanvas.height;
            pageCtx.fillStyle = 'white';
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, i * pxPageHeight, w, h, 0, 0, w, h);

            if (i > 0) {
                pdf.addPage();
            }
            const imgData = pageCanvas.toDataURL('image/png', 1.0);
            pdf.addImage(
                imgData,
                'PNG',
                margin[1],
                margin[0],
                innerPageWidth,
                pageHeight
            );
        }
    }
    pdf.save(filename);
}
