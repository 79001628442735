import styled from '@emotion/styled';

export const StyledBox = styled.div`
    top: 50px;
    min-width: 900px;
    margin: auto;
    height: auto;
    position: relative;
    width: 90%;
    padding-bottom: 20px;
`;

export const StyledCard = styled.div`
    max-width: 300px;
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const StyledCardGrid = styled.div`
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    justify-content: space-between;
    row-gap: 4px;
    padding: 18px;
`;

export const StyledSelect = styled.div`
    padding-top: 130px;
`;

export const StyledButton = styled.div`
    position: absolute;
    right: 0px;
    margin-top: -40px;
`;

export const StyledGrid = styled.div`
    border-radius: 5px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    ${(props: { disable?: boolean }) =>
        props.disable &&
        `
        pointer-events: none;
        opacity: 0.8;
    `}
`;
