import { TableFooter } from '@mui/material';
import { NumberFormatValues } from 'react-number-format';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Table from '../../../../components/table/Table';
import TableBody from '../../../../components/tableBody/TableBody';
import TableCell from '../../../../components/tableCell/TableCell';
import TableContainer from '../../../../components/tableContainer/TableContainer';
import TableHead from '../../../../components/tableHead/TableHead';
import TableRow from '../../../../components/tableRow/TableRow';
import TextField from '../../../../components/textField/TextField';
import Typography from '../../../../components/Typography/Typography';
import {
    AmountInvoicesFormat,
    InvoiceFormatCustom,
} from '../../../../helpers/FormatNumbers';
import { AMOUNT_ERROR_MESSAGE } from '../../../../helpers/Constants';
import { TableInvoiceProps } from './TableInvoices.types';
import { AmountErrorType } from '../../../../models/App.types';
import { SummaryHelper } from '../../../../helpers/SummaryHelper';

export default function TableInvoices(props: TableInvoiceProps) {
    const {
        rows,
        cells,
        totalSelected,
        onCheckboxHeaderSelect,
        onCheckboxRowSelect,
        handleInvoiceInputs,
        totalAmount,
        allowPending,
        isPopupOpen,
    } = props;

    return (
        <TableContainer>
            <Table sx={{ minWidth: 750 }} size="small">
                <TableHead
                    cells={cells}
                    totalSelected={totalSelected}
                    rowCount={rows.length}
                    onCheckboxSelect={onCheckboxHeaderSelect}
                    disabled={isPopupOpen}
                />
                <TableBody>
                    {rows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const isAllowed = (formatValue: NumberFormatValues) =>
                            String(Math.trunc(formatValue.floatValue ?? 0))
                                .length <=
                            String(Math.trunc(row.remainingDue)).length;
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={row.isSelected}
                                tabIndex={-1}
                                key={index}
                            >
                                <TableCell padding="none">
                                    <Checkbox
                                        id={row.invoiceNumber}
                                        checked={row.isSelected || false}
                                        onCheckboxChange={onCheckboxRowSelect}
                                        disabled={isPopupOpen}
                                    />
                                </TableCell>
                                <TableCell id={labelId} padding="none">
                                    <Typography
                                        variant="body1"
                                        text={row.invoiceNumber}
                                        color={'otherText.primary'}
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="body1"
                                        text={
                                            row.date &&
                                            new Date(
                                                row.date
                                            ).toLocaleDateString()
                                        }
                                        color={'otherText.primary'}
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="body1"
                                        text={row.serviceLocationName}
                                        color={'otherText.primary'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        variant="body1"
                                        text={SummaryHelper.formatTotalAmount(
                                            row.originalAmount
                                        )}
                                        color={'otherText.primary'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        variant="body1"
                                        text={SummaryHelper.formatTotalAmount(
                                            row.remainingDue
                                        )}
                                        color={'otherText.primary'}
                                    />
                                </TableCell>
                                {allowPending && (
                                    <TableCell align="right">
                                        <Typography
                                            variant="body1"
                                            text={
                                                row.pendingAmount > 0
                                                    ? '(' +
                                                      SummaryHelper.formatTotalAmount(
                                                          row.pendingAmount
                                                      ) +
                                                      ')'
                                                    : ''
                                            }
                                            color={'otherText.primary'}
                                        />
                                    </TableCell>
                                )}
                                <TableCell align="left">
                                    <TextField
                                        id={row.invoiceNumber}
                                        hiddenLabel
                                        value={row.amount}
                                        size="small"
                                        error={
                                            row.errorType !==
                                            AmountErrorType.NONE
                                        }
                                        handleChange={handleInvoiceInputs}
                                        inputComponent={InvoiceFormatCustom}
                                        inputProps={{
                                            isAllowed: isAllowed,
                                            style: { textAlign: 'right' },
                                        }}
                                        helperText={
                                            AMOUNT_ERROR_MESSAGE[row.errorType]
                                        }
                                        disabled={isPopupOpen}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colspan={cells.length} />
                        <TableCell align="left">
                            <Typography
                                variant="subtitle1"
                                text={'Total'}
                                sx={{ fontWeight: 'bold', mr: '14px' }}
                                align="right"
                                color={'text.primary'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colspan={cells.length} />
                        <TableCell align="left">
                            <Typography
                                variant="subtitle1"
                                align="right"
                                sx={{ mr: '14px' }}
                                color={'text.primary'}
                            >
                                <AmountInvoicesFormat text={totalAmount} />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
