import { useMsal } from '@azure/msal-react';
import { AppBar, Toolbar } from '@mui/material';
import Box from '../../components/box/Box';
import Button from '../../components/Button/Button';
import Tabs from '../../components/tabs/Tabs';
import Typography from '../../components/Typography/Typography';
import Avatar from '../../components/avatar/Avatar';
import { B2C_CONSTANTS } from '../../config/B2CConfig';
import { useApplication } from '../../context/ApplicationContext';
import { TAB_VALUES } from '../../helpers/Constants';
import SuccessfulSignup from '../SuccessfulSignup/SuccessfulSignup';
import { AuthInformation } from '../../models/App.interfaces';
import {
    CreateAuthInformation,
    GetInitials,
    ConvertEmailForPasswordChange,
} from '../../helpers/Utilities';
import AuthActions from '../../hooks/AuthActions';
import { useNavigate } from 'react-router-dom';
import {
    InteractionStatus,
    AuthenticationResult,
    PopupRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';
import { useSnackbar } from 'notistack';
import { TokenHelper } from '../../helpers/TokenHelper';
import { errorActions } from '../../helpers/ErrorActions';
import UserMenu from '../../components/menu/UserMenu';
import { useState } from 'react';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import ScreenConstants from '../../constants/ScreenConstants';

export default function NavbarView() {
    const [applicationState, actions] = useApplication();
    const { isPopupOpen, isUserSignedIn, userInformation } = applicationState;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { instance, inProgress } = useMsal();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { fullPageLoader, showLoader, hideLoader } = useFullPageLoader();

    //TODO: Update color when we update tidal packages
    //tidlColors.slateBlueGrey[600] is not available in our current version of tidal
    const slateBlueGrey = '#586A7A';

    const postSignUp = AuthActions.useSignUp((error) =>
        errorActions.signInError(navigate, enqueueSnackbar, instance)
    );
    const postSignIn = AuthActions.useInternalSignIn((error) =>
        errorActions.signInError(navigate, enqueueSnackbar, instance)
    );

    const onSignin = async () => {
        actions.onUpdateIsPopupOpen(true);
        try {
            showLoader();
            const loginRequest = {
                scopes: B2C_CONSTANTS.scopes,
                authority: B2C_CONSTANTS.authoritySignIn,
            };
            await instance.loginPopup(loginRequest);
            const request: AuthInformation = CreateAuthInformation();
            const { data } = await postSignIn.mutateAsync(request);
            actions.onAddUserData(data);
            actions.onUpdateIsUserSignedIn();
        } finally {
            actions.onUpdateIsPopupOpen(false);
            hideLoader();
        }
    };

    const onSignup = async () => {
        actions.onUpdateIsPopupOpen(true);
        try {
            showLoader();
            const loginRequest = {
                scopes: B2C_CONSTANTS.scopes,
                authority: B2C_CONSTANTS.authoritySignUp,
            };
            await instance.loginPopup(loginRequest);
            const request: AuthInformation = CreateAuthInformation();
            const { data } = await postSignUp.mutateAsync(request);
            actions.onAddUserData(data);
            actions.onUpdateIsSuccessfulSignup(true);
            actions.onUpdateIsUserSignedIn();
        } catch (error) {
            actions.onUpdateIsSuccessfulSignup(false);
        } finally {
            actions.onUpdateIsPopupOpen(false);
            hideLoader();
        }
    };

    const onLogOut = () => {
        setMenuOpen(false);
        actions.onUpdateLogUserOut(false);
        TokenHelper.logout(instance, navigate);
        actions.onUpdateIsUserSignedIn();
    };

    const onChangePassword = async () => {
        if (inProgress === InteractionStatus.None) {
            showLoader();
            actions.onUpdateIsPopupOpen(true);
            setMenuOpen(false);
            try {
                const changePasswordRequest: PopupRequest = {
                    scopes: B2C_CONSTANTS.scopes,
                    authority: B2C_CONSTANTS.authorityChangePassword,
                    extraQueryParameters: {
                        signInName: ConvertEmailForPasswordChange(
                            userInformation.emailAddress
                        ),
                    },
                };

                const authenticationResponse: AuthenticationResult =
                    await instance.acquireTokenPopup(changePasswordRequest);
                enqueueSnackbar(ScreenConstants.PASSWORD_CHANGE_SUCCESS_TOAST, {
                    variant: 'success',
                });
                TokenHelper.logoutOnChangePassword(
                    instance,
                    authenticationResponse.account
                );
                let signUpSignInFlowRequest: SsoSilentRequest = {
                    authority: B2C_CONSTANTS.authoritySignIn,
                    account: applicationState.originalAccount,
                };
                await instance.ssoSilent(signUpSignInFlowRequest);
            } catch {
            } finally {
                actions.onUpdateIsPopupOpen(false);
                hideLoader();
            }
        }
    };

    return (
        <>
            <AppBar position="static" sx={{ minWidth: '900px' }}>
                <Toolbar>
                    <Box
                        sx={{
                            height: 64,
                        }}
                    >
                        <img
                            alt="logo"
                            src={applicationState.Company.logo}
                            style={{
                                height: '100%',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                boxSizing: 'border-box',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            paddingLeft: '100px',
                        }}
                    >
                        {isUserSignedIn && <Tabs values={TAB_VALUES} />}
                    </Box>
                    {!isUserSignedIn && (
                        <>
                            <Button
                                color="inherit"
                                title="Sign in"
                                sx={{ textTransform: 'none' }}
                                onClick={onSignin}
                                disabled={isPopupOpen}
                            />
                            <Typography variant="body1">|</Typography>
                            <Button
                                color="inherit"
                                title="Sign up"
                                sx={{ textTransform: 'none' }}
                                onClick={onSignup}
                                disabled={isPopupOpen}
                            />
                        </>
                    )}
                    {isUserSignedIn && (
                        <>
                            <UserMenu
                                buttonTitle={
                                    applicationState.userInformation.fullName
                                }
                                onLogout={onLogOut}
                                onChangePassword={onChangePassword}
                                menuOpen={menuOpen}
                                setMenuOpen={setMenuOpen}
                            />
                            <Avatar
                                variant="circular"
                                sx={{
                                    m: 1,
                                    backgroundColor: slateBlueGrey,
                                }}
                            >
                                {GetInitials(
                                    applicationState.userInformation.fullName
                                )}
                            </Avatar>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {fullPageLoader}
            <SuccessfulSignup />
        </>
    );
}
