export enum ActionTypes {
    ListHistory = 'history.list',
    ListCompanies = 'history.companies.list',
    SetCompanyNumber = 'history.companyNumber.update',
    UpdateHistoryParameters = 'history.parameters.update',
    SetPaymentHistoryPagination = 'history.pagination.update',
    SetDateValue = 'history.dateValue.update',
    SetConfirmationPopoverOpen = 'history.confPopoverOpen.update',
    SetConfirmationNumber = 'history.confNumber.update',
    SetInvoicesPopoverOpen = 'history.invoicesPopoverOpen.update',
    SetInvoicesList = 'history.invoicesList.update',
}
