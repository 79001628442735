import Box from '../box/Box';
import Spinner from '../Spinner/Spinner';
import { type Theme } from '@mui/material';

export default function FullPageLoader() {
    return (
        <Box
            position="fixed"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                bgcolor: 'action.disabled',
                zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Spinner color="primary" sx={{ position: 'absolute' }} />
        </Box>
    );
}
