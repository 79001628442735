import { TableRow as MUITableRow } from '@mui/material';
import { RowProps } from './TableRow.types';

export default function TableRow(props: RowProps) {
    const { children, hover, role, tabIndex, myKey, isSelected } = props;
    return (
        <MUITableRow
            hover={hover}
            role={role}
            tabIndex={tabIndex}
            key={myKey}
            selected={isSelected}
        >
            {children}
        </MUITableRow>
    );
}
