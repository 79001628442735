export enum ActionTypes {
    SetPaymentMethodType = 'paymentMethod.methodType.update',
    SetPaymentMethodDetails = 'paymentMethod.details.update',
    SetCompanies = 'paymentMethod.companies.list',
    SetCompanyNumber = 'paymentMethod.companyNumber.update',
    SetBankAccountInformation = 'paymentMethod.bankAccountInformation.update',
    SetIsCreditCardModalOpen = 'paymentMethod.modal.creditCard.update',
    SetCreditCardTransactionSettings = 'payments.creditCard.transactionSettings.update',
    SetHasEventSourceFailed = 'payments.EventSource.failed.update',
    SetStreamEventStatus = 'payments.streamEvent.status.update',
    SetCreditCardModalReset = 'payments.creditCard.modal.reset',
}
