import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Container from '../../../components/container/Container';
import DataGrid from '../../../components/DataGrid/DataGrid';
import Paper from '../../../components/paper/Paper';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import Box from '../../../components/box/Box';
import UsersActions from '../../../hooks/UsersActions';
import theme from '../../../Theme';
import { useCustomers } from '../context/CustomersContext';
import AddCustomerModal from '../components/AddCustomerModal/AddCustomerModal';
import CustomerSuccessModal from '../components/CustomerSuccessModal/CustomerSuccessModal';
import { Wrapper, Customer, TokenData } from '../../../models/App.interfaces';
import { errorActions } from '../../../helpers/ErrorActions';
import { useMsal } from '@azure/msal-react';
import { TokenHelper } from '../../../helpers/TokenHelper';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { useState } from 'react';
import ErrorMessageConstants from '../../../constants/ErrorMessageConstants';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MetadataConstants from '../../../constants/MetadataConstants';
import Metadata from '../../../components/metadata/Metadata';
import { CUSTOMER_COLUMNS } from '../helpers/CustomerColumns';

export default function CustomersView() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [state, actions] = useCustomers();
    const { instance } = useMsal();
    const [customerNumber, setCustomerNumber] = useState<string>('');

    const handleIdle = () => {
        enqueueSnackbar(ErrorMessageConstants.SESSION_EXPIRED, {
            variant: 'error',
        });
        TokenHelper.logout(instance, navigate);
    };

    useIdleTimeout(handleIdle);

    const handleCustomerSuccess = (data: Wrapper<Customer>) => {
        if (data !== undefined) {
            actions.onSetCustomers(data);
        }
    };

    // GET Customers
    const { isLoading, isFetching } = UsersActions.useGetCustomers(
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            ),
        handleCustomerSuccess
    );

    function customNoRowsOverlay() {
        const message: string = 'No Customers for this Account.';
        return (
            <Typography
                variant="h5"
                text={message}
                sx={{ textAlign: 'center', mt: 22 }}
                color="text.primary"
            />
        );
    }

    const handleRowClick: GridEventListener<'rowClick'> = (
        params: GridRowParams<Customer>
    ) => {
        let tokenData: TokenData = TokenHelper.getInitialData();
        const customer = state.customers.find(
            (customer) => customer.customerNumber === params.row.customerNumber
        );

        tokenData.customerName = customer?.customerName || '';
        tokenData.customerNumber = customer?.customerNumber || '';
        tokenData.allowCreditCard = customer?.allowCreditCard || false;
        tokenData.allowACH = customer?.allowACH || false;
        TokenHelper.saveInitialData(tokenData);
        navigate('/invoices');
    };

    return (
        <>
            <Metadata
                tabTitle={MetadataConstants.CUSTOMERS_TAB_TITLE}
                description={MetadataConstants.CUSTOMERS_DESCRIPTION}
                noIndex
            />
            <Container maxWidth="xl" sx={{ pt: '70px', minWidth: '900px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mb: 5,
                    }}
                >
                    <Button
                        title={'Add customer'}
                        variant={'contained'}
                        onClick={() => {
                            actions.onUpdateIsCustomerPopupOpen(true);
                        }}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    />
                </Box>
                <Paper sx={{ pt: '16px' }}>
                    <Typography
                        variant="h5"
                        sx={{ maxWidth: '500px', pl: '16px', pb: '8px' }}
                        text={'Select a customer'}
                        color={'otherText.primary'}
                    />
                    <DataGrid
                        rows={state.customers}
                        columns={CUSTOMER_COLUMNS}
                        loading={isLoading || isFetching}
                        sx={{
                            mr: -0.1,
                            border: 0,
                            borderRadius: 0,
                            borderColor: 'divider',
                            minHeight:
                                state.customers.length > 0 ? '100px' : '500px',
                            '.disabled': {
                                bgcolor: theme.palette.action.hover,
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: theme.palette.dark.main,
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '& .principal-cell--cell': {
                                color: theme.palette.primary.main,
                                borderRight: '2px solid',
                                borderColor: 'divider',
                                justifyContent: 'space-between',
                            },
                            '& .principal-cell--cell .menu': {
                                visibility: 'hidden',
                            },
                            '& .principal-cell--cell:hover .menu': {
                                visibility: 'visible',
                            },
                        }}
                        getRowId={(rows: { customerNumber: any }) =>
                            rows.customerNumber
                        }
                        components={{ NoRowsOverlay: customNoRowsOverlay }}
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        sortingOrder={[null]}
                        hideFooter
                        onRowClick={handleRowClick}
                        autoHeight
                    />
                </Paper>
            </Container>
            <AddCustomerModal
                customerNumber={customerNumber}
                setCustomerNumber={setCustomerNumber}
            />
            <CustomerSuccessModal
                customerNumber={customerNumber}
                setCustomerNumber={setCustomerNumber}
            />
        </>
    );
}
