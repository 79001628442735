import { useMsal } from '@azure/msal-react';
import { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { SESSION_DATA_KEY } from '../helpers/Constants';

function PrivateRoute(props: { children: ReactNode }): JSX.Element {
    const { children } = props;
    const location = useLocation();
    const { accounts } = useMsal();

    return !!sessionStorage.getItem(SESSION_DATA_KEY) || accounts.length > 0 ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    );
}

export default PrivateRoute;
