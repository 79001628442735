import NavbarView from '../../views/navbarView/NavbarView';
import SummaryView from './views/SummaryView';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

export default function Summary() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <SummaryView />
            </StyledMain>
            <LogoFooter />
        </>
    );
}
