import {
    GridValueFormatterParams,
    GridValueGetterParams,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import { HeadCell } from '../models/Props.types';
import SelectedMethodCell from '../pages/Payments/components/selectedMethodCell/SelectedMethodCell';
import { DOLLAR_FORMAT } from './Constants';
import { PaymentHistory } from '../models/App.interfaces';
import InvoicesListCell from '../pages/history/components/InvoicesListCell/InvoicesListCell';
import ConfirmationCell from '../pages/history/components/ConfirmationCell/ConfirmationCell';

export const getHistoryColumns = (textColor: string) => {
    return [
        {
            field: 'paymentDate',
            headerName: 'Date of Payment',
            flex: 0.12,
            sortable: true,
            type: 'date',
            valueGetter: (params: GridValueGetterParams) =>
                params.value && new Date(params.value),
        },
        {
            field: 'referenceNumber',
            headerName: 'Conf #',
            flex: 0.08,
            sortable: true,
            renderCell: (
                params: GridRenderCellParams<string, PaymentHistory>
            ) => {
                return (
                    <ConfirmationCell params={params} textColor={textColor} />
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 0.12,
            sortable: false,
            valueFormatter: (params: GridValueFormatterParams) => {
                const formattedValue =
                    '$' + params.value.toFixed(2).replace(DOLLAR_FORMAT, ',');
                return `${formattedValue}`;
            },
        },
        {
            field: 'accountType',
            headerName: 'Payment Method',
            flex: 0.13,
            sortable: true,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.accountType} - *${params.row.accountLastFour}`,
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            flex: 0.15,
            sortable: true,
        },
        {
            field: 'appliedInvoices',
            headerName: 'Applied Invoices',
            flex: 0.25,
            sortable: false,
            renderCell: (
                params: GridRenderCellParams<string, PaymentHistory>
            ) => {
                return (
                    <InvoicesListCell params={params} textColor={textColor} />
                );
            },
        },
        {
            field: 'currentStatus',
            headerName: 'Status',
            flex: 0.15,
            sortable: true,
        },
    ];
};

export const INVOICE_COLUMNS = [
    {
        field: 'invoiceNumber',
        headerName: 'Invoice #',
        flex: 0.65,
    },
    {
        field: 'date',
        headerName: 'Invoice Date',
        flex: 0.75,
        type: 'date',
        valueGetter: (params: any) => params.value && new Date(params.value),
    },
    {
        field: 'pastDueDate',
        headerName: 'Due Date',
        flex: 0.75,
        type: 'date',
        valueGetter: (params: any) => params.value && new Date(params.value),
    },
    {
        field: 'serviceLocationName',
        headerName: 'Job Name',
        flex: 1,
    },
    {
        field: 'originalAmount',
        headerName: 'Original Amount',
        flex: 0.75,
        valueFormatter: (params: any) => {
            const formattedValue =
                '$' + params.value.toFixed(2).replace(DOLLAR_FORMAT, ',');
            return `${formattedValue}`;
        },
    },
    {
        field: 'remainingDue',
        headerName: 'Outstanding',
        flex: 0.75,
        valueFormatter: (params: any) => {
            const formattedValue =
                '$' + params.value.toFixed(2).replace(DOLLAR_FORMAT, ',');
            return `${formattedValue}`;
        },
    },
    {
        field: 'pendingAmount',
        headerName: 'Pending Payments',
        flex: 0.75,
        valueFormatter: (params: any) =>
            params.value > 0
                ? `($${params.value.toFixed(2).replace(DOLLAR_FORMAT, ',')})`
                : '',
    },
];

export const INVOICE_COLUMNS_SELECTED: HeadCell[] = [
    {
        id: 'number',
        disablePadding: true,
        label: 'Invoice #',
    },
    {
        id: 'date',
        disablePadding: false,
        label: 'Invoice Date',
    },
    {
        id: 'jobName',
        disablePadding: false,
        label: 'Job Name',
    },
    {
        id: 'originalAmount',
        disablePadding: false,
        label: 'Original Amount',
        align: 'right',
    },
    {
        id: 'outstanding',
        disablePadding: false,
        label: 'Outstanding',
        align: 'right',
    },
    {
        id: 'pending',
        disablePadding: false,
        label: 'Pending Payment',
        align: 'right',
    },
    {
        id: 'paymentAmount',
        disablePadding: false,
        label: 'Payment Amount',
    },
];

export const PAY_METHODS_COLUMNS = [
    {
        field: 'selected',
        headerName: '',
        width: 63,
        sortable: false,
        renderCell: SelectedMethodCell,
    },
    {
        field: 'friendlyName',
        headerName: 'Nickname',
        flex: 0.25,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
            params.value === ''
                ? `${params.row.methodDescription} - *****${params.row.methodLastFour} `
                : params.value,
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.25,
        sortable: false,
    },
    {
        field: 'methodLastFour',
        headerName: 'Card/Account No.',
        flex: 0.25,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.methodDescription} - *****${params.row.methodLastFour}`,
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration',
        flex: 0.15,
        sortable: false,
    },
    {
        field: 'isDefault',
        headerName: 'Primary',
        flex: 0.1,
        sortable: false,
    },
];
