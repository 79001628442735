import { createContext, useMemo, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { Reducer } from './State';
import ActionFactory from './Actions';
import { Props } from '../models/Props.types';
import { ApplicationFactory, Information } from './ApplicationContext.types';
import { CONTACT_INFO_DEFAULT } from '../helpers/Constants';

export const initialState: Information = {
    Company: {
        logo: '',
        name: '',
        contactInformation: CONTACT_INFO_DEFAULT,
    },
    isPopupOpen: false,
    isSuccessfulSignup: false,
    userInformation: {
        emailAddress: '',
        encodedTenantInformation: '',
        existsInDatabase: false,
        firstName: '',
        lastName: '',
        fullName: '',
    },
    originalAccount: undefined,
    isUserSignedIn: false,
};

const Application = createContext<Information>(initialState);
const ApplicationDispatch = createContext<ApplicationFactory>(
    {} as ApplicationFactory
);

export function ApplicationDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, initialState);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);

    useEffect(() => {
        actions.onUpdateCompanyData();
        actions.onUpdateUserData();
        actions.onUpdateIsUserSignedIn();
    }, [actions]);

    return (
        <Application.Provider value={value}>
            <ApplicationDispatch.Provider value={actions}>
                {children}
            </ApplicationDispatch.Provider>
        </Application.Provider>
    );
}

export function useApplication(): [Information, ApplicationFactory] {
    const state = useContext(Application);
    const actions = useContext(ApplicationDispatch);

    return [state, actions];
}
