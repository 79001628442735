import { Radio as MuiRadio } from '@mui/material';
import { RadioProps } from './Radio.types';

export default function Radio(props: RadioProps) {
    const { id, checked, disabled, onChange } = props;

    return (
        <MuiRadio
            id={id}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    );
}
