import { Dispatch } from 'react';
import {
    LOGO_KEY,
    CONTACT_INFORMATION_KEY,
    NAME_KEY,
} from '../helpers/Constants';
import { TokenHelper } from '../helpers/TokenHelper';
import { formatGeneralInformation } from '../helpers/Utilities';
import { GeneralInformation, UserInformation } from '../models/App.interfaces';
import { ActionsTypes } from './ActionTypes';
import { ApplicationFactory, LoginActions } from './ApplicationContext.types';
import { AccountInfo } from '@azure/msal-browser';

function SetCompany(
    payload: GeneralInformation,
    dispatch: Dispatch<LoginActions>
): void {
    const information: GeneralInformation = formatGeneralInformation(payload);
    TokenHelper.saveSessionStorage(
        information.logo,
        information.contactInformation,
        information.name
    );
    dispatch({ type: ActionsTypes.AddCompanyData, payload: information });
}

function UpdateCompany(dispatch: Dispatch<LoginActions>): void {
    let companyData: GeneralInformation = {
        logo: sessionStorage.getItem(LOGO_KEY) ?? '',
        contactInformation:
            sessionStorage.getItem(CONTACT_INFORMATION_KEY) ?? '',
        name: sessionStorage.getItem(NAME_KEY) ?? '',
    };
    dispatch({ type: ActionsTypes.AddCompanyData, payload: companyData });
}

function UpdateIsPopupOpen(
    payload: boolean,
    dispatch: Dispatch<LoginActions>
): void {
    dispatch({ type: ActionsTypes.UpdateIsPopupOpen, payload: payload });
}

function UpdateIsSuccessfulSignup(
    payload: boolean,
    dispatch: Dispatch<LoginActions>
): void {
    dispatch({ type: ActionsTypes.UpdateIsSuccessfulSignup, payload: payload });
}

function SetUserInformation(
    payload: UserInformation,
    dispatch: Dispatch<LoginActions>
): void {
    TokenHelper.saveAuthData(payload);
    dispatch({ type: ActionsTypes.AddUserData, payload: payload });
}

function UpdateUser(dispatch: Dispatch<LoginActions>): void {
    const userData: UserInformation = TokenHelper.getUserInformation();
    dispatch({ type: ActionsTypes.UpdateUserData, payload: userData });
}

function UpdateOriginalAccount(
    payload: AccountInfo,
    dispatch: Dispatch<LoginActions>
): void {
    dispatch({ type: ActionsTypes.UpdateOriginalAccount, payload: payload });
}

function UpdateIsUserSignedIn(dispatch: Dispatch<LoginActions>): void {
    const accessToken: string = TokenHelper.getAccessToken();
    const encodedTenant: string = TokenHelper.getEncodedTenantInformation();
    const isSignedIn: boolean = accessToken !== '' && encodedTenant !== '';
    dispatch({ type: ActionsTypes.UpdateIsUserSignedIn, payload: isSignedIn });
}

function UpdateLogUserOut(
    payload: boolean,
    dispatch: Dispatch<LoginActions>
): void {
    dispatch({ type: ActionsTypes.UpdateIsUserSignedIn, payload: payload });
}

export default function ActionFactory(
    dispatch: Dispatch<LoginActions>
): ApplicationFactory {
    return {
        onAddCompanyData: (payload: GeneralInformation) =>
            SetCompany(payload, dispatch),
        onUpdateCompanyData: () => UpdateCompany(dispatch),
        onUpdateIsPopupOpen: (payload: boolean) =>
            UpdateIsPopupOpen(payload, dispatch),
        onUpdateIsSuccessfulSignup: (payload: boolean) =>
            UpdateIsSuccessfulSignup(payload, dispatch),
        onAddUserData: (payload: UserInformation) =>
            SetUserInformation(payload, dispatch),
        onUpdateUserData: () => UpdateUser(dispatch),
        onUpdateOriginalAccount: (payload: AccountInfo) =>
            UpdateOriginalAccount(payload, dispatch),
        onUpdateIsUserSignedIn: () => UpdateIsUserSignedIn(dispatch),
        onUpdateLogUserOut: (payload: boolean) =>
            UpdateLogUserOut(payload, dispatch),
    };
}
