import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '../../../components/box/Box';
import Typography from '../../../components/Typography/Typography';
import Spinner from '../../../components/Spinner/Spinner';
import {
    TRANSACTION_CANCELLED,
    TRANSACTION_CANCELLED_MESSAGE,
    TRANSACTION_CAPTION_MESSAGE,
    TRANSACTION_SUCCESS,
    TRANSACTION_SUCCESS_MESSAGE,
    DOLLAR_FORMAT,
    PAYMENT_METHOD_WARNING,
} from '../../../helpers/Constants';
import {
    CreatePutHostedTransaction,
    FormatString,
} from '../../../helpers/Utilities';
import { StyledSpinner } from '../../Login/views/styling';
import PaymentActions from '../../../hooks/PaymentsActions';
import { useSnackbar } from 'notistack';
import {
    HostedTransactionPutResponse,
    IFrameResult,
} from '../../../models/Transaction.interfaces';
import { errorActions } from '../../../helpers/ErrorActions';
import { useMsal } from '@azure/msal-react';
import Metadata from '../../../components/metadata/Metadata';

export default function ResultView() {
    const [result, setResult] = useState<IFrameResult>();
    const [isActionCalled, setIsActionCalled] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { instance } = useMsal();

    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );
    const status = searchParams.get('TransactionStatus');

    useEffect(() => {
        if (status === null) {
            navigate('/error');
        } else if (result === undefined) {
            const iFrameResult: IFrameResult = Object.fromEntries(searchParams);
            setResult(iFrameResult);
        }
    }, [navigate, result, searchParams, status]);

    const deleteHostedTransaction = PaymentActions.useDeleteHostedTransaction(
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            )
    );

    const putHostedTransaction = PaymentActions.usePutHostedTransaction(
        (error) =>
            errorActions.defaultError(
                error,
                navigate,
                enqueueSnackbar,
                instance
            )
    );

    useEffect(() => {
        if (!isActionCalled && result !== undefined) {
            setIsActionCalled(true);
            if (status === 'Approved') {
                const hostedTransaction = CreatePutHostedTransaction(result);
                putHostedTransaction
                    .mutateAsync({
                        body: hostedTransaction,
                        id: result?.TeamReferenceNumber ?? '',
                    })
                    .then((response) => {
                        const data: HostedTransactionPutResponse =
                            response.data as HostedTransactionPutResponse;
                        const isSaveMethod = !!result.PaymentAccountToken;

                        setShowWarning(
                            isSaveMethod &&
                                !data.wasPaymentMethodSuccessfullySaved
                        );
                    });
            } else {
                deleteHostedTransaction.mutate(
                    result?.TeamReferenceNumber ?? ''
                );
            }
        }
    }, [
        deleteHostedTransaction,
        isActionCalled,
        putHostedTransaction,
        result,
        status,
    ]);

    return (
        <>
            <Metadata robotsOnly />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ backgroundColor: 'background.default' }}
            >
                <Box sx={{ m: 'auto', maxWidth: '690px', pt: 4 }}>
                    <Typography
                        color={'text.primary'}
                        variant="h6"
                        text={
                            status === 'Approved'
                                ? TRANSACTION_SUCCESS
                                : TRANSACTION_CANCELLED
                        }
                        sx={{ fontWeight: 'bold', mb: 2 }}
                    />
                    <Typography
                        color={'text.primary'}
                        variant="body2"
                        text={
                            status === 'Approved'
                                ? FormatString(
                                      TRANSACTION_SUCCESS_MESSAGE,
                                      result?.Amount
                                          ? result.Amount.toString().replace(
                                                DOLLAR_FORMAT,
                                                ','
                                            )
                                          : '0'
                                  )
                                : TRANSACTION_CANCELLED_MESSAGE
                        }
                    />
                    {status === 'Approved' && (
                        <>
                            {showWarning && (
                                <Typography
                                    color={'warning.light'}
                                    variant="body2"
                                    text={PAYMENT_METHOD_WARNING}
                                    sx={{ pt: 1 }}
                                />
                            )}
                            <Typography
                                color={'text.primary'}
                                variant="body2"
                                text={TRANSACTION_CAPTION_MESSAGE}
                                sx={{
                                    pt: showWarning ? 4 : 8,
                                    pb: 1,
                                    fontWeight: 'bold',
                                }}
                            />
                            <StyledSpinner>
                                <Spinner
                                    color="primary"
                                    size="45px"
                                    sx={{ mt: 3 }}
                                />
                            </StyledSpinner>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}
