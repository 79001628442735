import NavbarView from '../../views/navbarView/NavbarView';
import HistoryView from './views/HistoryView';
import { HistoryDataProvider } from './context/HistoryContext';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

export function History() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <HistoryDataProvider>
                    <HistoryView />
                </HistoryDataProvider>
            </StyledMain>
            <LogoFooter />
        </>
    );
}
