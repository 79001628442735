import Grid from '../Grid/Grid';
import GridItem from '../Grid/GridItem';
import Typography from '../Typography/Typography';
import { MethodGridProps } from './MethodGrid.types';
import { Fragment } from 'react';

export default function MethodGrid(props: MethodGridProps) {
    const { methodType, totalAmount, methodInformation } = props;

    return (
        <Grid gridSx={{ pl: 4, mt: 0 }}>
            <GridItem xs={12}>
                <Typography
                    variant="body1"
                    color={'otherText.primary'}
                    sx={{ mb: 1 }}
                >
                    {methodType}
                </Typography>
            </GridItem>
            {methodInformation.map((item) => (
                <Fragment key={item.label}>
                    <GridItem xs={6}>
                        <Typography
                            variant="body1"
                            color={'otherText.primary'}
                            sx={{ mb: 1 }}
                        >
                            {item.label}
                        </Typography>
                    </GridItem>
                    <GridItem xs={6}>
                        <Typography
                            variant="body1"
                            color={'otherText.primary'}
                            sx={{ mb: 1 }}
                        >
                            {item.value}
                        </Typography>
                    </GridItem>
                </Fragment>
            ))}
            {totalAmount && (
                <>
                    <GridItem xs={6}>
                        <Typography
                            variant="body1"
                            color={'otherText.primary'}
                            sx={{ mt: '32px' }}
                        >
                            Total Payment Amount
                        </Typography>
                    </GridItem>
                    <GridItem xs={6}>
                        <Typography
                            variant="h6"
                            color={'success.main'}
                            sx={{ mt: '28px' }}
                        >
                            {totalAmount}
                        </Typography>
                    </GridItem>
                </>
            )}
        </Grid>
    );
}
