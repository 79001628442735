import { PAYMENTS_API } from '../config';
import { usePost } from '../api/usePost';
import { AuthInformation, UserInformation } from '../models/App.interfaces';
import { AxiosError } from 'axios';

const base = 'auth';

function useSignIn(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return usePost<AuthInformation, UserInformation>(
        PAYMENTS_API + `${base}/sign-in`,
        {
            onErrorCallback: onErrorCallback,
            staleTime: 0,
        }
    );
}

function useInternalSignIn(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return usePost<AuthInformation, UserInformation>(
        PAYMENTS_API + `${base}/internal/sign-in`,
        {
            onErrorCallback: onErrorCallback,
            staleTime: 0,
        }
    );
}

function useSignUp(
    onErrorCallback: (error: AxiosError) => Promise<void> | void
) {
    return usePost<AuthInformation, UserInformation>(
        PAYMENTS_API + `${base}/internal/sign-up`,
        {
            onErrorCallback: onErrorCallback,
            staleTime: 0,
        }
    );
}

const AuthActions = {
    useSignIn: (onErrorCallback: (error: AxiosError) => Promise<void> | void) =>
        useSignIn(onErrorCallback),
    useInternalSignIn: (
        onErrorCallback: (error: AxiosError) => Promise<void> | void
    ) => useInternalSignIn(onErrorCallback),
    useSignUp: (onErrorCallback: (error: AxiosError) => Promise<void> | void) =>
        useSignUp(onErrorCallback),
};

export default AuthActions;
