import NavbarView from '../../views/navbarView/NavbarView';
import { PaymentsDataProvider } from './context/PaymentsContext';
import PaymentsView from './views/PaymentsView';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

function Payments() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <PaymentsDataProvider>
                    <PaymentsView />
                </PaymentsDataProvider>
            </StyledMain>
            <LogoFooter />
        </>
    );
}
export default Payments;
