export default {
    INVALID_DATA: 'Entered data invalid.',
    INVALID_ACCOUNT: 'Unable to locate account with entered data.',
    SERVER_ERROR: 'Something went wrong, please try again later...',
    CONTACT_FOR_ASSISTANCE: 'Please contact {0} for further assistance.',
    CONTACT_FOR_PAYMENT: 'Please contact {0} to submit payment.',
    SESSION_EXPIRED: 'Your session has expired.',
    CONTACT_VENDOR:
        'Something went wrong, if the problem persists please contact your Vendor.',
    TRY_AGAIN: 'Unable to complete request. Please try again later.',
    TOO_MANY_LOGIN:
        'You have been temporarily locked out due to too many invalid login attempts. ' +
        'You may choose to wait and try again or reach out to us at the contact information below.',
    UNABLE_TO_SIGN_IN:
        'There was a problem Signing you into your account. Please try again.',
    PROCESSING_PAYMENT: 'There was a problem processing your payment.',
} as const;
