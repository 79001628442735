import { createContext, useMemo, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { State, Reducer } from './State';
import ActionFactory from './Actions';
import { Props } from '../../../models/Props.types';
import { CustomerData, CustomerFactory } from './CustomersContext.types';

const Customers = createContext<CustomerData>(State);
const CustomersDispatch = createContext<CustomerFactory>({} as CustomerFactory);

export function CustomerDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, State);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);

    return (
        <Customers.Provider value={value}>
            <CustomersDispatch.Provider value={actions}>
                {children}
            </CustomersDispatch.Provider>
        </Customers.Provider>
    );
}

export function useCustomers(): [CustomerData, CustomerFactory] {
    const state = useContext(Customers);
    const actions = useContext(CustomersDispatch);

    return [state, actions];
}
