import { Button as MUIButton } from '@mui/material';
import { ButtonProps } from './Button.types';

export default function Button(props: ButtonProps) {
    const {
        type,
        variant,
        fullWidth,
        disabled,
        onClick,
        sx,
        title,
        startIcon,
        endIcon,
        size,
        color,
    } = props;

    return (
        <MUIButton
            type={type}
            variant={variant}
            fullWidth={fullWidth}
            disabled={disabled}
            onClick={onClick}
            sx={sx}
            startIcon={startIcon}
            endIcon={endIcon}
            size={size}
            color={color}
        >
            {title}
        </MUIButton>
    );
}

Button.defaultProps = {
    disabled: false,
    fullWidth: false,
};
