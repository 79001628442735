import NavbarView from '../../views/navbarView/NavbarView';
import MethodsView from './views/MethodsView';
import { MethodsDataProvider } from './context/MethodsContext';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

export function Methods() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <MethodsDataProvider>
                    <MethodsView />
                </MethodsDataProvider>
            </StyledMain>
            <LogoFooter />
        </>
    );
}
