import NavbarView from '../../views/navbarView/NavbarView';
import AddMethodView from './views/AddMethodView';
import { AddMethodDataProvider } from './context/AddMethodContext';

export function AddMethod() {
    return (
        <>
            <NavbarView />
            <AddMethodDataProvider>
                <AddMethodView />
            </AddMethodDataProvider>
        </>
    );
}
