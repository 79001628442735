import Button from '../../../../components/Button/Button';
import Typography from '../../../../components/Typography/Typography';
import { PayInvoicesViewProps } from './PayInvoicesView.types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TableToolbar from '../../../../components/tableToolbar/Tabletoolbar';
import TableInvoices from '../../components/tableInvoice/TableInvoice';
import { INVOICE_COLUMNS_SELECTED } from '../../../../helpers/TableColumns';

export default function PayInvoicesView(props: PayInvoicesViewProps) {
    const {
        invoices,
        totalAmount,
        totalSelected,
        isPopupOpen,
        allowPending,
        handleClickRemove,
        handleSelectAllClick,
        handleInvoiceCheck,
        handleInvoiceInputs,
        navigate,
    } = props;

    return (
        <>
            <Typography
                variant="h5"
                sx={{ maxWidth: '500px' }}
                text={'Pay Invoices'}
                color={'otherText.primary'}
            />
            <Button
                title={'Invoices'}
                startIcon={<KeyboardArrowLeftIcon />}
                sx={{
                    textTransform: 'none',
                    mt: '60px',
                    pl: '0px',
                    fontWeight: 'bold',
                }}
                size={'small'}
                onClick={() => {
                    navigate('/invoices');
                }}
                disabled={isPopupOpen}
            />
            <TableToolbar
                isSelectedHeader={totalSelected >= 1}
                headerText={
                    totalSelected >= 1
                        ? totalSelected + ' selected'
                        : 'Selected Invoices'
                }
                onClick={handleClickRemove}
                disabled={isPopupOpen}
            />
            <TableInvoices
                rows={invoices}
                cells={
                    allowPending
                        ? INVOICE_COLUMNS_SELECTED
                        : INVOICE_COLUMNS_SELECTED.filter(
                              (item) => item.id !== 'pending'
                          )
                }
                allowPending={allowPending}
                totalSelected={totalSelected}
                onCheckboxHeaderSelect={handleSelectAllClick}
                onCheckboxRowSelect={handleInvoiceCheck}
                handleInvoiceInputs={handleInvoiceInputs}
                totalAmount={totalAmount}
                isPopupOpen={isPopupOpen}
            />
        </>
    );
}
