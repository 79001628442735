import { useEffect, useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button/Button';
import Spinner from '../../../../components/Spinner/Spinner';
import IconButton from '../../../../components/Button/IconButton';
import Close from '@mui/icons-material/Close';
import {
    StyledFrame,
    StyledModal,
    StyledSpinner,
    StyledExit,
} from '../../views/styling';
import { CreateHostedTransaction } from '../../../../helpers/Utilities';
import {
    CONTACT_INFO_DEFAULT,
    PAYMENT_WILL_BE_STORED_WARNING,
    MODAL_CC_TOP_MESSAGE,
} from '../../../../helpers/Constants';
import PaymentActions from '../../../../hooks/PaymentsActions';
import { usePayments } from '../../context/PaymentsContext';
import { useApplication } from '../../../../context/ApplicationContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { ModalPaymentProps } from './ModalPayment.types';
import { InvoiceToPay } from '../../../../models/Invoice.interfaces';
import { TransactionData } from '../../../../models/Transaction.interfaces';
import { TokenHelper } from '../../../../helpers/TokenHelper';
import { useMsal } from '@azure/msal-react';
import ModalText from '../../../../components/modal/ModalText';
import {
    EventStatus,
    HostedTransactionType,
    PaymentMethodType,
} from '../../../../models/App.types';
import ErrorMessageConstants from '../../../../constants/ErrorMessageConstants';

export default function ModalPayment(props: ModalPaymentProps) {
    const {
        companyNumber,
        billingInformation,
        invoices,
        totalAmount,
        nickname,
        isB2CUserAccount,
        isSavedMethod,
        isAddingMethod,
    } = props;
    const [state, actions] = usePayments();
    const [applicationState] = useApplication();
    const { Company } = applicationState;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const CustomerNumber = TokenHelper.getInitialData().customerNumber || '';
    const [loading, setLoading] = useState<boolean>(true);
    const [eventSourceFailed, setEventSourceFailed] = useState<boolean>(false);
    const { instance } = useMsal();

    const {
        ModalOpen,
        TransactionSettings,
        Cancelled,
        PaymentInformation,
        paymentMethodType,
        notes,
        isSavePaymentMethodChecked,
    } = state;

    const invoicesToPay: InvoiceToPay[] = invoices.map((invoice) => {
        return { amount: invoice.amount, invoiceNumber: invoice.invoiceNumber };
    });

    const handlePostError = (error: AxiosError) => {
        if (error.response?.status === 400) {
            enqueueSnackbar('Invoice Number not found', { variant: 'warning' });
            navigate('/invoices');
        } else if (error.response?.status === 500) {
            enqueueSnackbar(ErrorMessageConstants.SERVER_ERROR, {
                variant: 'error',
            });
            actions.onSetModalOpen(false);
        } else if (error.response?.status === 403) {
            enqueueSnackbar(
                'Please contact ' +
                    (Company.contactInformation ?? CONTACT_INFO_DEFAULT) +
                    ' to submit payment.',
                {
                    variant: 'error',
                }
            );
            TokenHelper.logout(instance, navigate);
        } else if (error.response?.status === 401) {
            enqueueSnackbar(
                'Please contact ' +
                    (Company.contactInformation ?? CONTACT_INFO_DEFAULT) +
                    ' to submit payment.',
                {
                    variant: 'error',
                }
            );
            actions.onSetModalOpen(false);
        } else {
            enqueueSnackbar(ErrorMessageConstants.CONTACT_VENDOR, {
                variant: 'error',
            });
            actions.onSetModalOpen(false);
        }
    };

    const postHostedTransaction =
        PaymentActions.usePostHostedTransaction(handlePostError);

    useEffect(() => {
        if (ModalOpen === true) {
            createTransaction();
        }
    }, [ModalOpen]);

    const createTransaction = async () => {
        const savePaymentMethod: boolean =
            isSavePaymentMethodChecked && isB2CUserAccount;
        const hostedTransaction = CreateHostedTransaction(
            companyNumber,
            billingInformation,
            invoices,
            notes,
            nickname,
            savePaymentMethod
        );

        const response = await postHostedTransaction.mutateAsync(
            hostedTransaction
        );
        actions.onSetTransactionSettings(response.data);
        setLoading(false);
        setupEventStream(response.data.hostedTransactionId);
    };

    //initialize event stream; when first message is received, closes stream
    const setupEventStream = (hostedTransactionId: string) => {
        const eventSource = PaymentActions.setEventSource(
            hostedTransactionId,
            HostedTransactionType.Payment
        );
        eventSource.addEventListener('message', handleEvent);
        eventSource.onmessage = () => {
            eventSource.close();
        };
        eventSource.onerror = () => {
            setEventSourceFailed(true);
            eventSource.close();
        };
    };

    const onCreditCardModalReset = () => {
        actions.onSetModalOpen(false);
        actions.onSetCancelled(false);
        actions.onSetTransactionSettings({
            hostedTransactionId: '',
            hostedTransactionUrl: '',
        });
        setLoading(true);
        setEventSourceFailed(false);
    };

    const handleEvent = (event: any) => {
        const parsedData = JSON.parse(event.data);
        const status = parsedData.status;
        switch (status) {
            case EventStatus.Deleted:
                actions.onSetCancelled(true);
                break;
            case EventStatus.Approved:
                actions.onSetPaymentInformation(parsedData);
                break;
            case EventStatus.TimedOut:
                onCreditCardModalReset();
                break;
        }
    };

    useEffect(() => {
        if (
            PaymentInformation.referenceNumber !== '' &&
            paymentMethodType === PaymentMethodType.CreditCard &&
            (!isSavedMethod || isAddingMethod)
        ) {
            handleRedirect();
        }
    }, [PaymentInformation]);

    const handleRedirect = () => {
        const transactionData: TransactionData = {
            email: billingInformation.email,
            amount: totalAmount,
            type: paymentMethodType,
            paymentInformation: PaymentInformation,
            invoices: invoicesToPay,
            customerAccount: CustomerNumber,
            notes: notes,
            isSavedMethod: false,
        };

        //setting a waiting period to allow user to see Success screen before redirect
        setTimeout(() => {
            navigate('/confirmation', { state: { transactionData } });
        }, 5000);
    };

    return (
        <>
            <Modal
                open={ModalOpen}
                sx={{ mt: 4, mb: 4 }}
                maxWidth="md"
                fullWidth
            >
                <StyledModal>
                    {!!eventSourceFailed && (
                        <StyledExit>
                            <IconButton
                                color={'primary'}
                                onClick={onCreditCardModalReset}
                            >
                                <Close fontSize={'large'} />
                            </IconButton>
                        </StyledExit>
                    )}
                    {!loading ? (
                        <>
                            {!eventSourceFailed &&
                                !Cancelled &&
                                isSavePaymentMethodChecked &&
                                PaymentInformation.referenceNumber === '' && (
                                    <ModalText sx={{ mt: '18px' }}>
                                        {MODAL_CC_TOP_MESSAGE}
                                    </ModalText>
                                )}
                            <StyledFrame
                                src={TransactionSettings.hostedTransactionUrl}
                                title="WorkWave iFrame"
                            />
                            {!eventSourceFailed &&
                                !Cancelled &&
                                isSavePaymentMethodChecked &&
                                PaymentInformation.referenceNumber === '' && (
                                    <ModalText sx={{ mt: '18px' }}>
                                        {PAYMENT_WILL_BE_STORED_WARNING}
                                    </ModalText>
                                )}
                        </>
                    ) : (
                        <StyledSpinner>
                            <Spinner color={'primary'} size={'50px'} />
                        </StyledSpinner>
                    )}
                    <br />
                    {!!Cancelled && (
                        <Button
                            variant="contained"
                            title="Close"
                            onClick={onCreditCardModalReset}
                            sx={{ mt: 3 }}
                        />
                    )}
                </StyledModal>
            </Modal>
        </>
    );
}
