import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Box from '../box/Box';
import { ModalProps } from './Modal.types';

export default function Modal(props: ModalProps) {
    const {
        open,
        children,
        sx,
        maxWidth,
        fullWidth,
        title,
        allowModalStructure,
        modalActions,
        justifyActions,
    } = props;
    return (
        <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} sx={sx}>
            <Box sx={{ p: '24px' }}>
                {title && (
                    <DialogTitle component="div" sx={{ p: 0 }}>
                        {title}
                    </DialogTitle>
                )}
                {allowModalStructure ? (
                    <>
                        <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
                        <DialogActions
                            sx={{
                                p: 0,
                                pt: 4,
                                ...(justifyActions && {
                                    justifyContent: justifyActions,
                                }),
                            }}
                        >
                            {modalActions}
                        </DialogActions>
                    </>
                ) : (
                    children
                )}
            </Box>
        </Dialog>
    );
}
