import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import { Customer, Wrapper } from '../../../models/App.interfaces';
import { CustomerActions, CustomerFactory } from './CustomersContext.types';

function SetCustomers(
    payload: Wrapper<Customer>,
    dispatch: Dispatch<CustomerActions>
) {
    const customers: Customer[] = payload?.data ? payload?.data : [];
    dispatch({ type: ActionTypes.ListCustomers, payload: customers });
}

function UpdateIsCustomerPopupOpen(
    payload: boolean,
    dispatch: Dispatch<CustomerActions>
) {
    dispatch({ type: ActionTypes.UpdateIsCustomerPopupOpen, payload: payload });
}

function UpdateIsCustomerSuccessOpen(
    payload: boolean,
    dispatch: Dispatch<CustomerActions>
) {
    dispatch({
        type: ActionTypes.UpdateIsCustomerSuccessOpen,
        payload: payload,
    });
}

function deleteCustomer(payload: string, dispatch: Dispatch<CustomerActions>) {
    dispatch({
        type: ActionTypes.DeleteCustomer,
        payload: payload,
    });
}

export default function ActionFactory(
    dispatch: Dispatch<CustomerActions>
): CustomerFactory {
    return {
        onSetCustomers: (payload: Wrapper<Customer>) =>
            SetCustomers(payload, dispatch),
        onUpdateIsCustomerPopupOpen: (payload: boolean) =>
            UpdateIsCustomerPopupOpen(payload, dispatch),
        onUpdateIsCustomerSuccessOpen: (payload: boolean) =>
            UpdateIsCustomerSuccessOpen(payload, dispatch),
        onDeleteCustomer: (payload: string) =>
            deleteCustomer(payload, dispatch),
    };
}
