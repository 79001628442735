import { InvoicesListCellProps } from './InvoicesListCell.types';

export default function InvoicesListCell(props: InvoicesListCellProps) {
    const { params, textColor } = props;
    const { row } = params;
    const invoices = row.appliedInvoices.slice(0, 3);
    const remainingInvoices = row.appliedInvoices.length - 3;
    return (
        <>
            {String(invoices).replaceAll(',', ', ')}
            {remainingInvoices > 0 ? (
                <span style={{ color: textColor, fontWeight: 'bold' }}>
                    , +{remainingInvoices} more
                </span>
            ) : (
                ''
            )}
        </>
    );
}
