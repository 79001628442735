import { createContext, useMemo, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { State, Reducer } from './State';
import ActionFactory from './Actions';
import { HistoryData, HistoryFactory } from './HistoryContext.types';
import { HistoryDateProps, Props } from '../../../models/Props.types';
import { getInitialHistoryDates } from '../../../helpers/Utilities';

const History = createContext<HistoryData>(State);
const HistoryDispatch = createContext<HistoryFactory>({} as HistoryFactory);

export function HistoryDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, State);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);

    useEffect(() => {
        const historyInitialDates: HistoryDateProps = getInitialHistoryDates();
        actions.onUpdateHistoryParameters({
            ...value.historyParams,
            startDate: historyInitialDates.priorPeriod,
            endDate: historyInitialDates.today,
        });
    }, []);

    return (
        <History.Provider value={value}>
            <HistoryDispatch.Provider value={actions}>
                {children}
            </HistoryDispatch.Provider>
        </History.Provider>
    );
}

export function usePaymentHistory(): [HistoryData, HistoryFactory] {
    const state = useContext(History);
    const actions = useContext(HistoryDispatch);

    return [state, actions];
}
