import { Tab as MUItab, Tabs as MUITabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { TabProps } from './Tabs.types';
import { setPathName } from '../../helpers/Utilities';

export default function Tabs(props: TabProps) {
    const { pathname } = useLocation();
    const value = setPathName(pathname);
    return (
        <MUITabs value={value} textColor="inherit">
            {props.values.map((value) => (
                <MUItab
                    key={value.value}
                    label={value.label}
                    value={value.link}
                    to={value.link || '/login'}
                    component={Link}
                    sx={{ textTransform: 'none' }}
                />
            ))}
        </MUITabs>
    );
}
