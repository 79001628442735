import { FormatFooter, FormatString } from '../../../helpers/Utilities';
import { FOOTER } from '../../../helpers/Constants';
import { StyledFooter, StyledFooterBox } from './styling';
import { useApplication } from '../../../context/ApplicationContext';
import Typography from '../../Typography/Typography';

export default function Footer() {
    const [applicationState] = useApplication();
    const { Company } = applicationState;

    const footer = FormatFooter(
        FormatString(FOOTER, Company.contactInformation)
    );

    return (
        <StyledFooterBox>
            <StyledFooter>
                <Typography
                    text={footer}
                    variant="body1"
                    color="text.primary"
                />
            </StyledFooter>
        </StyledFooterBox>
    );
}
