export default {
    CONFIRM_PROCESS: 'Confirm & Process',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    PAYMENT_METHOD: 'Payment Method',
    IFRAME: 'WorkWave iFrame',
    METHOD_CC_MODAL_TITLE: 'Card Details',
    PAYMENT_WILL_BE_STORED_WARNING:
        'Your payment method will be stored by WinTeam to be used for any future transactions. ' +
        'Saved Payment Methods may be removed at any time by visiting the Payment Methods tab, ' +
        'hovering over the Nickname field, and selecting the Delete/Remove option.',
    ADD_PAYMENT_METHOD_LABEL: 'Add Payment Method',
    ARE_YOU_SURE_MESSAGE: 'Are you sure?',
    DELETE_CUSTOMER_TEXT:
        'Removing a customer cannot be undone, and they will no longer be accessible from your ' +
        'account.',
    DELETE_METHOD_TEXT:
        'Removing a saved payment method means it will no longer be accessible from your account.' +
        ' You will no longer be able to use it to pay invoices, but any pending payments using ' +
        'this method will not be rescinded.',
    TEAM_SOFTWARE_TEXT: 'TEAM Software',
    POWERED_BY_TEXT: 'Online Payments Portal powered by',
    MODAL_ACH_TOP_MESSAGE:
        'Clicking "Add & Process Payment" will submit your account and billing info to ' +
        'our system. Your account information will be used to process ' +
        'this payment and be saved for later use.',
    MODAL_ACH_FAILURE_MESSAGE:
        'There was a problem processing your payment, please contact your ' +
        'System Admin for additional assistance.',
    PAYMENT_METHOD_WARNING:
        'Your payment was processed, but there was a problem saving the info for later use.',
    SUCCESS_ACH_TEXT:
        'Your payment for {0} was successfully processed. This payment has been placed in pending' +
        ' status. A pending payment will typically be cleared within several business days.' +
        ' A receipt has been emailed to you at {1}. You can safely close this window,' +
        ' or click below to view the payment summary.',
    SUCCESS_CC_TEXT:
        'Your payment for {0} was successful. A receipt has been emailed to you at {1}.' +
        ' You can safely close this window, or click below to view the payment summary.',
    PASSWORD_CHANGE_SUCCESS_TOAST: 'Password Changed Successfully!',
} as const;
