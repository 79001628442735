import InvoicesView from './views/InvoicesView';
import { InvoiceDataProvider } from './context/InvoicesContext';
import NavbarView from '../../views/navbarView/NavbarView';
import LogoFooter from '../../components/Footer/LogoFooter/LogoFooter';
import { StyledMain } from '../../components/Footer/LogoFooter/styling';

function Invoices() {
    return (
        <>
            <NavbarView />
            <StyledMain>
                <InvoiceDataProvider>
                    <InvoicesView />
                </InvoiceDataProvider>
            </StyledMain>
            <LogoFooter />
        </>
    );
}

export default Invoices;
