import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_ID, ENVIRONMENT_TYPE } from './config';

const googleTagId = GOOGLE_TAG_ID;

function init(company?: string) {
    const tagManagerArgs = {
        gtmId: googleTagId,
        dataLayer: {
            /* eslint-disable*/
            'companyName': company,
            'customerNumber': 'unknown',
            'tenantID': 'unknown',
            'environment': ENVIRONMENT_TYPE,
            /* eslint-enable*/
        },
    };

    TagManager.initialize(tagManagerArgs);
}

export default {
    init,
};
