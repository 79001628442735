import {
    Route,
    Routes as Routing,
    Navigate,
    BrowserRouter,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from '../hooks/ScrollToTop';
import Login from '../pages/Login/Login';
import Invoices from '../pages/Invoices/Invoices';
import ErrorPage from '../pages/error/ErrorPage';
import Payments from '../pages/Payments/Payments';
import Result from '../pages/result/Result';
import Summary from '../pages/Summary/Summary';
import { TokenHelper } from '../helpers/TokenHelper';
import { Customers } from '../pages/customers/Customers';
import { Methods } from '../pages/methods/Methods';
import { AddMethod } from '../pages/addMethod/AddMethod';
import { History } from '../pages/history/History';
import AuthenticatedRoute from './AuthenticatedRoute';
import { EmailSuccess } from '../pages/emailSuccess/EmailSucces';
import { EmailSignupResponse } from '../models/App.interfaces';
import MethodResult from '../pages/methodResult/MethodResult';

export default function Routes() {
    const companyName: string = TokenHelper.getCompanyName();
    const hashValue: EmailSignupResponse | string =
        companyName === 'invite' ? TokenHelper.getHashValues() : '';

    return (
        <BrowserRouter basename={companyName}>
            <ScrollToTop />
            <Routing>
                <Route path="/response" element={<EmailSuccess />} />
                <Route
                    path="/login"
                    element={
                        companyName === '' || companyName === 'error' ? (
                            <Navigate replace to="/error" />
                        ) : companyName === 'invite' ? (
                            <Navigate
                                replace
                                to="/response"
                                state={{ hashValue }}
                            />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/invoices"
                    element={
                        <PrivateRoute>
                            <Invoices />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/payinvoices"
                    element={
                        <PrivateRoute>
                            <Payments />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/confirmation"
                    element={
                        <PrivateRoute>
                            <Summary />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/customers"
                    element={
                        <AuthenticatedRoute>
                            <Customers />
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/payment-methods"
                    element={
                        <AuthenticatedRoute>
                            <Methods />
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/add-method"
                    element={
                        <AuthenticatedRoute>
                            <AddMethod />
                        </AuthenticatedRoute>
                    }
                />
                <Route
                    path="/history"
                    element={
                        <AuthenticatedRoute>
                            <History />
                        </AuthenticatedRoute>
                    }
                />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/result" element={<Result />} />
                <Route path="/method-result" element={<MethodResult />} />
                <Route path="*" element={<ErrorPage />} />
            </Routing>
        </BrowserRouter>
    );
}
