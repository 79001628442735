import { Dispatch } from 'react';
import { ActionTypes } from './ActionTypes';
import {
    Company,
    PaymentMethods,
    Wrapper,
} from '../../../models/App.interfaces';
import { MethodsActions, MethodsFactory } from './MethodsContext.types';
import { TransactionSettings } from '../../../models/Transaction.interfaces';
import { EventStatus } from '../../../models/App.types';

function SetPaymentMethods(
    payload: Wrapper<PaymentMethods>,
    dispatch: Dispatch<MethodsActions>
) {
    const paymentMethods: PaymentMethods[] = payload?.data ? payload?.data : [];
    dispatch({ type: ActionTypes.ListPaymentMethods, payload: paymentMethods });
}

function UpdateIsPrimaryPopupOpen(
    payload: boolean,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({ type: ActionTypes.UpdateIsPrimaryPopupOpen, payload: payload });
}

function UpdatePrimaryMethodId(
    payload: number,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({ type: ActionTypes.UpdatePrimaryMethodId, payload: payload });
}

function SetCompanies(
    payload: Wrapper<Company>,
    dispatch: Dispatch<MethodsActions>
) {
    const companies: Company[] = payload?.data ? payload?.data : [];
    dispatch({ type: ActionTypes.ListCompanies, payload: companies });
}

function SetCompany(payload: number, dispatch: Dispatch<MethodsActions>) {
    dispatch({ type: ActionTypes.UpdateCompanyNumber, payload: payload });
}

function deletePaymentMethod(
    payload: string,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({ type: ActionTypes.DeletePaymentMethod, payload: payload });
}

function SetIsCreditCardLoading(
    payload: boolean,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({
        type: ActionTypes.SetIsCreditCardLoading,
        payload: payload,
    });
}

function SetHasEventSourceFailed(
    payload: boolean,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({ type: ActionTypes.SetHasEventSourceFailed, payload: payload });
}

function SetCreditCardTransactionSettings(
    payload: TransactionSettings,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({
        type: ActionTypes.SetCreditCardTransactionSettings,
        payload: payload,
    });
}

function SetStreamEventStatus(
    payload: EventStatus,
    dispatch: Dispatch<MethodsActions>
) {
    dispatch({ type: ActionTypes.SetStreamEventStatus, payload: payload });
}

function CreditCardModalReset(dispatch: Dispatch<MethodsActions>) {
    dispatch({ type: ActionTypes.SetCreditCardModalReset });
}

export default function ActionFactory(
    dispatch: Dispatch<MethodsActions>
): MethodsFactory {
    return {
        onSetPaymentMethods: (payload: Wrapper<PaymentMethods>) =>
            SetPaymentMethods(payload, dispatch),
        onDeletePaymentMethod: (payload: string) =>
            deletePaymentMethod(payload, dispatch),
        onUpdateIsPrimaryPopupOpen: (payload: boolean) =>
            UpdateIsPrimaryPopupOpen(payload, dispatch),
        onUpdatePrimaryMethodId: (payload: number) =>
            UpdatePrimaryMethodId(payload, dispatch),
        onSetCompanies: (payload: Wrapper<Company>) =>
            SetCompanies(payload, dispatch),
        onUpdateCompanyNumber: (payload: number) =>
            SetCompany(payload, dispatch),
        onSetIsCreditCardLoading: (payload: boolean) =>
            SetIsCreditCardLoading(payload, dispatch),
        onSetHasEventSourceFailed: (payload: boolean) =>
            SetHasEventSourceFailed(payload, dispatch),
        onSetCreditCardTransactionSettings: (payload: TransactionSettings) =>
            SetCreditCardTransactionSettings(payload, dispatch),
        onSetStreamEventStatus: (payload: EventStatus) =>
            SetStreamEventStatus(payload, dispatch),
        onCreditCardModalReset: () => CreditCardModalReset(dispatch),
    };
}
