import { Grid as MUIGrid } from '@mui/material';
import { GridProps } from './Grid.types';

export default function Grid(props: GridProps) {
    const { spacing, columns, justify, rowSpacing, gridSx, children } = props;
    return (
        <MUIGrid
            container
            spacing={spacing}
            columns={columns}
            justifyContent={justify}
            rowSpacing={rowSpacing}
            sx={gridSx}
        >
            {children}
        </MUIGrid>
    );
}
