import { createContext, useMemo, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { State, Reducer } from './State';
import ActionFactory from './Actions';
import { Props } from '../../../models/Props.types';
import { AddMethodData, AddMethodFactory } from './AddMethodContext.types';

const Methods = createContext<AddMethodData>(State);
const MethodsDispatch = createContext<AddMethodFactory>({} as AddMethodFactory);

export function AddMethodDataProvider(props: Props) {
    const { children } = props;
    const [value, dispatch] = useImmerReducer(Reducer, State);
    const actions = useMemo(() => ActionFactory(dispatch), [dispatch]);

    return (
        <Methods.Provider value={value}>
            <MethodsDispatch.Provider value={actions}>
                {children}
            </MethodsDispatch.Provider>
        </Methods.Provider>
    );
}

export function useAddMethod(): [AddMethodData, AddMethodFactory] {
    const state = useContext(Methods);
    const actions = useContext(MethodsDispatch);

    return [state, actions];
}
